
<div fxLayout="column" class="table-view" *ngIf="tableDataList?.length" [ngStyle]="{'height':tableHeight}">
    <table style="width: 100%;" mat-table matSort class="mat-elevation-z8" fxFlex="100" [dataSource]="datasource"
        cdkDropList (cdkDropListDropped)="dragAndDrop($event)">
        <ng-container matColumnDef="select">
            <ng-container>
                <th mat-header-cell *matHeaderCellDef>
                    <mat-checkbox (change)="allSelection()" [checked]="allSelectionEnabled" *ngIf="config.multiselect"></mat-checkbox>
                </th>
                <td mat-cell *matCellDef="let row">
                    <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? rowSelection(row, 'checkbox'): null" *ngIf="config.multiselect"
                        [checked]="row.isChecked">
                    </mat-checkbox>
                </td>
            </ng-container>
        </ng-container>
        <ng-container *ngFor="let column of config.dataFields"> 
            <ng-container [matColumnDef]="column.title">
                <ng-container *ngIf="!column.isSortRequired">
                    <ng-container *ngIf="column.display">
                        <th mat-header-cell *matHeaderCellDef>{{(column.title |uppercase) | translate|uppercase}}</th>
                    </ng-container>
                    <ng-container *ngIf="!column.display">
                        <th mat-header-cell *matHeaderCellDef></th>
                    </ng-container>
                </ng-container>
                <ng-container *ngIf="column.isSortRequired">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header (click)="sortColumn(column.fieldName)">
                        {{(column.title|uppercase) | translate|uppercase }}</th>
                </ng-container>
                <ng-container *ngIf="column.title != 'logo'">
                    <td mat-cell *matCellDef="let element">
                        <ng-container *ngIf="column.dataFormat.type == 'String'">{{element[column.fieldName] || element[column.fieldName] === 0 ? (element[column.fieldName] | translate): blankFieldText }}
                            <!-- For Depot managemnet - Showing a vehicle is trasferred -->
                            <ng-container *ngIf="column.dataFormat.icon?.enabled && element.currentOwnership?.transferredOn">
                                <span style="font-weight: 500; color: #ecaa22; font-size: 14px"  [title]="'Vehicle Transferred'">(T)</span>
                            </ng-container>
                        </ng-container>
                        <ng-container *ngIf="column.dataFormat.type == 'Boolean'">{{element[column.fieldName] ? ( 'YES'| translate | titlecase ): ('NO'| translate | titlecase ) }}</ng-container>
                        <ng-container *ngIf="column.dataFormat.type == 'Date'">{{element[column.fieldName] ? (element[column.fieldName] | vehicleTimeHandler ): blankFieldText}}</ng-container>
                        <ng-container *ngIf="column.dataFormat.type == 'button'">
                            <mat-icon style="width:32px" [color]='column.dataFormat.iconColor' (click)="buttonAction($event, element)">{{column.dataFormat.icon}}</mat-icon>
                        </ng-container>
                        <ng-container *ngIf="column.dataFormat.type == 'link'">
                            <div [innerHTML]="column.dataFormat.template"></div>
                            <!-- <a [routerLink]="[column.dataFormat.url]" [queryParams]="column.dataFormat.params"> -->
                                <!-- {{element[column.dataFormat.template}} -->
                            <!-- </a> -->
                        </ng-container>
                    </td>
                </ng-container>
                <ng-container *ngIf="column.title == 'logo'">
                     <td mat-cell *matCellDef="let element">
                    <ng-container *ngIf="element?.vehicle?.isFaulty">
                        <ng-container *ngIf="element?.drivingStatus?.transmissionStatus === 'Stopped'">
                            <img title="Stopped-Faulty"src="../../../../../../assets/images/map/FaultyTruck.svg" style="width:24px">
                        </ng-container>
                        <ng-container *ngIf="element?.drivingStatus?.transmissionStatus === 'Driving'">
                            <img title="Driving-Faulty"src="../../../../../../assets/images/map/DrivingFaultyTruck.svg" style="width:24px">
                        </ng-container>
                        <ng-container *ngIf="element?.drivingStatus?.transmissionStatus === 'Idle'">
                            <img title="Idle-Faulty"src="../../../../../../assets/images/map/IdleFaultyTruck.svg" style="width:24px">
                        </ng-container> 
                    </ng-container>
                    <ng-container *ngIf="!(element?.vehicle?.isFaulty)">
                        <mat-icon [title]="element.drivingStatus.transmissionStatus" [className]="element.drivingStatus.transmissionStatus" style="width:32px">
                            local_shipping
                        </mat-icon>
                    </ng-container>
                </td>
                </ng-container>
            </ng-container>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr> 
        <ng-container *ngIf="!config.draggable">
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowSelection(row,'row')"
                [ngClass]="{ 'backgroundColor': (row.isSelected) }">
            </tr>
        </ng-container>
        <ng-container *ngIf="config.draggable">
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="rowSelection(row,'row')"
                [ngClass]="{ 'backgroundColor': (row.isSelected) }" cdkDrag [cdkDragData]="row"
                cdkDragBoundary=".drag-flex-container" (cdkDragStarted)="dragStarted($event)"
                (cdkDragEnded)="removeMouseOverListenerEvent()" (cdkDragMoved)="onDragMoved($event)">
            </tr>
        </ng-container>
    </table>
</div>
<div fxLayout="column" *ngIf="tableDataList?.length">
    <mat-paginator #paginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
        [pageSizeOptions]="[15, 30]" (page)="pageEvent = pagination($event)" showFirstLastButtons>
    </mat-paginator>
</div>
<div class="no-data" *ngIf="!tableDataList?.length && !isLoadingActive && isLoadingAsssign"><span> {{'NO DATA FOUND' |translate |titlecase}}</span></div>
<div class="no-data" *ngIf="tableDataList?.length == 0 && !isLoadingActive"><span> {{'NO DATA FOUND' |translate |titlecase}} </span></div>
