import { Component, OnInit, Input, SimpleChanges, OnChanges, Output, EventEmitter } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { colorsSettings } from '@config/color-config';

@Component({
    selector: 'app-dashboard-def-chart',
    templateUrl: './dashboard-def-chart.component.html',
    styleUrls: ['./dashboard-def-chart.component.scss']
})
export class DashboardDefChartComponent implements OnInit, OnChanges {
    @Input() chartApiData;
    @Output() chartClickedIndex = new EventEmitter<number>();
    graphOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true,
                    reverse: false,
                    suggestedMax: 10,
                    stepSize: 10,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Vehicle Count'
                }
            }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'DEF level Percentage'
                }
            }]
        },
        legend: {
            display: true,
            position: 'top',
            align: 'start', 
        },
        tooltips: {
            callbacks: {
                title: function(tooltipItem, data) {
                    return 'DEF Level : ' + data['labels'][tooltipItem[0]['index']];
                  },
                label: function(tooltipItem, data) {
                    return data['datasets'][0]['data'][tooltipItem['index']] + ' Vehicles';
                }
            }
          },
        // def level api request
        onClick: function (e) {
            const barIndex = this.getElementsAtEvent(e)[0]?._index;
            barIndex>-1 && window['defChartBarClick'](barIndex);
        },
        // show cursor on bar hover
        onHover: (event, chartElement) => {
            (event.target as HTMLElement).style.cursor = chartElement[0] ? 'pointer' : 'default';
        }
    };
    labels;
    defData;
    clickedIndex: number;
    hasData: boolean = false;

    constructor() { 
        window['defChartBarClick'] = this.defChartBarClick.bind(this);
    }

    ngOnInit(): void {
    }

    defChartBarClick(event) {
        this.chartClickedIndex.emit(event+1);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue) {
            this.labels = this.chartApiData?.chartLabels || [];
            this.defData = [{
                data: this.chartApiData?.chartData[0]?.data || [],
                label: 'DEF level',
                backgroundColor: colorsSettings.def,
                hoverBackgroundColor: colorsSettings.def
            }];
            this.hasData = this.chartApiData?.chartData[0]?.data.filter(Boolean)?.length;
            if (this.chartApiData?.chartData[0]?.data[0] !== 0 && this.chartApiData?.chartData[0]?.data.filter(Boolean)?.length == 1) this.hasData = false;
        }
    }
}
