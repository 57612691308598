import { Component, OnInit, OnDestroy, ChangeDetectorRef, NgZone, ElementRef, ViewChild } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BaseHttpService } from '@core/services/base-http.service';
import { dateFormatter, activeTransferTableHandler, getCurrentMonth, filterLabels, generateQueryParams, createQueryString, getTags, getVehicleTags } from '@core/helpers/app.helper';
import { ToastService } from '@core/services/toast.service';
import { AuthService } from '@core/services/auth.service';
import { SubSink } from 'subsink';
import { MyfleetService } from '@modules/myfleet/services/myfleet.service';
import { HttpParams } from '@angular/common/http';
import { environment } from '@environments/environment';
import { AppService } from '@core/services/app.service';
import { MatTableDataSource } from '@angular/material/table';
import { CryptoService } from '@core/services/crypto.service';
import { TranslateService } from '@ngx-translate/core';
import { FiltersAppliedInfo } from '@core/models/app.model';
import { UserService } from '@modules/user-management/services/user.service';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Router } from '@angular/router'
import * as atlas from 'azure-maps-control';
import { Map, AuthenticationType } from 'azure-maps-control';
import { dashboardTableDataConfig } from '@shared/components/pagination/config';

@Component({
    selector: 'app-dashboard-view',
    templateUrl: './dashboard-view.component.html',
    styleUrls: ['./dashboard-view.component.scss']
})
export class DashboardViewComponent implements OnInit, OnDestroy {
    mapView;
    listView = false;
    currentUserdistanceCode;
    mapOrList: 'map' | 'list' = 'map';
    listIconColor;
    mapIconColor;
    displayAll = false;
    displayBtn = false;
    filterIconColor;
    vehicleList: any[] = [];
    displayedColumnsDashboard = ['statusLogo', 'vin', 'currentOwner', 'tags', 'lastLocation', 'status', 'depotName'];
    filterModalValue : any;
    mapData;
    // dashboard charts
    dashDefChart;
    dashUtilizationChart;
    dashFuelEconomyChart;
    dashIdleTimeChart = null;
    rightBarState = false;
    comparisonChartState = false;
    selectedRowData: any = {
        vin: '',
        tag: '',
        location: '',
        time: '',
        status: '',
        cases: '',
        poll: '',
        vehicleId: '',
        accountId: '',
        currentLocation: '',
        lastLocation: '',
        vehicleVin: '',
        vehicle: {}
    };
    nullSelectedRowData = { ...this.selectedRowData };
    openMapView = false;
    routesData: any;
    copyOfRoutesData: any;
    isLoading: boolean;
    subSink = new SubSink();
    routeSelections = new Set<string>();
    // right sidebar charts
    utilizationChart = null;
    fuelEconomyComparisonChart = null;
    idleTimeComparisonChart = null;
    fuelEconomyChart = null;
    updateUserPreference = {
        enabled: false,
        body: null
    };
    idleTimeChart = null;
    comparisonChart = null;
    currentVehicleData;
    nearestLocations: any[] = [];
    boundedBodyClick;
    isShowCharts = false;
    timedApiCalls;
    selectedCountry: boolean = true;
    currentMonth: string;
    dealerList: any[] = [];
    depotList: any[] = [];
    customerList: any[] = [];
    statusList: any[] = [];
    vocationDropdowns: any[] = [];
    isChartsHidden = true;
    filterInfo: FiltersAppliedInfo;
    // pageData = null;
    user: any;
    filteredWithDEF = false;
    filterMessageDEF = '';
    refreshEnabled = false;
    defLevels: any[] = [];
    map: any
    certifiedDealer = '../../../../assets/images/map/certified-dealer.png';
    truckStop = '../../../../assets/images/map/trucks.png';
    isResetMap = false;
    selectedNearestDealer = null;
    selectedDEFLevel = '';
     // Pagination start
     paginationConfig = dashboardTableDataConfig;
     sortSettings = {
         sort_column: 'vin',
         sort_order: 'ASC'
     };
     pageSettings = {
         start: 0,
         pageSize: 15
     };
     pageData: any = {};
     // Pagination End
    isHinoInternal: boolean;
    blankFieldText = environment.blankFieldText;
    resetMap = false;
    mapBounds = null;
    initialDealerLoad = true;
    currentUserPreference;
    constructor(
        private modalService: NgbModal,
        private userService: UserService,
        private deviceService: DeviceDetectorService,
        private http: BaseHttpService,
        private toast: ToastService,
        private authService: AuthService,
        private myFleetService: MyfleetService,
        private changeDetectorRef: ChangeDetectorRef,
        private appService: AppService,
        private ngZone: NgZone,
        private cryptoService: CryptoService,
        private translateService: TranslateService,
        private userSerice: UserService,
        private router: Router,
        private dashboardService: DashboardService
    ) {
        this.user = this.cryptoService.decryptData(
            localStorage.getItem('user')
        );
    }

    ngOnInit(): void {
        // subscribe to loading events
        // this.ngZone.runOutsideAngular(() => {
        //     this.subSink.sink = this.appService.appLoader$.subscribe(value => {
        //         this.isLoading = value;
        //         this.changeDetectorRef.detectChanges();
        //     });
        // });
        this.isHinoInternal = this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate;
        localStorage.removeItem('dashboard-filters');
        
        this.renderComponent();
        if(!this.isHinoInternal) this.getInsightPrograms();
        this.timedApiCalls = setInterval(() => {
            this.mapOrList === 'map' && this.renderComponent();
            this.refreshEnabled = true;
            if (this.rightBarState && this.selectedRowData?.vehicle?.vehicleId) this.selectedRowDataTransmit(this.selectedRowData);
            else this.refreshEnabled = false;
        }, environment.apiCallInterval);
        this.currentMonth = getCurrentMonth();
        // close right sidebar on body click
        this.boundedBodyClick = this.closeRightSidebarOnBodyClick.bind(this);
        document.addEventListener('click', this.boundedBodyClick);
        // get depots for filter dropdown
        this.http.get(`depots`).subscribe(response => this.depotList = response.data.depots);
        this.http.get(`fetchAllCustomers`).subscribe(response => this.customerList = response.data);
        // get status for filter dropdown
        this.http.get('taxonomy?taxonomy_type=TRANSMISSION_STATUS').subscribe(response => {
            this.statusList = response.data.taxonomies.map(obj => {
                obj.isDisabled = false;
                if (obj.taxonomyName === 'Faulty')
                    obj.taxonomyName = 'Fault';
                return obj;
            })
        });
        this.http.get('taxonomy?taxonomy_type=DEF_LEVEL').subscribe(response => this.defLevels = response.data.taxonomies);
        this.getVocationDropdowns();
        // set app user-preferences
        this.http.get(`users/${this.authService.getUserInfo().userId}/preference`, { observe: 'response' }).subscribe(response => {
            localStorage.setItem('user-preferences', this.cryptoService.encryptData(response.body.data));
            // i18n
            const userLanguage = response.body.data?.language.code.split('-')[0];
            this.translateService.setDefaultLang(userLanguage?.toLowerCase() || 'en');
            this.translateService.use(userLanguage?.toLowerCase() || 'en');
            this.currentUserPreference = response.body.data;
            this.currentUserdistanceCode = response.body.data.uom.distance.code
        }, (errorResponse) => {
            this.userSerice.savePreference();
        });

        this.dashboardService.updateAction.subscribe((data) => {
            this.resetMap = true;
            if(data) this.renderComponent();
        });
        this.userService.broadcastUser.subscribe((data) => {
            if (data && data.hasOwnProperty('dataType')) {
                this.updateUserPreference = {
                    enabled: true,
                    body: data
                }
            }
        });
        console.log(this.updateUserPreference);
    }

    ngOnDestroy() {
        document.removeEventListener('click', this.boundedBodyClick);
        clearInterval(this.timedApiCalls);
        this.subSink.unsubscribe();
    }

    // To render Dashboard content - Map(clusters an dealers)/List View and KPI charts
    renderComponent() {
        this.getVocationDropdowns();
        if (this.mapOrList === 'map') this.showAzureMap();
        else this.showListView();
        this.getKpiChartsData();
        this.routeSelections.clear();
        this.utilizationChart = null;
        this.fuelEconomyComparisonChart = null;
        this.idleTimeComparisonChart = null;
        this.comparisonChart = null;
        // this.rightBarState = false;
    }

    //To populate vocation dropdown in right side bar
    getVocationDropdowns() {
        this.http
            .get('taxonomy?taxonomy_type=VOCATION')
            .subscribe((response) => (this.vocationDropdowns = response.data.taxonomies));
    }

    // To get KPI chart data to show in chart section
    getKpiChartsData(queryParams: string = '') {
        this.http.get(`dashboard/kpi-chart${this.getConstraints(queryParams)}`).subscribe(response => {
            const { defLevelChart, utilizationChart, fuelEconomyChart, idletimeChart } = response.data;
            this.dashDefChart = defLevelChart;
            this.dashUtilizationChart = utilizationChart;
            this.dashFuelEconomyChart = fuelEconomyChart;
            this.dashIdleTimeChart = idletimeChart;
            this.isShowCharts = true;
        });
    }

    // To get Currently logged in user account Type
    getAccountType(): string {
        return this.isHinoInternal ? 'account_id=-1' : '';
    }

    getConstraints(queryParams): string {
        return queryParams
            ? (this.getAccountType() ? `${queryParams}&${this.getAccountType()}?duration=half-year` : `${queryParams}?duration=half-year`)
            : (this.getAccountType() ? `?${this.getAccountType()}&duration=half-year` : '?duration=half-year');
    }

    /** azure map cluster + marker data */
    showAzureMap(
        queryParams: string = '',
        filters: Array<[string, string]> = [],
    ) {
        this.getVehicleMapView();
    }

    /** show applied filters above table */
    setFilterStatus(
        filters: Array<[string, string]>
    ) {
        let defLevel: any;
        filters.map(element => {
            if(element[0] == 'def_level') {
                defLevel = parseInt(element[1]) - 1;
                element[1] =  defLevel.toString();
            }
        });
        this.selectedDEFLevel = (defLevel?.toString()) ? this.dashDefChart['chartLabels'][parseInt(defLevel)] : '';
        let labels = [];
        filterLabels(filters).forEach((label, index) => {
            if(label == "def level") {
                label = (this.selectedDEFLevel?.toString()) ? `def level ${this.selectedDEFLevel}`: label;
                labels.push(label);
            } else labels.push(label);
        });
        if (filters?.length) {
            this.filterInfo = {
                isFilterApplied: labels.length > 0 ? true : false,
                appliedFilters: labels
            };
            this.vehicleList?.length;
        }
        else this.filterInfo = null;
    }

    /** vehicle list */
    showListView(
        queryParams: string = '',
        filters: Array<[string, string]> = [],
    ) {
        this.getVehicleListView();
    }

    scrollComponentToTop() {
        document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 0);
    }

    // To get Vehicle details on Vehicle Marker icon in Azure map
    getClickedVehicleDetails(vehicleId: number) {
        console.log("vehicleId")
        this.http.get(`vehicles/${vehicleId}`).subscribe(response => {
            if (response.data?.vehicle) {
                let vehicleData = getVehicleTags(response.data);
                this.selectedRowDataTransmit(response.data);
            }
        });
    }

    closeRightBar() {
        // this.selectedRowData = {};
        this.rightBarState = false;
        this.comparisonChartState = false;
    }

    filterModalSubmit(formValue) {
        this.pageSettings.start = 0;
        this.filterModalValue = formValue;
    }
    // Function to open modals = referenceVariable refers to the id of the modal which should be opened
    open(referenceVariable) {
        this.modalService.open(referenceVariable, { size: 'lg', backdrop: 'static', keyboard: false }).result.then(
            (result) => {
                if (result === 'filter') {
                    const nonNullParameters = Object.entries(this.filterModalValue).reduce((prev, curr) => {
                        let  filterKey = curr[0];
                        if (filterKey != undefined && filterKey != '' && filterKey != null) prev.push(curr)
                        return prev;
                    }, []);
                    const originalQuery = generateQueryParams(this.filterModalValue);
                    if (this.mapOrList === 'map') this.showAzureMap(originalQuery, nonNullParameters);
                    else this.showListView(originalQuery, nonNullParameters);
                }
            },
            (reason) => {
            }
        );
    }

    selectedRowDataTransmit(event) {
        // Set currently selected Vehicle Data   
        this.currentVehicleData = event;
        this.selectedRowData = event;
        this.utilizationChart =  null;
        this.fuelEconomyChart =  null;
        this.idleTimeChart =  null;
        this.comparisonChart =  null;
        this.rightBarState = true;
        //get all kpi data
        this.http.get(`vehicles/${event.vehicle.vehicleId}/kpi-charts`)
        .subscribe((response) => {
            const {
                fuelEconomyChart,
                utilizationChart,
                idletimeChart,
                EnginehoursChart,
            } = response.data;
            this.utilizationChart = utilizationChart || null;
            this.fuelEconomyChart = fuelEconomyChart || null;
            this.idleTimeChart = idletimeChart || null;
            this.comparisonChart = EnginehoursChart || null;
        });
        
        this.http.get(`telemetry/recent?search_vin=${this.currentVehicleData?.vehicle?.vehicleVin}`).subscribe((odometerDataRes) => {
            if(odometerDataRes?.status == "success") {
                let telemetryList = odometerDataRes.data?.telemetryList;
                let odometerValue = telemetryList[telemetryList.length-1]?.parameters.hiResVehicleDistance;
                this.currentVehicleData.odometer = odometerValue;
            }
        });
        let lastLocation = event?.lastLocation?.geoTag?.geometry?.coordinates;
        this.nearestLocations = [];
        if (Array.isArray(lastLocation) && lastLocation[0] && lastLocation[1]) {
            let httpParams = new HttpParams();
            httpParams = httpParams.append('categories', 'HINO_DEALERS');
            httpParams = httpParams.append('lat', lastLocation[1]);
            httpParams = httpParams.append('lon', lastLocation[0]);
            httpParams = httpParams.append('vin', this.currentVehicleData?.vehicle?.vehicleVin);
            // httpParams = httpParams.append('lat', event.lastLocation[1]);
            httpParams = httpParams.append('rows', "3");
            // To get nearest Dealers for the selected Vehicle
            this.http.get(`dashboard/maps/nearest-geofence`, { params: httpParams }).subscribe(response => {
                this.nearestLocations = response.data.map(this.mapNearestDealers);
            });
        }
    }

    /** to persist active state of selected routes on search */
    isRouteSelected = (routeInfo): boolean => this.routeSelections.has(`${routeInfo.date}${routeInfo.start}${routeInfo.end}`);

    /** set route path in azure map */
    setSelection(event, routeInfo, index: number) {
        const uniqueRoute = `${routeInfo.date}${routeInfo.start}${routeInfo.end}`;
        const target = (event.target as HTMLElement).closest('.path-instance');
        if (!!(+target.getAttribute('data-selection'))) {
            target.classList.remove('active');
            target.setAttribute('data-selection', '0');
            (target.querySelector('input[type="checkbox"]') as HTMLInputElement).checked = false;
            this.myFleetService.setRoute$.next({ routeInfo, vehicleId: this.currentVehicleData.vehicle?.vehicleId, index, removeRoute: true });
            if (this.routeSelections.has(uniqueRoute)) this.routeSelections.delete(uniqueRoute);
        }
        else {
            target.classList.add('active');
            target.setAttribute('data-selection', '1');
            (target.querySelector('input[type="checkbox"]') as HTMLInputElement).checked = true;
            this.myFleetService.setRoute$.next({ routeInfo, vehicleId: this.currentVehicleData.vehicle?.vehicleId, index });
            this.routeSelections.add(uniqueRoute);
        }
    }

    /** search handler for azure maps */
    searchOutput(searchQuery: string) {
        if (!searchQuery) {
            this.routesData = this.copyOfRoutesData;
            return;
        } else {
            const routes = this.copyOfRoutesData.data.filter(el => {
                const _date = dateFormatter(new Date(el.dateInText));
                if (
                    el.start.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    el.end.toLowerCase().includes(searchQuery.toLowerCase()) ||
                    _date.includes(searchQuery)
                ) return true;
            });
            this.routesData = {
                paths: this.copyOfRoutesData.paths,
                data: routes
            };
        }
        this.changeDetectorRef.detectChanges();
    }



    openLocationHistory() {
        this.openMapView = true;
        this.routeSelections.clear();
    }

    showMapRoutes(routesData) {
        this.rightBarState = true;
        this.routesData = routesData;
        this.sortData();
        if(this.routesData.data.length > 6) {
            this.displayBtn = true;
        }
        this.copyOfRoutesData = JSON.parse(JSON.stringify(routesData));
    }

    showAll(){
       this.displayAll = !this.displayAll;
    }

    sortData() {
        return  this.routesData.data.sort((a, b) => {
          return <any>new Date(b.dateInText) - <any>new Date(a.dateInText);
        });
      }

    closeMapViewFunction() {
        this.openMapView = false;
        this.routeSelections.clear();
        this.routesData = {
            data: [],
            paths: []
        };
    }

    closeRightSidebarOnBodyClick(event) {
        if (
            !(
                (event?.target as HTMLElement).closest('.fleet-sidenav') ||
                (event?.target as HTMLElement).closest('.mat-cell') ||
                (event?.target as HTMLElement).closest('.location-history') ||
                (event?.target as HTMLElement).closest('.map-container') ||
                (event?.target as HTMLElement).closest('.search-container') ||
                (event?.target as HTMLElement).closest('.false-close') ||
                (event?.target as HTMLElement).closest('.main-title') ||
                (event?.target as HTMLElement).closest('.main-title-right-panel')
            )
        ) {
            this.closeRightBar();
            this.closeMapViewFunction();
        }
    }

    // To show Vehicles list view , for the selected DEF level from DEF chart. The selected DEF level will be applied as a filter in list view and saves in local storage
    defChartClicked(event) {
        this.pageSettings.start = 0;
        if(this.filterModalValue) {
            this.filterModalValue.def_level = event;
            this.selectedDEFLevel = this.dashDefChart['chartLabels'][event];
            localStorage.setItem('dashboard-filters', JSON.stringify(this.filterModalValue));
        } else {
            let filterValues = this.filterModalValue?.def_level || {};
            filterValues.def_level = event;
            this.filterModalValue = filterValues;
        }
        this.showListView();
    }

    setChartsVisibility() {
        this.isChartsHidden = !this.isChartsHidden;
        typeof window['refreshMap'] === 'function' && window['refreshMap']();
        setTimeout(() => {
            this.isChartsHidden && document.getElementsByTagName('mat-drawer-content')[0].scrollTo(0, 1000);
        });
    }
    removeDEFFilter() {
        this.filteredWithDEF = false;
        this.showListView();
    }
    openDetailPage() {
        this.router.navigate([`/hino/dashboard/vehicle/${this.selectedRowData?.vehicle?.vehicleId}/details`]);
    }

    // find nearest dealer on map when selecting from right panel nearest dealers list
    findDealerOnMap(selectedDealer) {
        this.selectedNearestDealer = selectedDealer;

    }


    // To mark nearest Dealer on map when selecting from right panel nearest dealers list
    mapNearestDealers (dealer) {
            const {
                title,
                distance,
                description,
                geometry
            } = dealer;
            let address = description.split('\r\n');

            let phone = '';
            let fax = '';
            let streetAddress = '';
            let city = '';
            let state = '';
            let zipCode = '';
            let country = '';
            let website = '';
            let dealerAddress = [];
            address.forEach(element => {
                if (element.indexOf('Phone: ') > -1) phone = element.split('Phone: ')[1].split(',')[0];
                if (element.indexOf('Fax: ') > -1) fax = element.split('Fax: ')[1];
            });
            streetAddress = address[0];
            city = address[1];
            state = address[2];
            zipCode = address[3];
            country = address[4];
            website = address[6];
            for (let i=0; i<5; i++) dealerAddress.push(address[i]);
            if(fax) dealerAddress.push(`Fax: ${fax}`);
            return {
                title,
                distance,
                geometry,
                description,
                streetAddress,
                city,
                state,
                country,
                zipCode,
                phone,
                fax,
                website,
                fullAddress: dealerAddress.join(',')
            };
    }

    // To get insight subscription data to control Open case option visibility in case management Module
    getInsightPrograms() {
        this.http
            .get(`users/insight-subscription`, { observe: 'response' })
            .subscribe(
                (insightResponse: any) => {
                    if (insightResponse.status == 200) {
                        let userInsight = insightResponse.body.data.userInsight.caseManagement;
                        let isCaseManagementEnabled = (userInsight.configValue) ? true : false;
                        localStorage.setItem('isCaseManagementEnabled', this.cryptoService.encryptData({isCaseManagementEnabled}));
                    }
                },
                (errorResponse) => {
                    // if (errorResponse.status == 400 || errorResponse == 404) {
                    //     this.user.preferences = null;
                    // }
                }
            );
    }

    // To get Pagination data from table view
    outPaginationData(event) {
        this.pageSettings = event;
        this.getVehicleListView();
    }

    // To get Selected records from table view
    outputSelectedRows(event) {
        if(event.selectedRow == null) this.closeRightBar();
        else this.selectedRowDataTransmit(event.selectedRow);
    }

    // To get Sorting data from table view
    outputSortSelection(event) {
        this.sortSettings = event;
        this.getVehicleListView();
    }


    // To format Vehicles API [GET] Query with current user account, Filter options, Sort Selection, Pagination
    getListQuery() {
        let accountQuery = this.getAccountType() ? `&${this.getAccountType()}`: ``;
        let sortQuery = (this.sortSettings) ? `&${createQueryString(this.sortSettings)}` : ''
        let filterQuery = (this.filterModalValue && createQueryString(this.filterModalValue)) ? `&${createQueryString(this.filterModalValue)}` : '';
        let ownershipStatusQuery = `&ownership_status=active`;
        let pageQuery = (this.pageSettings) ? `?rows=${this.pageSettings.pageSize}&start=${this.pageSettings.start}` : `?rows=15`;
        let query = `vehicles${pageQuery}${sortQuery}${filterQuery}${accountQuery}${ownershipStatusQuery}`;
        return query;
    }
    // To format Dashboard MAp Vehicles API [GET] Query with current user account, Filter options, Sort Selection, Pagination
    getMapQuery() {
        let params = [];
        let accountQuery = this.getAccountType() ? `${this.getAccountType()}` : ``;
        this.filterModalValue = JSON.parse(localStorage.getItem('dashboard-filters'));
        let mapQuery;
        let filterQuery = (this.filterModalValue) ? `${createQueryString(this.filterModalValue)}` : '';
        let boundsQuery = (this.mapBounds) ? `bbox=${this.mapBounds.bounds.join(',')}` : `&bbox=-192.26074218749898,9.275622176793007,-3.7353515625005684,58.72259882804346`;
        let sortQuery = (this.sortSettings) ? `${createQueryString(this.sortSettings)}` : '';
        let statusQuery = (this.isHinoInternal) ? `status=active` : ``;
        if(sortQuery) params.push(sortQuery);
        if(filterQuery) params.push(filterQuery);
        if(accountQuery) params.push(accountQuery);
        if(boundsQuery) params.push(boundsQuery);
        if(params.length == 0) {
            let query = `dashboard/maps/vehicles`;
            return query;
        } else {
            let query = `dashboard/maps/vehicles?${params.join('&')}`;
            return query;
        }
    }

   // To get all vehicles in table view
   getVehicleListView() {
    this.mapOrList = 'list';
    this.http.get(this.getListQuery()).subscribe(response => {
        this.vehicleList = response.data.vehicles.map(getVehicleTags);
        this.pageData = response.data;
        this.mapView = false;
        this.listView = true;
        this.rightBarState = false;
        this.scrollComponentToTop();
        // !this.vehicleList?.length && this.toast.info('no vehicles found!');
        this.filterStatus();
    });
}

// To get all Dashboard map vehicles in table view
getVehicleMapView() {
    this.mapView = true;
    this.mapOrList = 'map';
    this.http.get(this.getMapQuery()).subscribe(response => {
        this.mapData = response.data;
        this.listView = false;
        this.scrollComponentToTop();
        this.changeDetectorRef.detectChanges();
        // !this.mapData.features?.length && this.toast.info('no vehicles found!');
        this.filterStatus();
    });
}

    // To set Filter status
    filterStatus() {
        let nonNullParameters = [];
        if(this.filterModalValue) {
            nonNullParameters = Object.entries(this.filterModalValue).reduce((prev, curr) => {
                let filterKey = curr[1]?.toString() || '';
                if (filterKey != undefined && filterKey != '' && filterKey != null) {
                    prev.push(curr)
                }
                return prev;
            }, []);
        }
        this.setFilterStatus(nonNullParameters);
    }

    // To get bounding box values from azure maps to fetch vehicles with in that selected bound box
    getBounds(event) {
        this.mapBounds = event;
        setTimeout(() => {
            if (this.mapOrList === 'map') this.showAzureMap();
            else this.showListView();
            // this.renderComponent();
            // if(this.initialDealerLoad) {
            if(event?.zoom > 6) {
                // get dealer list
                let boundsQuery = (this.mapBounds) ? `&bbox=${this.mapBounds.bounds.join(',')}` : `&bbox=-192.26074218749898,9.275622176793007,-3.7353515625005684,58.72259882804346`;
                this.http.get(`dashboard/maps-geofence?categories=HINO_DEALERS${boundsQuery}`).subscribe(response => {
                    this.dealerList = response.data;
                    this.initialDealerLoad = false;
                });
            }
        }, 6000);
    } 
    }