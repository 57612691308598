<section class="route-container">
    <!-- loader -->
    <div *ngIf="isLoading" class="gear-loader dashboard-loader"></div>
    <section class="main-title">
        <div *ngIf="listView">
            <!-- <span class="last-location-info">
                <mat-icon>info</mat-icon>
                The last location marked with 'xx' will not show in map view
            </span> -->
        </div>
   
        <!-- dashboard buttons -->
        <div class="main-title-right-panel dashboard-buttons" fxFlex fxLayoutAlign="flex-end">
            <a *ngIf="!openMapView" class="dashboard-button" [title]="'SHOW MAP VIEW' | translate | titlecase"  (click)="showAzureMap()">
                <!-- <mat-icon [class.active]="mapView">map</mat-icon> -->
                <img style="height: 30px;" src="assets/images/map/dashboard_map.png" class="logo-small" alt="" *ngIf="mapView">
                <img style="height: 30px;" src="assets/images/map/dashboard_map_greyed.png" class="logo-small" alt="" *ngIf="!mapView">

            </a>
            <a *ngIf="!openMapView" class="dashboard-button" [title]="'SHOW TABLE VIEW' | translate | titlecase" (click)="showListView()">
                <mat-icon [class.active]="listView">format_list_bulleted</mat-icon>
            </a>
            <a *ngIf="!openMapView" class="dashboard-button-stacked" [title]="'FILTER VEHICLES' | translate | titlecase" (click)="open(dashboardListFilter)">
                <div class="group-icons">
                    <mat-icon id="mat2" [class.active]="filterInfo?.isFilterApplied">{{ filterInfo?.isFilterApplied ? 'error_outline' : '' }}</mat-icon>
                    <mat-icon id="mat1" [class.active]="filterInfo?.isFilterApplied">{{ filterInfo?.isFilterApplied ? 'filter_alt' : 'filter_alt' }}</mat-icon>                    
                </div>
            </a>
            <a (click)="closeMapViewFunction()" *ngIf="openMapView" href="javascript:void(0)">
                <mat-icon>cancel</mat-icon>Close Map
            </a>
            <!-- fitler status -->
            <section *ngIf="filterInfo?.isFilterApplied" class="applied-filters">
                <div class="filter-before">Applied Filters: </div>
                <ng-container *ngFor="let filter of filterInfo?.appliedFilters">
                    <span>{{filter}}</span>
                </ng-container>
            </section>
           
        </div>
    </section>

    <!-- dashboard map  -->
    <section class="dashboard-map-container" [ngStyle]="{'height': isChartsHidden ? null : 'calc(100vh - 113px - 44px)'}" *ngIf="mapView">
        <div class="status-container">
            <span class="driving">{{ 'VEHICLE_STATUS.Driving' | translate }}</span>
            <span class="stopped">{{ 'VEHICLE_STATUS.Stopped' | translate }}</span>
            <span class="idle">{{ 'VEHICLE_STATUS.Idle' | translate }}</span>
            <!-- <span class="pto">{{ 'VEHICLE_STATUS.PTO' | translate }}</span> -->
            <span class="faulty">{{ 'VEHICLE_STATUS.Faulty' | translate }}</span>
        </div>
        <app-map-dashboard (getClickedVehicleDetails)="getClickedVehicleDetails($event)" (getBounds)="getBounds($event)" [dealerList]="dealerList" [data]="mapData" [selectedNearestDealer]="selectedNearestDealer"></app-map-dashboard>
    </section>

    <!-- list View -->
    <section *ngIf="listView">
        <!-- <app-list-view [tableData]="vehicleList" [displayedColumns]="displayedColumnsDashboard" [pageData]="pageData"
            (selectedRowData)="selectedRowDataTransmit($event)"></app-list-view> -->

        <app-pagination-list-control 
            [config]="paginationConfig"
            [tableDataList]="vehicleList"
            [pageData]="pageData"
            (outPaginationData)="outPaginationData($event)"
            (outputSelectedRows)="outputSelectedRows($event)"
            (outputSortSelection)="outputSortSelection($event)"
        ></app-pagination-list-control>
    </section>

    <!-- right sidebar -->
    <div>
        <mat-sidenav-container class="rightBar sidenav-container">
            <mat-sidenav #rightBar class="fleet-sidenav" [(opened)]="rightBarState" position="end" mode="side" #drawer
                fixedInViewport="true" [fixedTopGap]="95">
                <!-- vehicle data -->
                <mat-icon class="fleet-sidenav-close" color='warn' (click)="closeRightBar()">close
                </mat-icon>
                <mat-icon matTooltip="More" matTooltipPosition="below" class="fleet-sidenav-close" style="left: 35px;" color='warn' (click)="openDetailPage()">more_horiz
                </mat-icon>
                <!-- sidebar vehicle header -->
                <section class="vehicle-header-wrapper">
                    <app-vehicle-header *ngIf="selectedRowData" [vinNumber]="selectedRowData?.vehicle?.vehicleVin" [vehicle]="selectedRowData?.vehicle"></app-vehicle-header>
                </section>
                <!-- sidebar vehicle body -->
                <section  *ngIf="currentVehicleData?.odometer" class="odometer-reading">
                    <mat-icon>speed</mat-icon>
                    <h4 class="odometer-content" >
                        <!-- <mat-icon>speed</mat-icon>&nbsp; -->
                        {{ (currentUserdistanceCode === 'MILES') ? (selectedRowData.odometer / 1.609).toFixed(2) || '0' : selectedRowData.odometer || '0'}}&nbsp;
                        <span *ngIf="currentUserPreference">{{ (currentUserdistanceCode === 'MILES') ? 'mi' : 'km'}}</span>
                    </h4>
                </section>
                <!-- nearest dealers -->
                <ng-container *ngIf="!openMapView">
                    <mat-accordion class="location-history" fxLayout="column" style="margin-top:25px;">
                        <mat-expansion-panel fxLayout="column" fxFlex="100" [expanded]="true">
                            <mat-expansion-panel-header style="background-color:#fafafa">
                                <mat-panel-title>
                                    <h4>{{'VEHICLE INFORMATION' |translate |titlecase }}</h4>
                                </mat-panel-title>
                            </mat-expansion-panel-header> 
                            <app-right-side-bar-details [module]="'dashboard'" [selectedRowData]="selectedRowData" [vocationDropdowns]="vocationDropdowns">
                            </app-right-side-bar-details>
                        </mat-expansion-panel>
                    </mat-accordion>
                    <mat-accordion class="location-history" fxLayout="column" style="margin-top:5px;">
                        <mat-expansion-panel fxLayout="column" fxFlex="100">
                            <mat-expansion-panel-header style="background-color:#fafafa">
                                <mat-panel-title>
                                    <h4>{{'NEAREST DEALERS' |translate |titlecase }}</h4>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                            <ng-container *ngIf="nearestLocations?.length; else noNearest">
                                <section class="">
                                    <table class="nearest-dealer-list">
                                        <thead>
                                            <th>{{'TITLE' |translate |titlecase }}</th>
                                            <th>{{'DISTANCE' |translate |titlecase }}</th>
                                            <th>{{'DESCRIPTION' |translate |titlecase }}</th>
                                            <th></th>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let n of nearestLocations" style="cursor: pointer;" (click)="findDealerOnMap(n)">
                                                <td [title]="n.title">{{ n.title || blankFieldText }}</td>
                                                <td>{{ n.distance ? (n.distance | uomConverter: 'distance') : blankFieldText }}</td>
                                                <td>{{ n.fullAddress || blankFieldText }}</td>
                                                <td>
                                                    <span style="display: flex; justify-content: space-between;">
                                                        <span [title]="n.phone"><a href="{{'tel:'+ n.phone}}"><mat-icon style="color: rgb(176, 48, 52); cursor: pointer;">call</mat-icon></a></span>
                                                        <!-- <span [title]="n.fax"><a href="javascript:void(0)"><mat-icon style="color: rgb(176, 48, 52);">print</mat-icon></a></span> -->
                                                        <span [title]="n.website"><a href="{{'//' + n.website}}" target="_blank"><mat-icon style="color: rgb(85, 85, 85); cursor: pointer;">language</mat-icon></a></span>
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </section>
                            </ng-container>
                            <ng-template #noNearest>
                                <div class="no-data"><span>{{'NO NEAREST DEALERS' |translate |titlecase }}</span></div>
                            </ng-template>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-container *ngIf="!openMapView">
                    <!-- location history -->
                    <mat-accordion class="location-history" fxLayout="column" style="margin-top:5px;">
                        <mat-expansion-panel fxLayout="column" fxFlex="100" [expanded]="openMapView" (opened)="openLocationHistory()" (closed)="closeMapViewFunction()">
                            <mat-expansion-panel-header style="background-color:#fafafa">
                                <mat-panel-title>
                                    <h4>{{'LOCATION HISTORY' |translate |titlecase }}</h4>
                                </mat-panel-title>
                            </mat-expansion-panel-header>
                        </mat-expansion-panel>
                    </mat-accordion>
                </ng-container>
                <ng-container *ngIf="openMapView">
                            <div class="text-center" style="margin-top: 25px;">
                                <label class="vehicle-data">{{'LOCATION HISTORY' |translate |titlecase}}</label>
                            </div>
                            <!-- location search -->
                            <div class="col-12" style="margin: 0 0 20px; padding: 0px 16px 0 20px">
                                <app-search [searchPlaceholder]="'routes'" (searchOutput$)="searchOutput($event)"></app-search>
                            </div>
                            <!-- route repeater -->
                            <section *ngIf="routesData?.data?.length; else noRoutes" class="routes">
                                <div class="route-table-title">
                                    <span>{{'FROM' |translate |titlecase }}</span>
                                    <span>{{'TO' |translate |titlecase }}</span>
                                    <span>{{'DATE' |translate |titlecase }}</span>
                                    <span>&nbsp;</span>
                                </div>
                                <div *ngIf = "!displayBtn">
                                    <a *ngFor="let pathData of routesData?.data; index as i;" (click)="setSelection($event, pathData, i);"
                                    [attr.data-selection]="isRouteSelected(pathData) ? '1' : '0'" [class.active]="isRouteSelected(pathData)"
                                    href="javascript:void(0)" class="path-instance">
                                    <span [title]="pathData.start">{{ pathData.start }}</span>
                                    <span [title]="pathData.end">{{ pathData.end }}</span>
                                    <span>{{ pathData.dateInText }}</span>
                                    <span><input type="checkbox" [checked]="isRouteSelected(pathData)"></span>
                                </a>
                                </div>
                                <div *ngIf = "displayBtn">
                                    <div *ngIf = "displayAll">
                                        <a *ngFor="let pathData of routesData?.data; index as i;" (click)="setSelection($event, pathData, i);"
                                        [attr.data-selection]="isRouteSelected(pathData) ? '1' : '0'" [class.active]="isRouteSelected(pathData)"
                                        href="javascript:void(0)" class="path-instance">
                                        <span [title]="pathData.start">{{ pathData.start }}</span>
                                        <span [title]="pathData.end">{{ pathData.end }}</span>
                                        <span>{{ pathData.dateInText}}</span>
                                        <span><input type="checkbox" [checked]="isRouteSelected(pathData)"></span>
                                    </a>
                                    </div> 
                                    <div *ngIf = "!displayAll">
                                        <a *ngFor="let pathData of routesData?.data | slice:0:10; index as i;" (click)="setSelection($event, pathData, i);"
                                        [attr.data-selection]="isRouteSelected(pathData) ? '1' : '0'" [class.active]="isRouteSelected(pathData)"
                                        href="javascript:void(0)" class="path-instance">
                                        <span [title]="pathData.start">{{ pathData.start }}</span>
                                        <span [title]="pathData.end">{{ pathData.end }}</span>
                                        <span>{{ pathData.dateInText }}</span>
                                        <span><input type="checkbox" [checked]="isRouteSelected(pathData)"></span>
                                    </a>
                                    </div>
                                 </div>
                                 <button *ngIf="displayBtn" type="button" class="btn btn-outline-dark btn-sm" style="right: 0px; margin: 5%;">
                                    <span (click)="showAll()">{{ (!displayAll ? 'MORE' : 'LESS') | translate | titlecase }}</span>
                                </button>
                             </section>
                          <ng-template #noRoutes>
                                <div class="no-data">
                                    <span *ngIf="isLoading">{{'FETCHING ROUTES' |translate |titlecase }}</span>
                                    <span *ngIf="!isLoading && !routesData?.data?.length">{{'NO ROUTES FOUND' |translate |titlecase }}</span>
                                </div>
                            </ng-template>
                </ng-container>
                <ng-container *ngIf="!openMapView">
                    <app-utilization *ngIf="rightBarState" [chartApiData]="utilizationChart"></app-utilization>
                    <!-- fuel comparison chart -->
                    <!-- <app-peer-comparison *ngIf="comparisonChartState" [title]="'Fuel Economy'" [chartApiData]="fuelEconomyComparisonChart">
                    </app-peer-comparison> -->
                    <!-- idletime comparison chart -->
                    <!-- <app-peer-comparison *ngIf="comparisonChartState" [title]="'Idle Time'" [chartApiData]="idleTimeComparisonChart">
                    </app-peer-comparison> -->
                    <app-fuel-economy *ngIf="rightBarState" [chartApiData]="fuelEconomyChart"></app-fuel-economy>
                    <app-idle-time *ngIf="rightBarState" [chartApiData]="idleTimeChart"></app-idle-time>
                    <app-comparison *ngIf="rightBarState" [chartApiData]="comparisonChart"></app-comparison>
                </ng-container>
            </mat-sidenav>
        </mat-sidenav-container>
    </div>

    <!-- charts minimize/maximize -->
    <section class="charts-visibility" [ngStyle]="{'margin-bottom': isChartsHidden ? null : '20px'}">
        <a [class.enhance]="!isChartsHidden" (click)="setChartsVisibility()" href="javascript:void(0)">
            <span>{{ (isChartsHidden ? 'HIDE' : 'SHOW') | translate }} {{ 'CHARTS' | translate }}</span>
            <mat-icon>{{ isChartsHidden ? 'remove_circle_outline' : 'add' }}</mat-icon>
        </a>
    </section>

    <!-- 3 charts -->
    <section [hidden]="!isChartsHidden" class="overall-charts">
        <!-- def level -->
        <section>
            <div class="title">DEF {{ 'LEVEL OVERVIEW FOR' | translate }} {{ 'MONTHS.' + currentMonth | translate | titlecase }}</div>
            <div class="chart-wrapper">
                <app-dashboard-def-chart (chartClickedIndex)="defChartClicked($event)" *ngIf="isShowCharts" [chartApiData]="dashDefChart"></app-dashboard-def-chart>
            </div>
        </section>
        <!-- utilization overview -->
        <section>
            <div class="title">{{ 'UTILIZATION OVERVIEW FOR' | translate }} {{ 'MONTHS.' + currentMonth | translate | titlecase }}</div>
            <div class="chart-wrapper">
                <app-dashboard-utilization *ngIf="isShowCharts" [chartApiData]="dashUtilizationChart"></app-dashboard-utilization>
            </div>
        </section>
        <!-- fuel economy peer comparison -->
        <section>
            <div class="title">
                {{ 'FUEL ECONOMY' | translate }} 
                <!-- ({{user.acccount.accountName | translate}} <span style="text-transform: lowercase;">{{ 'VS' | translate }}</span> 
                {{ 'PEERS' | translate }}) -->
            </div>
            <div class="chart-wrapper">
                <app-dashboard-fuel-economy *ngIf="isShowCharts"
                    [chartApiData]="dashFuelEconomyChart" [type] = "0"></app-dashboard-fuel-economy>
            </div>
        </section>
        <section>
            <div class="title">
                {{ 'IDLE TIME' | translate }} 
                <!-- {{ 'PEERS' | translate }} -->
            </div>
            <div class="chart-wrapper">
                <app-dashboard-fuel-economy *ngIf="isShowCharts" [idleTimeData]="dashIdleTimeChart" [type] = "1"
                    ></app-dashboard-fuel-economy>
            </div>
        </section>
    </section>

    <!-- location history map view -->
    <app-azure-map *ngIf="openMapView" (click)="rightBarState = true" [routesData]="routesData"
        [currentLocation]="selectedRowData.lastLocation?.geoTag?.geometry?.coordinates" [selectedVehicle]="selectedRowData" (mapRoutes)="showMapRoutes($event)"></app-azure-map>

    <!-- filter modal -->
    <ng-template #dashboardListFilter let-modal>
        <app-filter-modal-dashboard [mapView] ="mapView" [depotList]="depotList" [customerList]="customerList" [statusList]="statusList"  [defLevels]="defLevels" (filterFormValue)="filterModalSubmit($event)"></app-filter-modal-dashboard>
        <div class="modal-footer">
            <button type="button" class="btn btn-outline-dark btn-sm"
                (click)="modal.close()"><span>{{ 'CANCEL' | translate | titlecase }}</span></button>
            <button type="button" style="background-color:#b03034" class="btn btn-sm"
                (click)="modal.close('filter')"><span style="color:white">{{ 'APPLY FILTERS' | translate | titlecase }}</span></button>
        </div>
    </ng-template>
</section>
 