<div *ngIf="!locationHistoryDetails" fxLayout="column">
    <div class="side-paddings" fxLayout="column" style="color: rgba(128, 128, 128, 0.8) !important;">

        <div *ngIf="module === 'depot' && selectedRowData?.currentOwnership?.transferredOn" fxLayout="row" fxFlex class="rowLabel">
            <div fxFlex><label class="textKey"></label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue"><span class="transfer-label">Transferred</span></div>
        </div>
        <div *ngIf="isDepot" fxLayout="row" fxFlex class="rowLabel">
            <div fxFlex><label class="textKey">VIN</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue">{{ selectedRowData.vehicle?.vehicleVin }}</div>
        </div>

        <!-- tags -->
        <div fxLayout="row" fxFlex fxLayoutAlign="center flex-start" class="rowLabel">
            <div fxFlex><label class="textKey">{{'CASEMANAGEMENTALLTABLES.TAG' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue" *ngIf="!tagEditEnable">
                <label [title]="selectedRowData.tags" style="text-transform: lowercase;">
                    {{ selectedRowData.tags?.length > 20 ? selectedRowData.tags?.slice(0, 20) + '...' :
                    (selectedRowData?.tags || blankFieldText ) }}</label>
                <mat-icon *ngIf="!isDepot && !isHinoInternal" class="mat-cell"
                    style="margin: 0 0 0 10px; font-size:16px; cursor: pointer;" (click)="editTag()">edit
                </mat-icon>
            </div>
            <mat-icon fxFlex fxLayoutAlign="flex-end" *ngIf="!isDepot && tagEditEnable" class="mat-cell"
                style="margin: 12px 8px 0 0; font-size:16px; cursor: pointer;" (click)="tagChange()">close
            </mat-icon>
        </div>
        <!-- tags edit section -->
        <div *ngIf="tagEditEnable">
            <mat-form-field class="tags-edit-section">
                <mat-chip-list #chipList>
                    <mat-chip *ngFor="let tag of selectedRowData.originalTags" [selectable]="true" [removable]="true"
                        (removed)="removeTag(tag)">
                        {{ tag.tagName | lowercase }}
                        <mat-icon matChipRemove>cancel</mat-icon>
                    </mat-chip>
                    <input id="matChipInput" placeholder="Add Tags" [matChipInputFor]="chipList"
                        [matChipInputSeparatorKeyCodes]="separatorKeysCodes" [matChipInputAddOnBlur]="true"
                        (matChipInputTokenEnd)="addTag($event)">
                </mat-chip-list>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center flex-start" class="rowLabel">
            <div fxFlex><label class="textKey">{{'DEPOT' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue">{{ selectedRowData.depot?.depotName|| blankFieldText }}</div>
        </div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center flex-start">
            <div style="min-width: 150px; display: flex; align-items: center;">
                <span><label class="textKey">{{'LAST REPORTED LOCATION' |translate |titlecase }}</label></span>
                <span class="material-icons" style="font-size: 1.6em" matTooltip [matTooltip]="locationTooltip" [matTooltipPosition]="'below'"
                    matTooltipHideDelay="50">info</span>
            </div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue" [title]="selectedRowData.lastLocation?.lastReportedLocation">
                {{ selectedRowData.lastLocation?.lastReportedLocation ? ( selectedRowData.lastLocation?.lastReportedLocation)  : blankFieldText }}
            </div>
        </div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center flex-start" class="rowLabel">
            <div fxFlex><label class="textKey"><p (click)="viewInGoogleMaps()" style="color:blueviolet; cursor: pointer;"  onMouseOver="this.style.textDecoration='underline'" onMouseOut="this.style.textDecoration='none'">View In Google Maps</p></label><img src="../../../../assets/images/google-maps.png" alt="" style="height: 1rem"></div>
        </div>
        
        <div fxLayout="row" fxFlex fxLayoutAlign="center flex-start" class="rowLabel">
            <div fxFlex><label class="textKey">{{'LAST REPORTED TIME' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue">
                {{ selectedRowData.lastLocation?.lastReportedtime ? (selectedRowData.lastLocation?.lastReportedtime | vehicleTimeHandler) : blankFieldText }}
            </div>
        </div>

        <div fxLayout="row" fxFlex fxLayoutAlign="center flex-start" class="rowLabel">
            <div fxFlex><label class="textKey">{{'VOCATION' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" *ngIf="!vocationEditEnable"><label
                    class="textValue">{{selectedRowData.vocation?.vocationName || blankFieldText}}</label>
                <mat-icon *ngIf="!isDepot && !isHinoInternal" class="mat-cell"
                    style="margin-top:10px; font-size:16px; cursor: pointer;" (click)="toggleVocation(true)">edit
                </mat-icon>
            </div>
            <div fxFlex fxLayoutAlign="flex-end" *ngIf="vocationEditEnable" class="textValue">
                <select class="editable" (change)="vocationChange($event)">
                    <option value="">{{'CHOOSE VOCATION' |translate |titlecase }}</option>
                    <option *ngFor="let option of vocationDropdowns" [value]="option.taxonomyId">
                        {{ option.taxonomyName }}</option>
                </select>
            </div>
        </div>

        <div fxLayout fxFlex fxLayoutAlign="center flex-start" class="rowLabel">
            <div fxFlex><label class="textKey">{{'CASEMANAGEMENTALLTABLES.STATUS' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue">{{selectedRowData.drivingStatus?.transmissionStatus|| blankFieldText }}</div>
        </div>
        <div fxLayout="row" fxFlex fxLayoutAlign="center" *ngIf="isDecisivCasemanagementEnabled">
            <div fxFlex><label class="textKey">{{'CASES' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue">
                <ng-container *ngIf="selectedRowData.cases?.length; else noCases">
                    <a href="javascript:void(0)" (click)="navigateToCaseManagement()">
                        <!-- <mat-icon matTooltip [matTooltip]="'Case Id - ' + case.caseId" [matTooltipPosition]="'right'"
                            matTooltipHideDelay="50" style="color: #b03034;">warning</mat-icon> -->
                        <img matTooltip [matTooltip]="selectedRowData.caseList" [matTooltipPosition]="'right'"
                            matTooltipClass="line-broken-tooltip" matTooltipHideDelay="50"
                            src="../../../../assets/images/case_module/Case MGT.png" style="width: 32px;"
                            style="margin-top: -10px; width: 32px;" src="../../../../assets/images/case_module/Case MGT.png"
                            width="60px">
                    </a>
                </ng-container>
                <ng-template #noCases>
                    {{ blankFieldText  }}
                </ng-template>
            </div>
        </div>
        <div fxLayout="row" fxFlex fxLayoutAlign="center" *ngIf="isDecisivCasemanagementEnabled">
            <div fxFlex><label class="textKey">{{'Active Faults' |translate |titlecase }}</label></div>
                <div fxFlex fxLayoutAlign="flex-end" class="textValue">
                    <ng-container *ngIf="selectedRowData.activeFaults?.length; else noActiveFaults">
                        <div (click)="openDetailPage()" style="color:rgb(54, 57, 231); cursor: pointer;"  onMouseOver="this.style.textDecoration='underline'" onMouseOut="this.style.textDecoration='none'">
                        <ng-container *ngFor="let faults of selectedRowData.activeFaults; let last = last">
                            {{ faults.fault_cd || blankFieldText }}
                            <span *ngIf="!last">, &nbsp; </span>
                        </ng-container>
                        </div>
                    </ng-container>
                          <ng-template #noActiveFaults>
                            {{"No Active Faults"}}
                          </ng-template>
                </div>
        </div>
        
        <div fxLayout="row" fxFlex fxLayoutAlign="center" *ngIf="!selectedRowData?.currentOwnership?.transferredOn">
            <div fxFlex><label class="textKey">{{'TSP SUBSCRIPTIONS' |translate |titlecase }}</label></div>
            <div fxFlex fxLayoutAlign="flex-end" class="textValue">
                <div class="tsp-subscription-container" *ngIf="selectedRowData?.tsps?.length; else noImages">
                    <ng-container *ngFor="let tsp of selectedRowData.tsps">
                        <img matTooltip [matTooltip]="tsp.tspName" [matTooltipPosition]="'right'"
                        matTooltipClass="line-broken-tooltip" matTooltipHideDelay="50" [src]="tsp.tspImageUrl" alt="" height="36" style="cursor: pointer;" (click)="navigateToTelematicsPage()">
                    </ng-container>
                </div>
                <ng-template #noImages>
                    {{blankFieldText}}
                </ng-template>
            </div>
        </div>
    </div>
</div>

<div *ngIf="locationHistoryDetails" fxLayout="column">
    <img style="width:60px;height:40px;align-self: center;"
        src="https://tiq.azureedge.net/hino-website/Uploads/2020-02/7dcf66ed-8ee8-4a1f-9c48-9ca0aae3e917{69758}[600x400].jpg"
        alt="truckimage">
    <h4 style="text-align:center"> VIN-ABC1234 | {{'CASEMANAGEMENTALLTABLES.TAG' |translate |titlecase }} - 8399</h4>
    <label style="color:rgb(128, 128, 128,0.6);text-align:center">{{'DATA LAST UPDATED' |translate |titlecase }} @ 11:00
        am</label>
    <!-- <mat-selection-list>
            <mat-toolbar style="height:25px">
                <div class="fontSize">Location History</div>
                <div fxFlex>
                    <mat-icon fxLayoutAlign="flex-end" class="fontSize">search</mat-icon>
                </div>
            </mat-toolbar>
            <mat-list-option>
                <div matLine>
                    <p class="fontSize" style="float:left">Location A</p>
                    <p class="fontSize" style="float:right">Time</p>
                </div>
            </mat-list-option>
            <mat-list-option>
                <div matLine>
                    <p class="fontSize" style="float:left">Location B</p>
                    <p class="fontSize" style="float:right">Time</p>
                </div>
            </mat-list-option>
        </mat-selection-list> -->
    <!-- <header>
            <h4>LOCATION HISTORY</h4>
            <mat-icon>search</mat-icon>
        </header> -->
    <table style="width:100%;height:100%;" mat-table [dataSource]="dataSource" class="mat-elevation-z8" fxFlex="100">
        <ng-container matColumnDef="location">
            <th mat-header-cell *matHeaderCellDef> {{'CASEMANAGEMENTALLTABLES.LOCATION' |translate |titlecase }} </th>
            <td mat-cell *matCellDef="let element"> {{element.location}} </td>
        </ng-container>
        <ng-container matColumnDef="time">
            <th mat-header-cell class="tagColumnHeader" *matHeaderCellDef> {{'TIME' |translate |titlecase }}</th>
            <td mat-cell *matCellDef="let element"> {{element.time}}</td>
        </ng-container>
        <ng-container matColumnDef="select">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox (click)="$event.stopPropagation()" (change)="$event ? selection.toggle(row) : null"
                    [checked]="selection.isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
</div>