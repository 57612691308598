import { Injectable } from '@angular/core';
import { CryptoService } from './crypto.service';
import { IUserInfo } from '@core/models/app.model';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    constructor(
        private cryptoService: CryptoService,
    ) { }

    isUserLoggedIn = (): boolean => !!localStorage.getItem('user');

    getUserInfo = (): IUserInfo => this.cryptoService.decryptData(localStorage.getItem('user'));
}
