<form [formGroup]="formGroup">
    <div class="filter-modal">
        <span class="title">{{ 'FILTER PANEL' | translate | titlecase }}</span>
        <a (click)="formGroup.reset();clearDropdowns();" [title]="'RESET ALL FILTERS' | translate | titlecase" href="javascript:void(0)">
            <mat-icon>search_off</mat-icon>
        </a>
    </div>

    <div class="form-wrapper">
        <div class="row">
            <div class="col-4">
                <label class="filterLabels">VIN</label>
                <input formControlName="VIN" class="inputCSS">
            </div>
          
            <div class="col-4">
                <label class="filterLabels">{{ 'TAGS' | translate | titlecase }}</label>
                <input formControlName="tag" class="inputCSS">
            </div>

            <div class="col-4">
                <label class="filterLabels">{{ 'CHOOSE STATUS' | translate | titlecase }}</label>
                <!-- <select class="inputCSS" formControlName="status">
                    <option *ngFor="let option of statusList" [value]="option.taxonomyCode">
                        {{ option.taxonomyName }}</option>
                </select> -->
                <ng-container *ngIf="statusList?.length">
                    <ng-multiselect-dropdown [settings]="dropdownSettings" [data]="statusList" formControlName="status"
                        (onDeSelect)="onStatusDeSelect($event)"  [placeholder]="'Select status'"
                        (onSelect)="onStatusSelect($event)" (onSelectAll)="onStatusSelectAll($event)" 
                        (onDeSelectAll)="onStatusDeSelectAll($event)">
                    </ng-multiselect-dropdown>
                </ng-container>
            </div> 
        </div>
        <div class="row">
            <div class="col-4 depot-selection">
                <label class="filterLabels">{{ 'CHOOSE DEPOT' | translate | titlecase }}</label>
                <!-- <select class="inputCSS" formControlName="depot_id">
                    <option *ngFor="let depot of depotList" [value]="depot.depotId">
                        {{ depot.depotName }}</option>
                </select> -->
                <ng-multiselect-dropdown [settings]="depotDropdownSettings" [data]="depotList" formControlName="depot_id"
                    [placeholder]="'Select Depot'" (onDeSelect)="onDepotDeSelect($event)" (onSelect)="onDepotSelect($event)">
                </ng-multiselect-dropdown>
            </div>
            <div [ngClass]="isHinoInternal ? 'col-3' : 'col-4'" class="depot-selection">
                <label class="filterLabels">{{ 'CHOOSE DEF LEVEL' | translate | titlecase }}</label>
                <ng-multiselect-dropdown [settings]="defDropdownSettings" [data]="defLevels" formControlName="def_level"
                    [placeholder]="'Select DEF Level'" (onDeSelect)="onDefLevelDeSelect($event)" (onSelect)="onDefLevelSelect($event)">
                </ng-multiselect-dropdown>
            </div>
            <div *ngIf="isHinoInternal" class="col-5">
                <label class="filterLabels">{{ 'CHOOSE CUSTOMER' | translate | titlecase }}</label>
                <ng-multiselect-dropdown [settings]="cusDropdownSettings" [data]="customerList" formControlName="customer_id"
                    (onDeSelect)="onCustomerDeSelect($event)" [placeholder]="'Select Customer'" 
                    (onSelect)="onCustomerSelect($event)" (onSelectAll)="onCustomerSelectAll($event)"
                    (onDeSelectAll)="onCustomerDeSelectAll($event)">
                 </ng-multiselect-dropdown>
            </div>
        </div>
    </div>
</form>