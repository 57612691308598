import { Component, OnInit } from '@angular/core';
import { AppService } from '@core/services/app.service';


@Component({
    selector: 'app-route-title',
    templateUrl: './route-title.component.html',
    styleUrls: ['./route-title.component.scss']
})
export class RouteTitleComponent implements OnInit {
    title: string;

    constructor(
        private appService: AppService,
    ) { }

    ngOnInit(): void {
        this.appService.pageTitle$.subscribe(value => this.title = value);
    }

}
