
export interface UserList {
    [x: string]: any;
    // checked: boolean;
    // photo: string;
    name: string;
    email: string;
    contact: string;
    // location: string;
    lastlogin: string;
    roles: string;
}
export const getRoles = (rolesArray: string[]): string => {
    const roles = rolesArray.map((el: any) => el?.role?.role_name).reduce((prev, curr) => prev += `${curr}, `, '');
    return roles?.length ? roles.slice(0, -2) : '';
}
export const getLastLogin = (loginArray: string[]): string => {
    const logins = loginArray.map((el: any) => el?.login_time).reduce((prev, curr) => prev += `${curr}, `, '');
    return logins?.length ? logins.slice(0, -2) : '';
}