import { Component, OnInit } from '@angular/core';
import { authSettings } from '@config/oauth';

@Component({
  selector: 'app-customer-login',
  templateUrl: './customer-login.component.html',
  styleUrls: ['./customer-login.component.scss']
})
export class CustomerLoginComponent implements OnInit {
  isPrivacyChecked: boolean;

  constructor() { }

  ngOnInit(): void {
    this.isPrivacyChecked = true;
    try {
      this.isPrivacyChecked = window.self !== window.top;
    } catch(e) {
      this.isPrivacyChecked = true;
    }
    window.location.href = authSettings['b2c_auth'];
  }
}

