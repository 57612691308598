import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { FormsModule } from '@angular/forms';
import { ReactiveFormsModule } from '@angular/forms';
import { MaterialModule } from '@material/material.module';
import { FlexLayoutModule } from '@angular/flex-layout';
import { RouteTitleComponent } from './components/route-title/route-title.component';
import { SearchComponent } from './components/search/search.component';
import { UtilizationComponent } from './components/graphs/utilization/utilization.component';
import { FuelEconomyComponent } from './components/graphs/fuel-economy/fuel-economy.component';
import { TableComponent } from './components/tables/dashboard/table/table.component';
import { AssignListComponent } from './components/tables/depotManagement/assign-list/assign-list.component';
import { UnassignListComponent } from './components/tables/depotManagement/unassign-list/unassign-list.component';
import { ActivatedComponent } from './components/tables/myFleet/activated/activated.component';
import { TransferredComponent } from './components/tables/myFleet/transferred/transferred.component';
import { PredeliveryComponent } from './components/tables/myFleet/predelivery/predelivery.component';
import { CreateDepotComponent } from './components/modal/depotManagement/create-depot/create-depot.component';
import { TransferTruckComponent } from './components/modal/myFleet/transfer-truck/transfer-truck.component';
import { DepotListComponent } from './components/list/depotManagement/depot-list/depot-list.component';
import { SelectionCardsComponent } from './components/selection-cards/selection-cards.component';
import { RightSideBarDetailsComponent } from './components/right-side-bar-details/right-side-bar-details.component';
import { UserManagementComponent } from './components/tables/user-management/user-management.component';
import { UsersGridListComponent } from './components/cards/users-grid-list/users-grid-list.component';
import { UpdateRoleComponent } from './components/modal/user-management/update-role/update-role.component';
import { AddNewUserCorporateComponent } from './components/modal/user-management/add-new-user-corporate/add-new-user-corporate.component';
import { AddNewUserCustomerComponent } from './components/modal/user-management/add-new-user-customer/add-new-user-customer.component';
import { FilterUserManagementComponent } from './components/modal/user-management/filter-user-management/filter-user-management.component';
import { ViewProfileUserManagementComponent } from '@core/components/view-profile-user-management/view-profile-user-management.component';
import { ChangePasswordUserManagementComponent } from '@core/components/change-password-user-management/change-password-user-management.component';
import { ViewPermissionsUserManagementComponent } from '@core/components/view-permissions-user-management/view-permissions-user-management.component';
import { ViewPreferencesUserManagementComponent } from '@core/components/view-preferences-user-management/view-preferences-user-management.component';

// ng2-charts
import { ChartsModule } from 'ng2-charts';
import { ComparisonComponent } from './components/graphs/comparison/comparison.component';
import { IdleTimeComponent } from './components/graphs/idle-time/idle-time.component';
import { FilterComponent } from './components/modal/myFleet/filter/filter.component';

// ng-bootstrap
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// @ngmodule/material-carousel
import { MatCarouselModule } from '@ngmodule/material-carousel';
import { FilterOpenCasesComponent } from './components/modal/caseManagement/filter-open-cases/filter-open-cases.component';
import { FilterOpenRecallsComponent } from './components/modal/caseManagement/filter-open-recalls/filter-open-recalls.component';
import { FilterMaintainanceComponent } from './components/modal/caseManagement/filter-maintainance/filter-maintainance.component';
import { MaintainanceConfirmComponent } from './components/modal/caseManagement/maintainance-confirm/maintainance-confirm.component';
import { VehicleListComponent } from './components/tables/depotManagement/vehicle-list/vehicle-list.component';
import { LocationHistoryComponent } from './components/location-history/location-history.component';
import { AzureMapComponent } from './components/azure-map/azure-map.component';
import { VehicleHeaderComponent } from './components/vehicle-header/vehicle-header.component';
import { GeofenceFilterComponent } from './components/geofence-filter/geofence-filter.component';
import { CreateGeofenceComponent } from './components/create-geofence/create-geofence.component';
import { AliasUserComponent } from './components/alias-user/alias-user.component';
import { PeerComparisonComponent } from './components/peer-comparison/peer-comparison.component';
import { MeasurementPreferencePipe } from './pipes/measurement-preference.pipe';
import { AppTimeHandlerPipe } from './pipes/app-time-handler.pipe';
import { VehicleTimeHandlerPipe } from './pipes/vehicle-time-handler.pipe';
import { NumbersOnlyDirective } from './directives/numbers-only.directive';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { DepotSelectionCardComponent } from './components/depot-selection-card/depot-selection-card.component';
import { SelectDropDownModule } from 'ngx-select-dropdown';
import { AutocompleteComponent } from './components/autocomplete/autocomplete.component';
import { PaginationListControlComponent } from './components/pagination/pagination-list-control/pagination-list-control.component';
import {ListControlComponent} from './components/pagination/list-control/list-control.component';
import { CreateDtcErrorComponent } from './components/modal/dtc-error-page/create-dtc-error/create-dtc-error.component';
import { RestrictSpecialCharDirective } from './directives/SpeciaCharDirective/restrict-special-char.directive';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatButtonModule } from '@angular/material/button';
import { ClipboardModule } from 'ngx-clipboard';
import { TelematicsTableComponent } from './components/tables/telematics-table/telematics-table.component';
import { CreateNewTelematicsComponent } from './components/modal/telematics/create-new-telematics/create-new-telematics.component';
import { TelematicsConfigurationComponent } from './components/modal/telematics/telematics-configuration/telematics-configuration.component';
import { TruncateStringPipe } from './pipes/truncate-string.pipe'; 
import { DtcErrorFilterComponent } from './components/modal/dtc-error-page/dtc-error-filter/dtc-error-filter.component';
import { HistoryRequestModalComponent } from './components/modal/historyReplay/history-request-modal/history-request-modal.component';
import { NgxMatDatetimePickerModule, NgxMatNativeDateModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { SecondaryOwnershipMailConfigComponent } from './components/modal/secondary-ownership/secondary-ownership-mail-config/secondary-ownership-mail-config.component';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { DepotFilterComponent } from './components/modal/myFleet/depot-filter/depot-filter.component';
// AOT compilation requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
        return new TranslateHttpLoader(httpClient, '../assets/i18n/', '.json');
}

@NgModule({
        declarations: [
                RouteTitleComponent,
                SearchComponent,
                UtilizationComponent,
                FuelEconomyComponent,
                TableComponent,
                AssignListComponent,
                UnassignListComponent,
                ActivatedComponent,
                TransferredComponent,
                CreateDepotComponent,
                TransferTruckComponent,
                DepotListComponent,
                SelectionCardsComponent,
                RightSideBarDetailsComponent,
                UserManagementComponent,
                UsersGridListComponent,
                UpdateRoleComponent,
                AddNewUserCorporateComponent,
                AddNewUserCustomerComponent,
                ComparisonComponent,
                IdleTimeComponent,
                FilterComponent,
                FilterUserManagementComponent,
                ViewProfileUserManagementComponent,
                ChangePasswordUserManagementComponent,
                ViewPermissionsUserManagementComponent,
                ViewPreferencesUserManagementComponent,
                FilterOpenCasesComponent,
                FilterOpenRecallsComponent,
                FilterMaintainanceComponent,
                MaintainanceConfirmComponent,
                VehicleListComponent,
                LocationHistoryComponent,
                AzureMapComponent,
                VehicleHeaderComponent,
                GeofenceFilterComponent,
                CreateGeofenceComponent,
                AliasUserComponent,
                PeerComparisonComponent,
                MeasurementPreferencePipe,
                AppTimeHandlerPipe,
                VehicleTimeHandlerPipe,
                NumbersOnlyDirective,
                ConfirmDialogComponent,
                DepotSelectionCardComponent,
                AutocompleteComponent,
                PaginationListControlComponent,
                ListControlComponent,
                CreateDtcErrorComponent,
                DtcErrorFilterComponent,
                RestrictSpecialCharDirective, 
                TelematicsTableComponent,
                CreateNewTelematicsComponent,
                TelematicsConfigurationComponent,
                TruncateStringPipe,
                HistoryRequestModalComponent,
                SecondaryOwnershipMailConfigComponent,
                PredeliveryComponent,
                DepotFilterComponent,
        ],
        imports: [
                CommonModule,
                FormsModule,
                ReactiveFormsModule,
                MaterialModule,
                FlexLayoutModule,
                ChartsModule,
                NgbModule,
                ClipboardModule,
                MatTooltipModule,
                MatButtonModule,
                MatCarouselModule.forRoot(),
                TranslateModule.forChild({
                        loader: {
                                provide: TranslateLoader,
                                useFactory: HttpLoaderFactory,
                                deps: [HttpClient]
                        },
                        extend: true,  
                }),
                NgMultiSelectDropDownModule.forRoot(),
                SelectDropDownModule,
                ClipboardModule,
                NgxMatDatetimePickerModule,
                NgxMatTimepickerModule,
                NgxMatNativeDateModule,
                ScrollingModule
        ],
        exports: [
                FormsModule,
                ReactiveFormsModule,
                RouteTitleComponent,
                SearchComponent,
                MaterialModule,
                UtilizationComponent,
                FuelEconomyComponent,
                TableComponent,
                AssignListComponent,
                UnassignListComponent,
                ActivatedComponent,
                TransferredComponent,
                PredeliveryComponent,
                CreateDepotComponent,
                TransferTruckComponent,
                DepotListComponent,
                SelectionCardsComponent,
                RightSideBarDetailsComponent,
                UserManagementComponent,
                UsersGridListComponent,
                UpdateRoleComponent,
                AddNewUserCorporateComponent,
                AddNewUserCustomerComponent,
                FilterUserManagementComponent,
                ComparisonComponent,
                IdleTimeComponent,
                FilterComponent,
                DepotFilterComponent,
                ViewProfileUserManagementComponent,
                ChangePasswordUserManagementComponent,
                ViewPermissionsUserManagementComponent,
                ViewPreferencesUserManagementComponent,
                FilterOpenCasesComponent,
                FilterOpenRecallsComponent,
                FilterMaintainanceComponent,
                MaintainanceConfirmComponent,
                VehicleListComponent,
                ChartsModule,
                ClipboardModule,
                LocationHistoryComponent,
                AzureMapComponent,
                VehicleHeaderComponent,
                GeofenceFilterComponent,
                DtcErrorFilterComponent,
                CreateGeofenceComponent,
                AliasUserComponent,
                PeerComparisonComponent,
                MeasurementPreferencePipe,
                AppTimeHandlerPipe,
                VehicleTimeHandlerPipe,
                ConfirmDialogComponent,
                TranslateModule,
                NumbersOnlyDirective,
                NgMultiSelectDropDownModule,
                DepotSelectionCardComponent,
                AutocompleteComponent,
                TelematicsTableComponent,
                CreateNewTelematicsComponent,
                TelematicsConfigurationComponent,
                TruncateStringPipe,
                PaginationListControlComponent,
                ListControlComponent,
                CreateDtcErrorComponent,
                ClipboardModule,
                TruncateStringPipe,
                TelematicsTableComponent,
                CreateNewTelematicsComponent,
                TelematicsConfigurationComponent,
                HistoryRequestModalComponent
        ],
        entryComponents: [

        ],
})
export class SharedModule { }
