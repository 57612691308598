export const userTableDataConfig = {
    multiselect: true,
    showTruckIconColumn: true,
    expandRow: false,
    draggable: false,
    idField: 'id',
    columnNames: [
        { title: 'logo', addOns: { enabled: false, title: '', iconType: '' } },
        { title: 'name', addOns: { enabled: false, title: '', iconType: '' } },
        { title: 'email', addOns: { enabled: false, title: '', iconType: '' } },
        {
            title: 'contact',
            addOns: { enabled: false, title: '', iconType: '' },
        },
        {
            title: 'address',
            addOns: { enabled: false, title: '', iconType: '' },
        },
        {
            title: 'last login',
            addOns: { enabled: false, title: '', iconType: '' },
        },
        {
            title: 'associated roles',
            addOns: { enabled: false, title: '', iconType: '' },
        },
    ],
    dataFields: [
        {
            title: 'logo',
            fieldName: '',
            fieldPath: '',
            dataFormat: {
                type: '',
                url: '',
                params: '',
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: false,
            logoType: 'initial',
        },
        {
            title: 'name',
            fieldName: 'displayFullName',
            fieldPath: 'name',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'email',
            fieldName: 'emailAddress',
            fieldPath: 'email',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'contact',
            fieldName: 'mobileNo',
            fieldPath: 'contact',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'address',
            fieldName: 'location',
            fieldPath: 'location',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'last login',
            fieldName: 'lastLogin',
            fieldPath: 'lastlogin',
            dataFormat: {
                type: 'Date',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'associated roles',
            fieldName: 'roles',
            fieldPath: 'roles',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
    ],
};