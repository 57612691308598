import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { pascalCase } from '@core/helpers/app.helper';
import { AuthService } from '@core/services/auth.service';
import { CryptoService } from '@core/services/crypto.service';
import { UserService } from '@modules/user-management/services/user.service';
import { BaseHttpService } from '@core/services/base-http.service';
import { colorsSettings } from '@config/color-config';

export interface ILineChartData {
    data: any[];
    label?: string;
    type?: string;
    backgroundColor?: string;
    borderColor?: string;
}

@Component({
    selector: 'app-dashboard-fuel-economy',
    templateUrl: './dashboard-fuel-economy.component.html',
    styleUrls: ['./dashboard-fuel-economy.component.scss']
})
export class DashboardFuelEconomyComponent implements OnInit {
    @Input() idleTimeData;
    @Input() chartApiData;
    @Input() type;
    graphOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    reverse: false,
                    min: 0,
                    suggestedMax: 10,
                    stepSize: 10,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'FE & Idle Time'
                }
            }, 
            {
                display: true,
                gridLines: {display: false,color: 'transparent'},
                ticks: {display: false},
                scaleLabel: {
                    display: true,
                    labelString: '',
                }
              }],
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                }
            }]
        },
        legend: {
            display: true,
            position: 'bottom',
            align: 'start', 
        }
    };
    labels;
    data;
    firstLineData: ILineChartData;
    secondLineData: ILineChartData;
    lineDataConfig = {
        type: 'line',
        fill: false,
    };
    pieChartColors = [{
        backgroundColor: ['red', 'cyan', 'grey', 'blue']
    }];

    constructor(
        private authService: AuthService,
        private cryptoService: CryptoService,
        private userSerice : UserService,
        private http: BaseHttpService) { }

    ngOnInit(): void {
        this.getPreference()
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue && this.type === 0) {
            this.labels = this.chartApiData.chartLabels?.filter(el => el.axis === 'x')[0].labels.map(x => x.replace('-', ''));
            this.data = this.chartApiData.chartData[0].data?.map((el, index) => {
                const _key = Object.keys(el)[0];
                return {
                    label: pascalCase(_key) + ' FE',
                    data: el[_key],
                    backgroundColor: _key === 'Peers' ? colorsSettings.peersFE : colorsSettings.amazonFF,
                    hoverBackgroundColor: _key === 'Peers' ? colorsSettings.peersFE : colorsSettings.amazonFF,
                }
            });
        }
        if (changes?.idleTimeData?.currentValue && this.type === 1) { 
            this.labels = this.idleTimeData.chartLabels?.filter(el => el.axis === 'x')[0].labels.map(x => x.replace('-', ''));
            const lineDataList = this.idleTimeData.chartData[0].data?.map((el, index) => { 
                const _key = Object.keys(el)[0];
                let colorConfig = {};
                if (index === 0) {
                    colorConfig = {
                        backgroundColor: colorsSettings.amazonFF,
                        borderColor: colorsSettings.amazonFF,
                        pointBackgroundColor: colorsSettings.amazonFF,
                        hoverBackgroundColor: colorsSettings.amazonFF
                    };
                } else {
                    colorConfig = {
                        backgroundColor: colorsSettings.peersFE,
                        borderColor: colorsSettings.peersFE,
                        pointBackgroundColor: colorsSettings.peersFE,
                        hoverBackgroundColor: colorsSettings.peersFE
                    };
                }
                return {
                    label: _key + ' Idle',
                    data: el[_key],
                    backgroundColor: _key === 'Peers' ? colorsSettings.peersFE : colorsSettings.amazonFF,
                    hoverBackgroundColor: _key === 'Peers' ? colorsSettings.peersFE : colorsSettings.amazonFF,
                    ...this.lineDataConfig,
                    ...colorConfig
                };
            });
            this.data = lineDataList;
        }
    }

    getPreference(): string {
        this.http.get(`users/${this.authService.getUserInfo().userId}/preference`, {observe: 'response'}).subscribe(response => {
            localStorage.setItem('user-preferences', this.cryptoService.encryptData(response.body.data));
            let preference = response.body.data;
            this.graphOptions = {
                responsive: true,
                maintainAspectRatio: false,
                scales: {
                    yAxes: [{
                        ticks: {
                            reverse: false,
                            min: 0,
                            suggestedMax: 10,
                            stepSize: 10,
                        },
                        display: true,
                        gridLines: {display: false,color: 'transparent'},
                        scaleLabel: {
                            display: true,
                            labelString: this.type === 0 ? 'FE' :  'Idle Time'
                        }
                    }],
                    xAxes: [{
                        scaleLabel: {
                            display: true,
                            labelString: 'Month'
                        }
                    }]
                },
                legend: {
                    display: true,
                    position: 'bottom',
                    align: 'start', 
                }
            };
            return preference?.uom?.distance?.text || ''
            // i18n 
        });
        return ''
    }
}
