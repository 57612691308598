import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BaseHttpService } from '@core/services/base-http.service';
import { CryptoService } from '@core/services/crypto.service';
import * as uuid from 'uuid';
import { Router } from '@angular/router';
import { authSettings } from '@config/oauth';
import { ToastService } from '@core/services/toast.service';
import * as _ from 'underscore';
import { environment } from '@environments/environment';

@Injectable({
    providedIn: 'root',
})
export class LoginService {
    id_token: string;
    userAgentValue;
    userAccount;
    mySessionId;
    loginData = {};
    ipAddress: any;

    icons = {
        Dashboard: 'speed',
        MyFleet: 'commute',
        'Case Management': 'chat_bubble_outline',
        'User Management': 'supervisor_account',
        'Depot Management': 'directions_bus',
        //'Remote Diagnostics': 'calendar_today',
        Telematics: 'memory',
    };
    constructor(
        private http: HttpClient,
        private route: ActivatedRoute,
        private httpB: BaseHttpService,
        private router: Router,
        private cryptoService: CryptoService,
        private toastService: ToastService
    ) { }

    createLoginPayload(routeUrl: any, source?: any) {

        return new Promise((resolve, reject) => {
            const id_tokenReceived = (routeUrl) ? routeUrl.fragment : null;
            // To extract only the token value
            if (!(id_tokenReceived === null)) {
                let urlToken = id_tokenReceived.includes('&client_info')
                    ? id_tokenReceived.split('&client_info')[0]
                    : id_tokenReceived;

                this.id_token = urlToken.split('id_token=')[1];
            }
            this.userAgentValue = window.navigator.userAgent;
            this.mySessionId = uuid.v4();
            // Code to get IP address
            this.http
                .get('https://api.ipify.org/?format=json', { headers: null })
                .subscribe((res: any) => {
                    this.ipAddress = res.ip;
                    Object.assign(this.loginData, (!source) ? {
                        idToken: this.id_token,
                        sessionId: this.mySessionId,
                        userAgent: this.userAgentValue,
                        devicePlatformCode: 'WEB',
                        deviceId: '',
                        countryId:'',
                    } : {
                        
                            aliasUser: source.email_address,
                            sessionId: this.mySessionId,
                            userAgent: this.userAgentValue,
                            devicePlatformCode: 'WEB',
                            deviceId: '',
                            countryId:'',
                            fleetCode:source.fleet_code,
                            idToken:localStorage.getItem( 'loginIdToken'),
                        });
                    // Finding whether IPV4 or IPV6
                    if (this.ipAddress.length < 15) {
                        Object.assign(this.loginData, {
                            ip: {
                                ipv4: this.ipAddress,
                                ipv6: '',
                            },
                        });
                    } else {
                        Object.assign(this.loginData, {
                            ip: {
                                ipv4: '',
                                ipv6: this.ipAddress,
                            },
                        });
                    }
                    // Passing the object to users/login api
                    this.restoreToken();
                    resolve(this.loginData);
                }, (err) => reject(err));
        });
    }

    login(payload, apiUrl?: string) {
        return new Promise((resolve, reject) => {
            this.httpB.post((apiUrl) ? `${apiUrl}subscription-key=${environment.subscriptionKey}` : `users/login?subscription-key=${environment.subscriptionKey}`, payload, { observe: 'response' }).subscribe((loginResponse) => {
                if (loginResponse.status == 200) {
                    resolve(loginResponse.body);
                } 
                // else if (loginResponse.status == 401) {
                //     // reject({ status: 400 });
                //     this.toastService.error("Unauthorized user!")
                // }
            }, (loginError) => {
                if (loginError.status == 400) {
                    this.toastService.error("User does not exist!")
                    reject({ status: 400 });
                }
                else if(loginError.status == 401){
                    this.toastService.error("Unauthorized user ")
                    reject({ status: 401 });
                }
            });
        })
    }

    saveLoginData(source, loginResponse) {
        return new Promise((resolve, reject) => {
            try {
                let list = [];
                let urlRoutes = [];
                let loginData = loginResponse.data;
                localStorage.setItem( 'session',  this.cryptoService.encryptData(this.mySessionId) );
                localStorage.setItem( 'token', this.cryptoService.encryptData(loginData.accessToken) );
                if (loginData.roles && loginData.roles[0])
                    localStorage.setItem( 'userType', this.cryptoService.encryptData(loginData.roles[0]) );
                else
                    localStorage.setItem( 'userType', this.cryptoService.encryptData({}));
                const user = this.cryptoService.decryptData( localStorage.getItem('userType'));
                let groupedList = _.groupBy(user.permissions, 'urlName');
                let permissionNames = Object.keys(groupedList);
                permissionNames.forEach((name) => {
                    groupedList[name].forEach((permission) => {
                        if (permission.platform.urlRoutePlatform == 'Web') {
                            let index = list.findIndex((item) => {
                                return item.urlName == name;
                            });
                            if (index == -1) {
                                let menu = {
                                    url: `/${permission.urlRoute}`,
                                    text: permission.urlName,
                                    icon: permission.menuIcon,
                                    order: permission.priorityOrder,
                                    urlType: permission.url_type
                                };
                                list.push({ ...menu });
                                urlRoutes.push(`/${permission.urlRoute}`);
                            }
                        }
                    });
                });
                list.sort((a, b) => a.text.localeCompare(b.text));
                list = list.sort((a, b) => a.order - b.order);
                localStorage.setItem( 'permissions', this.cryptoService.encryptData({ urls: urlRoutes, permissions: list, }) );
                localStorage.setItem( 'user', this.cryptoService.encryptData(loginData.user) );
                let userAccount = {
                    accountTypeCode: '',
                    isCorporate: false,
                    isAdmin: false,
                    roleName: '',
                };
                localStorage.removeItem('userAccount');
                if (
                    loginData.user.acccount &&
                    loginData.user.acccount.accountTypeCode === 'HINO_INTERNAL'
                ) {
                    userAccount.isCorporate = true;
                    userAccount.roleName = 'Hino Corporate';
                    userAccount.accountTypeCode = 'HINO_INTERNAL';
                    loginData.roles.forEach((role) => {
                        if (role.isAdmin) userAccount.isAdmin = true;
                    });
                    localStorage.setItem( 'userAccount', this.cryptoService.encryptData(userAccount) );
                }
                if (
                    loginData.user.acccount &&
                    loginData.user.acccount.accountTypeCode === 'HINO_CUSTOMER'
                ) {
                    userAccount.isCorporate = false;
                    userAccount.roleName = 'Hino Customer';
                    userAccount.accountTypeCode = 'HINO_CUSTOMER';
                    loginData.roles.forEach((role) => {
                        if (role.isAdmin) userAccount.isAdmin = true;
                    });
                    localStorage.setItem( 'userAccount', this.cryptoService.encryptData(userAccount) );
                }
                resolve(true);
            } catch (error) {
                reject(error)
            }
        });
    }

    backupToken() {

        let sessionValue = this.cryptoService.decryptData(localStorage.getItem('session'));
        let tokenValue = this.cryptoService.decryptData(localStorage.getItem('token'));
        let userTypeValue = this.cryptoService.decryptData(localStorage.getItem('userType'));
        let permissionsValue = this.cryptoService.decryptData(localStorage.getItem('permissions'));
        let userValue = this.cryptoService.decryptData(localStorage.getItem('user'));
        let userAccountValue = this.cryptoService.decryptData(localStorage.getItem('userAccount'));

        let storedData = {
            session: sessionValue,
            token: tokenValue,
            userType: userTypeValue,
            permissions: permissionsValue,
            user: userValue,
            userAccount: userAccountValue
        }



        localStorage.removeItem('session');
        localStorage.removeItem('token');
        localStorage.removeItem('userType');
        localStorage.removeItem('permissions');
        localStorage.removeItem('user');
        localStorage.removeItem('userAccount');

        localStorage.setItem('simulation', this.cryptoService.encryptData(storedData));
        
    }

    restoreToken() {
        let simulationToken = this.cryptoService.decryptData(localStorage.getItem('simulation'));
        if(simulationToken) localStorage.setItem('token', this.cryptoService.encryptData(simulationToken.token));
    }

    restoreSimulationData() {

        if (localStorage.getItem(this.cryptoService.decryptData('isCorpAccount')) == '1'){
            localStorage.setItem('token',localStorage.getItem('corpToken'));
            //localStorage.setItem('session', localStorage.getItem('corpSession')); 
            localStorage.setItem('userType',localStorage.getItem('corpUserType'));
            localStorage.setItem('user',localStorage.getItem('corpUser'));
            localStorage.setItem('userAccount',localStorage.getItem('corpUserAccount'));
            localStorage.setItem('permissions', localStorage.getItem('corpPermissions'));
        localStorage.removeItem('simulation');
}else{
        let simulation = this.cryptoService.decryptData(localStorage.getItem('simulation'));
        localStorage.setItem('session', this.cryptoService.encryptData(simulation.session));
        localStorage.setItem('token', this.cryptoService.encryptData(simulation.token));
        localStorage.setItem('userType', this.cryptoService.encryptData(simulation.userType));
        localStorage.setItem('permissions', this.cryptoService.encryptData(simulation.permissions));
        localStorage.setItem('user', this.cryptoService.encryptData(simulation.user));
        localStorage.setItem('userAccount', this.cryptoService.encryptData(simulation.userAccount));
        localStorage.removeItem('simulation');
    }
}}
