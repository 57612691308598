<div class="container">
    <div class="error-main">
        <div class="error-heading"></div>
        <!-- <p class="error-message">You do not have permission to access this page.</p> -->
    </div>
</div>




<!-- <div class="modal-body">
    <div class="terms-conditions">
        <section class="hino-telematics-end-user-Terms">
            <div class="terms-main-heading">
                Hino Connected Vehicle Privacy Policy
            </div>
            <ol>
                <li>
                    <span class="sub-headings">Introduction</span>
                    Hino Motors Sales U.S.A., Inc.’s (“<span class="strong-content">Hino</span>”)
                    is pleased to provide you with the Hino Connected Vehicle Privacy Policy (“<span
                        class="strong-content"> Privacy
                        Policy</span>”).
                    Hino may also be referred to in this Privacy Policy as (“<span
                        class="strong-content">us</span>”),
                    (“<span class="strong-content">we</span>”) or (“<span class="strong-content">our</span>”)
                    and you, or the company you represent,
                    may be referred to as (“<span class="strong-content">you</span>”) or (“<span
                        class="strong-content">your</span>”).
                    Your Hino truck (“<span class="strong-content">Hino Truck</span>”) is equipped with hardware
                    and software which
                    allows you as the owner, lessee or operator of a Hino Truck to receive services available
                    through the Hino connected
                    vehicle offerings. Capitalized terms not defined in this Privacy Policy have the same
                    meaning given to them in the
                    Hino Connected Vehicle End User Terms available at [TERMS]
                    <p>
                        In connection with the Services we provide you, Hino and its Service Providers may
                        collect,
                        use and disclose certain information about you and your Hino Truck. This Privacy Policy
                        only
                        applies only to the Services described in this Privacy Policy and the [TERMS]. Other
                        websites and applications offered by Hino are subject to different privacy policies.
                    </p>
                </li>
                <li>
                    <span class="sub-headings">YOUR CONSENT</span>
                    YOU CONSENT TO THE COLLECTION, USE AND DISCLOSURE OF INFORMATION AS DESCRIBED IN THIS
                    PRIVACY POLICY. YOU PROVIDE YOUR CONSENT (1) BY ENROLLING IN HINO CONNECTED VEHICLE AT ANY
                    TIME (2) BY CHOOSING NOT TO OPT-OUT OF HINO CONNECTED VEHICLE AT THE TIME OF DELIVERY OF
                    YOUR HINO TRUCK OR AT ANY OTHER TIME (3) OR BY USING THE SERVICES AT ANY TIME. BY PROVIDING
                    YOUR CONSENT, YOU HAVE AGREED THAT HINO AND ITS SERVICE PROVIDERS MAY COLLECT, USE AND
                    DISCLOSE INFORMATION ABOUT YOU AND YOUR HINO TRUCK AS MORE FULLY DESCRIBED IN THIS PRIVACY
                    POLICY.
                    <p>
                        Please read this Privacy Policy carefully to understand our policies and practices
                        regarding your information and how we will treat it. You are responsible for notifying
                        all users and occupants of your Hino Truck regarding the collection, use and disclosure
                        of information as described in this Privacy Policy. This Privacy Policy may change from
                        time to time and your continued use of Hino Connected Vehicle after we make changes is
                        deemed to be acceptance of those changes, so please periodically check this Privacy
                        Policy which is posted at the following link:
                        <a
                            href="https://www.hino.com/edge-terms---privacy.html">https://www.hino.com/edge-terms---privacy.html</a>
                        for any updates.
                    </p>
                    <p>
                        Hino respects your privacy and we are committed to protecting it through our compliance
                        with our Privacy Policy. This Privacy Policy describes: </p>
                    <ol type="i">
                        <li>
                            The types of information we may collect from Service Providers, your Hino Truck
                            dealer or directly from you in connection with your access to or use of Hino
                            Connected Vehicle.
                        </li>
                        <li>
                            Our practices regarding collection, use and disclosure of that information.
                        </li>
                    </ol>

                    <p>
                        This Privacy Policy does not apply to information that we collect offline or on any
                        other
                        apps or websites owned or operated by us or that is collected by any third party other
                        than
                        those mentioned in this Privacy Policy and for the specific purposes of fulfillment of
                        the
                        services available through Hino Connected Vehicle to you. Our websites and apps and
                        these
                        third parties may have their own privacy policies, which we encourage you to read before
                        using them.
                    </p>
                </li>

                <li>
                    <span class="sub-headings">How We Use Your Information</span>The types of information we may
                    collect about you and your truck
                    are more fully described in the next section and we may use this information for such
                    purposes as:
                    <ol type="i">
                        <li>
                            Helping you to maintain your Hino Truck, including notifying you when software
                            updates are available for you Hino Truck’s electronic systems
                        </li>
                        <li>
                            Diagnosing and troubleshooting concerns regarding the operation and performance
                            of your Hino Truck
                        </li>
                        <li>
                            Providing certain remote services such as finding the location of your Hino
                            Truck or locking or unlocking its doors
                        </li>
                        <li>
                            Engaging in research, development and analysis to improve our products and
                            services and those of our affiliates and dealers and for marketing
                        </li>
                        <li>
                            Complying with court orders and other legal processes and requirements
                        </li>
                        <li>
                            Carrying out our obligations and enforcing our rights arising from any contracts
                            entered into between you and us
                        </li>
                        <li>
                            Fulfilling any other purpose for which you provide it
                        </li>
                    </ol>

                </li>
                <li>
                    <span class="sub-headings">Information We Collect and Use</span> <br>
                    <span class="sub-headings">About You</span> Information we may collect about you (“<span
                        class="strong-content">Personal Information</span>”) includes:
                    <ol type="i">
                        <li>
                            <span class="sub-headings">Contact Information.</span>
                            Information by which you may be personally identified, such as name, postal address,
                            e-mail address, telephone number and other similar information
                        </li>
                        <li>
                            <span class="sub-headings">Correspondence.</span>
                            Records and copies of your correspondence, if you contact us
                        </li>
                        <li>
                            <span class="sub-headings">Miscellaneous.</span>
                            Any other information you voluntarily provide us, our affiliates or Service
                            Providers
                        </li>
                    </ol>
                    <span class="sub-headings">About your Hino Truck.</span> Information we may collect about
                    your Hino Truck (“<span class="strong-content">Vehicle Information</span>”) includes:
                    <ol type="i">
                        <li>
                            <span class="sub-headings">VIN.</span>
                            The vehicle identification number which identifies things like the model, year and
                            engine type
                        </li>
                        <li>
                            <span class="sub-headings">Driving Data.</span>
                            Data about your Hino Truck’s operation and performance such as odometer reading,
                            engine speed, vehicle speed, engine coolant temperature, engine operating time,
                            engine idle time, distance traveled, fuel used, fuel economy, hard braking count,
                            sharp acceleration count, excess speed duration, throttle position, accessory/PTO
                            fuel usage, diagnostic trouble codes and diagnostic information and other similar
                            information
                        </li>
                        <li>
                            <span class="sub-headings">Location Data.</span>
                            The location, speed and the direction of travel of your Hino Truck
                        </li>
                    </ol>
                    <p>
                        Personal Information and Vehicle Information may collectively be referred to as
                        (“<span class="strong-content">Information</span>”). Hino may use automatic means
                        (including, for example, cookies and web
                        beacons) to collect Information about you or your Hino Truck. You also may be required
                        to provide Information as a condition to downloading, installing, or using the Mobile
                        Application.
                    </p>
                    <p>
                        In the event your license to use the Services expires, is canceled or terminated, we
                        will continue to collect, use and disclose your Vehicle Information as described in this
                        Privacy Policy until you withdraw your consent in the manner provided in this paragraph.
                        <span class="strong-content">If you do not want us to collect, use and disclose your
                            Vehicle Information, you may
                            withdraw your consent at any time by notifying us by sending an email to</span>
                        <a href=" ultimatesupport@hino.com">ultimatesupport@hino.com</a>
                        . Note, however, that withdrawing your consent to our
                        collection, use and disclosure of Vehicle Information will significantly limit the
                        services we can provide you.
                    </p>
                </li>
                <li>
                    <span class="sub-headings">Disclosure of Your Information.</span> We may disclose aggregated
                    Information and Information that does not identify any individual, without restriction.In
                    addition, we may disclose Information that we collect:
                    <ol type="i">
                        <li>
                            To our subsidiaries and affiliates
                        </li>
                        <li>
                            To contractors, service providers, dealers and other third parties we use to support
                            Hino Connected Vehicle
                        </li>
                        <li>
                            To third party telematics providers from whom you receive services
                        </li>
                        <li>
                            To a buyer or other successor in the event of a merger, divestiture, restructuring,
                            reorganization, dissolution or other sale or transfer of some or all of our assets,
                            whether as a going concern or as part of bankruptcy, liquidation or similar
                            proceeding, in which Information held by us is among the assets transferred
                        </li>
                        <li>
                            To comply with any court order, law or legal process, including to respond to any
                            government or regulatory request
                        </li>
                        <li>
                            To enforce our rights arising from any contracts entered into between you and us
                        </li>
                        <li>
                            To fulfill the purpose for which you provide it
                        </li>
                        <li>
                            If we believe disclosure is necessary or appropriate to protect the rights,
                            property, or safety of us, our customers or others
                        </li>
                    </ol>

                </li>
                <li>
                    <span class="sub-headings">Your California Privacy Rights.</span>
                    California Civil Code Section § 1798.83 permits users of our services that are California
                    residents to request certain information regarding our disclosure of Personal Information to
                    third parties for their direct marketing purposes. To make such a request, please send an
                    e-mail to privacy@hino.com; call us at (866) 774-2773; or write us at: CA Privacy Rights
                    Request, c/o Hino Motors Sales U.S.A., Inc., 45501 Twelve Mile Road, Novi, MI 48377.
                </li>
                <li>
                    <span class="sub-headings">Changes to Our Privacy Policy.</span> We may update this Privacy
                    Policy from time to
                    time. If we make material changes to how we treat your Information, we will post the new
                    privacy policy at the following link: <a
                        href="https://www.hino.com/edge-terms---privacy.html">https://www.hino.com/edge-terms---privacy.html</a>
                    <p>
                        The date this Privacy Policy was last revised is indicated at the top of the page. You
                        are responsible for periodically visiting this Privacy Policy to check for any changes.
                    </p>
                </li>
                <li>
                    <span class="sub-headings">Contact Information.</span> To ask questions or comment about
                    this Privacy Policy and our privacy practices, contact us:
                    <p>
                        By Email:<br>
                        privacy@hino.com
                    </p>
                    <p>
                        By Phone:<br>
                        (866) 774-3277
                    </p>
                    <p>
                        By Postal Mail:<br>
                        Privacy Policy<br>
                        c/o Hino Motors Sales U.S.A., Inc.<br>
                        45501 Twelve Mile Road<br>
                        Novi, MI 48377
                    </p>
                </li>
            </ol>
        </section>
    </div>
</div> -->