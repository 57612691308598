import { Component, OnInit, SimpleChanges, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { BaseHttpService } from '@core/services/base-http.service';
import { CryptoService } from '@core/services/crypto.service';
import * as uuid from 'uuid';
import { Router } from '@angular/router';
import { authSettings } from '@config/oauth';
import { User } from '@modules/user-management/models/user';
import { ToastService } from '@core/services/toast.service';
import * as _ from 'underscore';
import { environment } from '@environments/environment';
@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnChanges {
    modalRefVariable;
    @ViewChild('loaderModal') loaderModalId: ElementRef;
    @ViewChild('termsAndConditionsModal') termsAndConditionsModal: ElementRef;
    id_token: string;
    userAgentValue;
    isAgreed:Boolean = false;
    isNext:Boolean = false;
    mySessionId;
    loginData = {};
    ipAddress: any;
     user: any;
    isLoading = true;
    isUserHasAccess = false;
    formGroup: FormGroup;
    icons = {
        'Dashboard': 'speed',
        'MyFleet': 'commute',
        'Case Management': 'chat_bubble_outline',
        'User Management': 'supervisor_account',
        'Depot Management': 'directions_bus',
        // 'Remote Diagnostics': 'calendar_today',
        'Telematics': 'memory'
    };
    isAccessDeniedPage = false;
    loaderMessage = "Please wait..."
    constructor(
        // private modalRef: NgbModalRef;
        private modalService: NgbModal,
        private formBuilder: FormBuilder,
        private http: HttpClient,
        private route: ActivatedRoute,
        private httpB: BaseHttpService,
        private router: Router,
        private cryptoService: CryptoService,
        private toastService: ToastService
    ) {
        this.formGroup = this.formBuilder.group({
            email: ['', Validators.required],
            password: ['', Validators.required],
        });
    }

    ngOnInit(): void {
        const id_tokenReceived = this.route.snapshot.fragment;
        // To extract only the token value
        if (!(id_tokenReceived === null)) {
            let urlToken = (id_tokenReceived.includes("&client_info")) ?
                id_tokenReceived.split('&client_info')[0]
                : id_tokenReceived;

            this.id_token = urlToken.split("id_token=")[1];
            if(id_tokenReceived && id_tokenReceived.includes("error=access_denied")) {
                if(id_tokenReceived.includes("&error_description=AADB2C90118")) {
                    this.isAccessDeniedPage = true;
                    window.location.href = authSettings['b2c_forgot_password'];
                } else if(id_tokenReceived.includes("&error_description=AADB2C90091")) {
                    this.isAccessDeniedPage = true;
                    this.router.navigate(['/customer-login']);
                }
            } else   {
                this.isAccessDeniedPage = false;
            }
        }

        if(!this.isAccessDeniedPage) {
            this.userAgentValue = window.navigator.userAgent;
            this.mySessionId = uuid.v4();
            // Code to get IP address
            this.http.get('https://api.ipify.org/?format=json', { headers: null }).subscribe((res: any) => {
                this.ipAddress = res.ip;
                Object.assign(this.loginData, {
                    idToken: this.id_token,
                    sessionId: this.mySessionId,
                    userAgent: this.userAgentValue,
                    devicePlatformCode: 'WEB',
                    deviceId: '',
                    countryId:'',
                });
                // Finding whether IPV4 or IPV6
                if (this.ipAddress.length < 15) {
                    Object.assign(this.loginData, {
                        ip: {
                            ipv4: this.ipAddress,
                            ipv6: ''
                        }
                    });
                }
                else {
                    Object.assign(this.loginData, {
                        ip: {
                            ipv4: '',
                            ipv6: this.ipAddress
                        }
                    });
                }

                // Passing the object to users/login api
                
                if (this.id_token) { 
                    // this.loaderMessage = "Please wait ......";
                    // if(!this.isAccessDeniedPage) this.open(this.loaderModalId);
                     let country_id = localStorage.getItem('countryId');
                    console.log(country_id)
                    let data:any = this.loginData;
                    this.httpB.post(
                        `users/login?country_id=${country_id}`,
                        this.loginData,
                        { observe: 'response' }
                        ).subscribe((loginResponse) => {
                            localStorage.setItem('loginIdToken', data.idToken);
                            localStorage.setItem('isCorpAccount', '1');
                            
                            let list = [];
                            let urlRoutes = [];
                            // if(!this.isAccessDeniedPage) this.open(this.loaderModalId);
                                if (loginResponse.status == 200) {
                                    // this.close(this.loaderModalId); // added by jefrry
                                    let loginData = loginResponse.body.data;
                                    
                                    localStorage.setItem('roleId', this.cryptoService.encryptData(loginData.roles[0].roleId));
                                    localStorage.setItem('session', this.cryptoService.encryptData(this.mySessionId));
                                    localStorage.setItem('token', this.cryptoService.encryptData(loginData.accessToken));
                                    localStorage.setItem('cv_country_id', loginData.user.country.countryId)

                                    document.cookie = `sessionCookie=Bearer ${loginData.accessToken};`;
                                    if (loginData.roles && loginData.roles[0])
                                        localStorage.setItem('userType', this.cryptoService.encryptData(loginData.roles[0]));
                                    else
                                        localStorage.setItem('userType', this.cryptoService.encryptData({}));
                                    const user = this.cryptoService.decryptData(
                                        localStorage.getItem('userType')
                                    );
                                    let groupedList = _.groupBy(user.permissions, 'urlName');
                                    let permissionNames = Object.keys(groupedList);
                                    permissionNames.forEach((name) => {
                                        groupedList[name].forEach((permission) => {
                                            if (permission.platform.urlRoutePlatform == 'Web') {
                                                let index = list.findIndex((item) => {
                                                    return item.urlName == name;
                                                });
                                                if (index == -1) {
                                                    let menu = {
                                                        url: `/${permission.urlRoute}`,
                                                        text: permission.urlName,
                                                        icon: permission.menuIcon,
                                                        order: permission.priorityOrder,
                                                        urlType: permission.url_type
                                                    };
                                                    list.push({ ...menu });
                                                    urlRoutes.push(`/${permission.urlRoute}`)
                                                }
                                            }
                                        });
                                    });
                                    list.sort((a, b) => a.text.localeCompare(b.text));
                                    list = list.sort((a, b) => a.order - b.order);
                                    if (list[list.length-1].url == '/hino/ticf'){
                                        var swapTicfOrder = list[list.length-1];
                                        list[list.length-1] = list[list.length-2];
                                        list[list.length-2] = swapTicfOrder;
                                    }
                                    localStorage.setItem('permissions', this.cryptoService.encryptData({ urls: urlRoutes, permissions: list }));
                                    localStorage.setItem('user', this.cryptoService.encryptData(loginData.user));
                                    let userAccount = {
                                        accountTypeCode: '',
                                        isCorporate: false,
                                        isAdmin: false,
                                        roleName: '',
                                    }
                                    if (loginData.user.acccount && loginData.user.acccount.accountTypeCode === "HINO_INTERNAL") {
                                        userAccount.isCorporate = true;
                                        userAccount.roleName = 'Hino Corporate';
                                        userAccount.accountTypeCode = "HINO_INTERNAL";
                                        loginData.roles.forEach(role => {
                                            if (role.isAdmin) userAccount.isAdmin = true;
                                        });
                                        localStorage.setItem('userAccount', this.cryptoService.encryptData(userAccount));
                                    }
                                    if (loginData.user.acccount && loginData.user.acccount.accountTypeCode === "HINO_CUSTOMER") {
                                        userAccount.isCorporate = false;
                                        userAccount.roleName = 'Hino Customer';
                                        userAccount.accountTypeCode = "HINO_CUSTOMER";
                                        loginData.roles.forEach(role => {
                                            if (role.isAdmin) userAccount.isAdmin = true;
                                        });
                                        localStorage.setItem('userAccount', this.cryptoService.encryptData(userAccount));
                                    }
            
                                    this.isUserHasAccess = true;
                                    this.isLoading = false;
                                    this.loaderMessage = "Hold tight, we are signing you in......";
                                    setTimeout(() => {
                                        if(loginData.user.acccount.accountTypeCode === "HINO_INTERNAL") {
                                            this.router.navigate(['/hino/dashboard']) 
                                        } else {
                                            if(loginData.user.istncAccepted == null || loginData.user.istncAccepted == 0) {
                                                this.open(this.termsAndConditionsModal);  
                                            } else {
                                                this.router.navigate(['/hino/dashboard']) 
                                            }
                                        }
                                    }, 2000);
                                    if(loginData.roles[0].roleId = 1 || 2){
                                        localStorage.setItem('corpToken', this.cryptoService.encryptData(loginData.accessToken));
                                        localStorage.setItem('corpRoleId', this.cryptoService.encryptData(loginData.roles[0].roleId));
                                        localStorage.setItem('corpSession', this.cryptoService.encryptData(this.mySessionId)); 
                                        localStorage.setItem('corpUserType', this.cryptoService.encryptData(loginData.roles[0]));
                                        localStorage.setItem('corpUser', this.cryptoService.encryptData(loginData.user));
                                        localStorage.setItem('corpUserAccount', this.cryptoService.encryptData(userAccount));
                                        localStorage.setItem('corpPermissions', this.cryptoService.encryptData({ urls: urlRoutes, permissions: list }));
                                    }
            
                                } else if (loginResponse.status == 400) {
                                    this.toastService.error("User does not have access to the portal");
                                    this.isAccessDeniedPage = true;
                                    this.isUserHasAccess = false;
                                    this.isLoading = false;
                                    // this.close(this.loaderModalId); 
                                }
    
                    }, (loginError) => {
                        if (loginError.status == 400) {
                            this.toastService.error("User does not have access to the portal");
                            this.isAccessDeniedPage = true;
                            this.isUserHasAccess = false;
                            this.isLoading = false;
                            // this.close(this.loaderModalId); 
                        }
                    });
                }
            });
        }


    }
    ngOnChanges(changes: SimpleChanges) {
    }
    customerLogin(authType) {
        // code to open the url
        if(authType === 'aad_auth') {
            this.router.navigate(['/corporate-login']);
        } else {

            window.open(authSettings[authType], '_self');
        }
    }
    open(loaderModal) {
        this.modalRefVariable = this.modalService.open(loaderModal, {
            ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false
        });
        this.modalRefVariable.result.then(
            (result) => {
                if (result && result.refreshList) {
                }
            },
            (reason) => {
                //  this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
            }
        );

    }
    onAgree(content){
        this.isAgreed = true;
        let updateUser = this.cryptoService.decryptData(localStorage.getItem('user'));
            updateUser.acccount = {
                accountId: updateUser?.acccount?.accountId,
            };
            updateUser.username = updateUser.emailAddress;
            updateUser.profilePhotoUrl = null;
            updateUser.istncAccepted = 1;
            this.httpB
                .patch(`users/${updateUser.userId}`, updateUser)
                .subscribe((updateResponse) => {
                    if (updateResponse.status === 'success') {
                        this.close(this.termsAndConditionsModal);
                        this.router.navigate(['/hino/dashboard']);
                        localStorage.removeItem('user');
                        localStorage.setItem('user', this.cryptoService.encryptData(updateUser));
                    }
                    else{
                        this.toastService.error('Error logging in');
                        window.location.href = authSettings['b2c_auth'];
                    }
                });
    }
    onCancel(content){
        this.isAgreed = false;
        localStorage.clear();
        window.location.href = authSettings['b2c_auth'];
        this.close(this.termsAndConditionsModal);

    }
    close(loaderModal) {
        this.modalRefVariable.close();
    }

    onBtnNav(action){
        if(action === 'next') this.isNext = true;
        else this.isNext = false;
    }

    goToLogin() {
        window.location.href = authSettings['b2c_auth'];
    }
}
