
    <section class="route-container">
        <section class="main-title">
        </section>
    
    
        <!-- active/ inactive tables -->
        <div fxLayout="column" style="margin-top: 15%;">
            <div>
                <h4 style="color:rgb(128,128,128,0.8);margin-top:30px;text-align: center;">
                    <img src="assets/images/home/hino_header_logo_left_corner.png" class="img-fluid logo-big" alt="" style=" height: 3em;">
                </h4>
            </div>
            <div class="row help-info">
                <p><label>{{ 'CONTACT NO' |translate |titlecase}}: 855-510-HINO (4466)</label></p>
            </div>
            <div class="row help-info">
                <p><label>{{ 'MAIL' |translate |titlecase}}: ultimatesupport@hino.com</label></p>
            </div>
        </div>
    </section>