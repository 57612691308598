import { Component, OnInit, ChangeDetectorRef, OnDestroy, NgZone, ViewChild, ElementRef, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationStart } from '@angular/router';
import { Location } from '@angular/common';
import { BaseHttpService } from '@core/services/base-http.service';
import { getNameInitials, activeTransferTableHandler, depotStatistics, dateFormatter, filterLabels, getCurrentMonth } from '@core/helpers/app.helper';
import { MyfleetService } from '@modules/myfleet/services/myfleet.service';
import { AppService } from '@core/services/app.service';
import { SubSink } from 'subsink';
import { AuthService } from '@core/services/auth.service';
import { ToastService } from '@core/services/toast.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatPaginator, PageEvent } from '@angular/material/paginator';
import { environment } from '@environments/environment';
import { CryptoService } from '@core/services/crypto.service'; 
@Component({
  selector: 'app-myfleet-vehicle-view',
  templateUrl: './myfleet-vehicle-view.component.html',
  styleUrls: ['./myfleet-vehicle-view.component.scss']
})
export class MyfleetVehicleViewComponent implements OnInit {
  @ViewChild('confirmationModal') confirmationModalId: ElementRef;
  utilizationChart = null;
  fuelEconomyChart = null;
  idleTimeChart = null;
  comparisonChart = null;
  routesData: any;
  copyOfRoutesData: any;
  isLoading: boolean;
  subSink = new SubSink();
  routeSelections = new Set<string>();
  openMapView = false;
  locationHistoryDetails = false;
  rightBarState = false;
  vehicles: any;
  vehicleData: any;
  basicInfo: Boolean = true;
  locationHistory: Boolean = false;
  isDepot: boolean = false;
  isHinoInternal: boolean;
  tagEditEnable = false;
  vocationEditEnable = false;
  depotEdit = true;
  tagEdit = true;
  vocationEdit = true;
  readonly separatorKeysCodes: number[] = [ENTER, COMMA];
  vocationDropdowns = [];
  currentMonth: string;
  showAssignmentHistory = false;
  assignmentHistory = false;
  locationHistoryList: any[] = [];
  selecetdVocationId = ''
  closeResult: any
  modalReference: any;


  pageEvent: PageEvent;
  pageIndex = 0;
  pageSize = 15;
  length = 0;
  start: number;
  assignmentHistoryDetails = [];
  pageData: any;
  vehicleID: any;
  blankFieldText = environment.blankFieldText;
  isDecisivCasemanagementEnabled = true;
  currentUserPreference;
  @ViewChild('locationHistoryPaginator') paginator: MatPaginator;
  @Input() source;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private http: BaseHttpService,
    private myFleetService: MyfleetService,
    private appService: AppService,
    private ngZone: NgZone,
    private changeDetectorRef: ChangeDetectorRef,
    private toast: ToastService,
    private authService: AuthService,
    private _location: Location,
    private modalService: NgbModal,
    private cryptoService: CryptoService
  ) {
    this.currentMonth = getCurrentMonth();
  }

  ngOnInit(): void {
    localStorage.setItem('previousPath','vehicle-view-component');
    this.isHinoInternal = this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate;
    this.getKPIData();
    this.getVocationDropdowns();
    // subscribe to loading events
    this.ngZone.runOutsideAngular(() => {
      this.subSink.sink = this.appService.appLoader$.subscribe(value => {
        this.isLoading = value;
        this.changeDetectorRef.detectChanges();
      });
    });
    let casemanagementInsight = localStorage.getItem('isCaseManagementEnabled');
        if(casemanagementInsight) {
            this.isDecisivCasemanagementEnabled = this.cryptoService.decryptData(casemanagementInsight).isCaseManagementEnabled;
        }
    this.route.queryParams.subscribe((queryParams: any) => {
      console.log("queryParams ", queryParams);
      this.source = queryParams?.source || '';
      this.isDepot = true;
      this.assignmentHistory = true;
      this.route.params.subscribe((params: any) => {
        this.vehicleID = params.vehicleId;
        this.http.get(`vehicles/${params.vehicleId}/depot-assignment-history?start=${this.pageIndex}&rows=${this.pageSize}`).subscribe(response => {
          this.locationHistoryList = response.data.depotAssignmentHistory;
          this.pageData = response.data?.page || {};
          this.length = this.pageData?.totalElements;
          this.pageSize = this.pageData?.size;
          this.pageIndex = this.pageData?.number - 1;
        });
      });
    });

    this.http.get(`users/${this.authService.getUserInfo().userId}/preference`, { observe: 'response' }).subscribe(response => {
      this.currentUserPreference = response.body.data;
  }, (errorResponse) => { });
  }

  getKPIData() {
    this.route.params.subscribe((params: any) => {
      //get all kpi data
      this.http.get(`vehicles/${params.vehicleId}/kpi-charts`)
        .subscribe((response) => {
          const {
            fuelEconomyChart,
            utilizationChart,
            idletimeChart,
            EnginehoursChart,
          } = response.data;
          this.utilizationChart = utilizationChart || null;
          this.fuelEconomyChart = fuelEconomyChart || null;
          this.idleTimeChart = idletimeChart || null;
          this.comparisonChart = EnginehoursChart || null;
        });
      this.vocationEditEnable = false;
      this.tagEditEnable = false;
      this.http.get(`vehicles/${params.vehicleId}`)
        .subscribe((response) => {
          if (response?.status === "success") {
            let responseData = response.data;
            this.vehicleData = response.data;
            this.vehicleData.vehicleId = responseData?.vehicle?.vehicleId;
            this.vehicleData.vin = responseData?.vehicle?.vehicleVin;
            this.vehicleData.currentLocation = responseData?.lastLocation?.geoTag?.geometry?.coordinates;
            this.vehicleData.originalTags = responseData?.tags;
            this.vehicleData.vocation = responseData?.vocation.vocationName;
            this.vehicleData.activeFaults = responseData?.activeFaults;
            this.vehicleData.lastLocation.lastReportedLocation = responseData?.lastLocation?.lastReportedLocation || ''
            let tagNames = [];
            responseData?.tags.map((el: any) => {
              tagNames.push(el?.tagName);
            });
            let tags = tagNames.join();
            this.vehicleData.tags = tags;
            let caseIDs = ``;
            this.vehicleData.cases.forEach(element => {
              caseIDs += '\nCase ID - ' + element.caseId;
            });
            this.vehicleData.caseList = caseIDs;
            
            this.http.get(`telemetry/recent?search_vin=${this.vehicleData.vin}`).subscribe((odometerDataRes) => {
              if(odometerDataRes?.status == "success") {
                  let telemetryList = odometerDataRes.data?.telemetryList;
                  let odometerValue = telemetryList[telemetryList.length-1]?.parameters.hiResVehicleDistance;
                  this.vehicleData.odometer = odometerValue;
              }
          });
          }
        });
    });
  }

  getVocationDropdowns() {
    this.http
      .get('taxonomy?taxonomy_type=VOCATION')
      .subscribe((response) => (this.vocationDropdowns = response.data.taxonomies));
  }

  goToMyFleets() {
    this.utilizationChart = null;
    this.fuelEconomyChart = null;
    this.idleTimeChart = null;
    this.comparisonChart = null;
    this.router.navigate([`/hino/my-fleet`]);
  }
  goToDepot() {
    this.utilizationChart = null;
    this.fuelEconomyChart = null;
    this.idleTimeChart = null;
    this.comparisonChart = null;

    // redirected to previous route
    this._location.back();
  }

  showMapView() {
    this.rightBarState = true;
    this.openMapView = true;
    this.routeSelections.clear();
  }

  closeMapViewFunction() {
    this.openMapView = false;
    this.locationHistoryDetails = false;
    this.routeSelections.clear();
    this.rightBarState = false;
    this.basicInfo = true;
    this.locationHistory = false;
  }
  showMapRoutes(routesData) {
    this.rightBarState = true;
    this.routesData = routesData;
    this.sortData();
    this.copyOfRoutesData = JSON.parse(JSON.stringify(routesData));
  }

  sortData() {
    return  this.routesData.data.sort((a, b) => {
      return <any>new Date(b.date) - <any>new Date(a.date);
    });
  }
  /** set route path in azure map */
  setSelection(event, routeInfo, index: number) {
    const uniqueRoute = `${routeInfo.date}${routeInfo.start}${routeInfo.end}`;
    const target = (event.target as HTMLElement).closest('.path-instance');
    if (!!(+target.getAttribute('data-selection'))) {
      target.classList.remove('active');
      target.setAttribute('data-selection', '0');
      (target.querySelector('input[type="checkbox"]') as HTMLInputElement).checked = false;
      this.myFleetService.setRoute$.next({ routeInfo, vehicleId: this.vehicleData.vehicleId, index, removeRoute: true });
      if (this.routeSelections.has(uniqueRoute)) this.routeSelections.delete(uniqueRoute);
    }
    else {
      target.classList.add('active');
      target.setAttribute('data-selection', '1');
      (target.querySelector('input[type="checkbox"]') as HTMLInputElement).checked = true;
      this.myFleetService.setRoute$.next({ routeInfo, vehicleId: this.vehicleData.vehicleId, index });
      this.routeSelections.add(uniqueRoute);
    }
  }

  /** to persist active state of selected routes on search */
  isRouteSelected = (routeInfo): boolean => this.routeSelections.has(`${routeInfo.date}${routeInfo.start}${routeInfo.end}`);

  /** search handler for azure maps */
  searchOutput(searchQuery: string) {
    if (!searchQuery) {
      this.routesData = this.copyOfRoutesData;
      return;
    } else {
      const routes = this.copyOfRoutesData.data.filter(el => {
        const _date = dateFormatter(new Date(el.date));
        if (
          el.start.toLowerCase().includes(searchQuery.toLowerCase()) ||
          el.end.toLowerCase().includes(searchQuery.toLowerCase()) ||
          _date.includes(searchQuery)
        ) return true;
      });
      this.routesData = {
        paths: this.copyOfRoutesData.paths,
        data: routes
      };
    }
    // this.changeDetectorRef.detectChanges();
  }

  editTag() {
    this.tagEdit = false;
    this.tagEditEnable = true;
    setTimeout(() => {
      (document.querySelector('#matChipInput') as HTMLInputElement)?.focus();
    });
  }

  toggleVocation(status: boolean) {
    if (status) {
      this.vocationEdit = false;
      this.vocationEditEnable = true;
      for (let ind = 0; ind < this.vocationDropdowns.length; ind++) {
        let el = this.vocationDropdowns[ind];
        if (el.taxonomyName === this.vehicleData.vocation) {
          this.selecetdVocationId = el.taxonomyId;
          break;
        }
        else this.selecetdVocationId = ''
      }
      // this.vocationDropdowns.forEach(el => {
      //   if(el.taxonomyName === this.vehicleData.vocation) {
      //     this.selecetdVocationId = el.taxonomyId;
      //     return;
      //   }
      //   else this.selecetdVocationId = ''
      // });
    } else {
      this.vocationEdit = true;
      this.vocationEditEnable = false;
    }
  }
  tagChange() {
    // const tagsPayload = this.selectedRowData.originalTags.map(el => {
    //     return {
    //         tagId: el.tagId || '',
    //         tagName: el.tagName,
    //         modifiedOn: 0,
    //         modifiedBy: 0
    //     }
    // });
    this.tagEdit = true;
    this.tagEditEnable = false;
  }
  addTag(event: MatChipInputEvent): void {
    if (event.value?.length > 150) {
      this.toast.error('Single tag can\'t contain more than 150 characters');
      return;
    } else {
      const input = event.input;
      const value = event.value;
      if ((value || '').trim()) {
        this.vehicleData.originalTags.push({ tagName: value.trim() });
        this.http.post(`vehicles/${this.vehicleData.vehicleId}/tags`, {
          tags: [
            { tagName: value.trim() }
          ]
        }).subscribe(_ => this.toast.success('Tag added'));
      }
      // reset the input value
      if (input) {
        input.value = '';
      }
      // reset tags in view
      const currentTags = this.vehicleData.originalTags
        .map(el => `${el.tagName}, `)
        .filter(Boolean);
      const tagsInputValue = currentTags?.length ? currentTags.join('').slice(0, -2) : currentTags[0];
      this.vehicleData.tags = tagsInputValue;
      // this.setVehicleData.emit({});
    }
  }

  removeTag(tag) {
    const { tagId, tagName } = tag;
    const _deleteTag = {
      tagId,
      tagName,
      modifiedOn: 0,
      modifiedBy: 0,
    }
    const deletePayload = {
      body: { tags: [_deleteTag] }
    };
    this.http.delete(`vehicles/${this.vehicleData.vehicleId}/tags`, deletePayload).subscribe(_ => this.toast.success('Tag removed'));
    this.vehicleData.originalTags = this.vehicleData.originalTags.filter(el => el.tagName !== tagName);
    // reset tags in view
    const currentTags = this.vehicleData.originalTags
      .filter(el => el.tagName !== tagName)
      .map(el => `${el.tagName}, `);
    const tagsInputValue = currentTags?.length ? currentTags.join('').slice(0, -2) : currentTags[0];
    this.vehicleData.tags = tagsInputValue;
    // this.setVehicleData.emit({});
  }


  vocationChange(event) {
    this.selecetdVocationId = event.target.value;
    const vocationName = this.vocationDropdowns.filter(el => +el.taxonomyId === +event.target.value)[0]['taxonomyName'];
    const vocationPayload = { vocationName, vocationId: +event.target.value };
    this.vehicleData.vocation = vocationName;

    this.http.post(`vehicles/${this.vehicleData.vehicleId}/vocations`, vocationPayload).subscribe(_ => {
      this.toast.success('Vehicle information updated', 'Success');
    });
    this.toggleVocation(false);
    // this.setVehicleData.emit({ vocationPayload });
  }
  navigateToCaseManagement() {
    this.router.navigate([`/hino/case-management`], { queryParams: { page: 'case', vehicleVin: this.vehicleData.vehicle.vehicleVin } });
  }
  checkIsNumber(value) {
    return !isNaN(value);
  }


  deleteVehicleDetails() {
    this.open(this.confirmationModalId, null, 'sm')
  }

  open(referenceVariable, isFilterModal?: boolean, modalSize?: string) {
    this.modalReference = this.modalService.open(referenceVariable, isFilterModal ? { size: modalSize, backdrop: 'static', keyboard: false } : { backdrop: 'static', keyboard: false});
    this.modalReference.result.then(
      (result) => {
        this.closeResult = `Closed with: ${result}`;
        if (result && result.refreshList) {
          // this.activeTelematicsList();
        }
      },
      (reason) => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  confirmationResponse(response, modalEntity) {
    modalEntity.close()
    if (response) {
      this.removeVehicle(this.vehicleData?.vehicleId, this.vehicleData?.depot?.depotId);
    }
  }

  /** remove vehicle from depot */
  removeVehicle(vehicleId: number, depotId: number) {
    this.http.delete(`depots/${depotId}/vehicles/${vehicleId}`).subscribe(_ => {
      this.toast.success('Vehicle removed from depot');
      this._location.back();
    });
  }

  // function to show success toast (content is copied)
  openToastForSuccess() {
    this.toast.success('Copied')
  }

  // pagination for depot assignment history 
  public getServerData(event?: PageEvent) {
    this.start = event.pageIndex * event.pageSize;
    this.pageSize = event.pageSize;
    this.http.get(`vehicles/${this.vehicleID}/depot-assignment-history?start=${this.start}&rows=${this.pageSize}`).subscribe(response => {
      this.locationHistoryList = response.data.depotAssignmentHistory;
      this.pageData = response.data?.page || {};
      this.length = this.pageData?.totalElements;
      this.pageSize = this.pageData?.size;
      this.pageIndex = this.pageData?.number - 1;
    });
    return event;
  }

  closeRightBar() {
    this.rightBarState = false;
  }
}
