<ng-template #accordion>
    <mat-accordion fxLayout="column" style="margin-top:8px;" >
        <mat-expansion-panel fxLayout="column" fxFlex="100">
            <mat-expansion-panel-header style="background-color:#fafafa">
                <mat-panel-title>
                    <h4>{{'UTILIZATION' |translate |titlecase }} <span style="text-transform: lowercase;">{{'FOR' |translate |titlecase }}</span> {{ currentMonth }}</h4>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="data?.length; else noData">
                <div>
                    <canvas baseChart [data]="data" [chartType]="'pie'" [colors]="pieChartColors" [labels]="labels" [options]="options"></canvas>
                </div>
            </ng-container>
            <ng-template #noData>
                <div class="no-data"><span>{{'NO DATA FOUND' |translate |titlecase }}</span></div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>

<ng-container *ngIf="canvasOnly; else accordion">
    <ng-container *ngIf="chartApiData">
        <canvas #myChart baseChart [data]="data" [chartType]="'pie'" [colors]="pieChartColors" [labels]="labels" [options]="chartOptions"  style="height:20em  !important;width:40em !important" ></canvas>
    </ng-container>
</ng-container>