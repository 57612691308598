import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2/dist/sweetalert2.js'; 
import { UserService } from '@modules/user-management/services/user.service';
import { BaseHttpService } from '@core/services/base-http.service';
import { CryptoService } from '@core/services/crypto.service';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { SecondayOwnershipService } from '@core/services/seconday-ownership.service';
import { distinctUntilChanged } from 'rxjs/operators';
import { Router, ActivatedRoute } from '@angular/router';
import { ThemePalette } from '@angular/material/core';
import { authSettings } from '@config/oauth';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
@Component({
  selector: 'app-register-vin-form',
  templateUrl: './register-vin-form.component.html',
  styleUrls: ['./register-vin-form.component.scss']
})
export class RegisterVinFormComponent implements OnInit {
  vehicleVin = '';
  stateText: String = 'STATE';
  vinError: Boolean = false;
  successResponse=[];
  isFleetManager: Boolean = true;
  isRegistrationStarted: Boolean = false;
  isSubmittedSuccess: Boolean = false;
  isFormSubmitted: Boolean = false;
  vinRegistrationData = {
    customerName: '',
    vin: this.vehicleVin,
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: '',
    address: '',
    city: '',
    state: { stateId: 0, stateName: ''},
    country: { countryId: 0, countryName: ''},
    zipcode: '',
    purchaseDate: '',
    serviceEndDate: new Date().getTime(),
    isFleetManager: true

  };
  fleetManagerData= {
    firstName: '',
    lastName: '',
    email: '',
    title: '',
  }
  vinVerficationData: any;
  customerNameEdit = false;
  firstNameEdit = false;
  lastNameEdit = false;
  addressEdit = false;
  stateEdit = false;
  countryEdit = false;
  cityEdit = false;
  emailEdit = false;
  zipcodeEdit = false;
  fleetTitleEdit = false;
  fleetFirstNameEdit = false;
  fleetLastNameEdit = false;
  fleetEmailEdit = false;
  customerForm: FormGroup;
  fleetForm: FormGroup;
  states: any = [];
  countries: any = [];
  salutations: any = [];
  fleetInfo = 'The Fleet Manager Contact will have administrative access and receive fault notification emails';
  config = {
    value: false,
    name: "",
    disabled: false,
    height: 25,
    width: 50,
    margin: 3,
    fontSize: 10,
    speed: 300,
    color: {
      checked: "#56C128",
      unchecked: "#dcdcdc"
    },
    switchColor: {
      checked: "#3366FF",
      unchecked: "crimson"
    },
    labels: {
      unchecked: "off",
      checked: "on"
    },
    checkedLabel: "",
    uncheckedLabel: "",
    fontColor: {
      checked: "#fafafa",
      unchecked: "#ffffff"
    }
  };
  color: ThemePalette = 'primary';
  modalRefVariable;
  isAgreed =  false;
  selectedLanguage = 'en';
  vinPlaceHolder = 'Please enter your VIN to get started';
  @ViewChild('termsAndConditions') termsAndConditions: ElementRef;
  constructor(
    private formBuilder: FormBuilder,
    private userService: UserService,
    private http: BaseHttpService,
    private cryptoService: CryptoService,
    private secondayOwnershipService: SecondayOwnershipService,
    private route: ActivatedRoute,
    private modalService: NgbModal,
    private translateService: TranslateService
  ) { }

  ngOnInit(): void {
    this.translateService.setDefaultLang('en');
    this.translateService.use('en');
    this.http.get('taxonomy?taxonomy_type=SALUTATION').subscribe(response => this.salutations = response.data.taxonomies);
    this.customerForm = new FormGroup(
        {
          customerName: new FormControl('', Validators.required),
          firstName: new FormControl('', Validators.required),
          lastName: new FormControl('', Validators.required),
          email: new FormControl('', [Validators.required, Validators.email]),
          address: new FormControl('', Validators.required),
          city: new FormControl('', Validators.required),
          state: new FormGroup({ stateId: new FormControl(0), stateName: new FormControl('') }, Validators.required),
          country: new FormGroup({ countryId: new FormControl(0), countryName: new FormControl('') }, Validators.required),
          zipcode: new FormControl('', Validators.required),
          phoneNumber: new FormControl(''),
          isFleetManager: new FormControl(true)

      }
    )
    this.fleetForm = this.formBuilder.group(
        {
          salutation: new FormGroup({ salutationId: new FormControl(0), salutationName: new FormControl('') }, Validators.required),
          firstName: new FormControl('', Validators.required),
          lastName: new FormControl('', Validators.required),
          email: new FormControl('', [Validators.required, Validators.email]),
          // title: new FormControl('', Validators.required ),
      }
    );


    this.customerForm.valueChanges.subscribe((formValue) => {
      let formData = this.customerForm.value;
      this.isFleetManager = formData.isFleetManager;
      if(this.customerForm.valid) this.isFormSubmitted = false;
    });
    this.fleetForm.valueChanges.subscribe((formValue) => {
      console.log("fleet form changes - value aftr", this.fleetForm.value);
    });

    this.fleetForm.get('salutation')
    .valueChanges.pipe(distinctUntilChanged())         
    .subscribe(value => {
         
          console.log("fleet form changes - value aftr", this.fleetForm.value
          );
    })

    this.route.queryParams.subscribe((params) => {
      if (params?.vin) {
          this.vehicleVin = params.vin;
      }
  });
    
  }
 
  getStates(countryId) {
    //if(countryId == 0) countryId =  1; else countryId = countryId;
    
    this.userService.getStates(countryId).then((statesRes) => {
        this.states = statesRes;
    });
    if(countryId == 1 || countryId == 2) this. stateText =  'STATE'; else this.stateText = 'PROVINCE';
  }
  blankState(){
    this.states=[];
  }
  setState(stateId) {
    let stateData = {
      stateId: parseInt(stateId),
      stateName: this.getControlNameValue(
          this.states,
          stateId,
          'stateId',
          'stateName'
      ),
  }
    this.customerForm.controls['state'].setValue(stateData);
  }

  setCountry(countryId){
    let countryData={
      countryId: parseInt(countryId),
      countryName: this.getControlNameValue(
        this.countries,
        countryId,
        'countryId',
        'countryName'
    ),
    }
    this.customerForm.controls['country'].setValue(countryData);
  }

  getControlNameValue(list, searchId, controlId, controlName) {
    let index = list.findIndex((item) => {
        return item[controlId] == searchId;
    });
    return list[index][controlName];
}

  registerVin() {
    console.log("vinError", this.vinError)
    let vinReg = "^(?=.*[0-9])(?=.*[A-z])[0-9A-z-]{17}$";
    console.log("vehicleVin", this.vehicleVin, this.vehicleVin?.match(vinReg))
    if(this.vehicleVin?.match(vinReg)) {
      this.openTermsAndConditions();
      this.userService.getCountries().then((countriesRes) => {
        this.countries = countriesRes;
        console.log("countries", this.countries)
        // this.getStates(this.vinRegistrationData?.country?.countryId || 0);
      });
      // API call to get registration data
      // this.secondayOwnershipService.verifyVin(this.vehicleVin).then((successResponse) => {
        this.isRegistrationStarted = true;
        this.vinError = false;
        this.vinRegistrationData = {
          customerName: this.successResponse['customer']?.customerName || '',
          vin: this.successResponse['vehicleVin']?.vehicleVin || '',
          firstName: this.successResponse['customer']?.customerContactFirstname || '',
          lastName: this.successResponse['customer']?.customerContactLastname || '',
          phoneNumber: this.successResponse['customer']?.phoneNo || '',
          email: this.successResponse['customer']?.emailAddress || '',
          address: this.successResponse['customer']?.streetAddress || '',
          city: this.successResponse['customer']?.city || '',
          state: this.successResponse['customer']?.state || null,
          country: this.successResponse['customer']?.country || null,
          zipcode: this.successResponse['customer']?.zipcode || '',
          purchaseDate: this.successResponse['puchanseDate'] || '',
          serviceEndDate: this.successResponse['serviceEndDate'] || '',
          isFleetManager: this.successResponse['insightFleetManager'].isInsightFleetManager ? true: (this.successResponse['insightFleetManager'].isInsightFleetManager == null) ? true: false
        };

        this.fleetManagerData = {
          title: this.successResponse['insightFleetManager'].insightContactSalutation.SalutationName,
          firstName: this.successResponse['insightFleetManager'].insightContactFirstname,
          lastName: this.successResponse['insightFleetManager'].insightContactLastname,
          email: this.successResponse['insightFleetManager'].insightEmailAddress
        };
        this.isFleetManager = this.successResponse['insightFleetManager'].isInsightFleetManager ? true: false;
        this.vinVerficationData = this.successResponse;
        this.customerForm.patchValue(this.vinRegistrationData);
        this.fleetForm.patchValue(this.fleetManagerData);
        console.log("fleet", this.fleetManagerData, this.isFleetManager)
        console.log("this.customerForm", this.customerForm);
      
        
        // }, (errorResponse) => {
        //   this.errorMessage();
        // });
    } else {
        this.isRegistrationStarted = false;
        this.vinError = true;
    }
  }

  vinChange() {
    console.log("vin change")
    this.vinError = false;
  }

  isFleetManagerSelection() {
    console.log("isFleetManagerSelection", this.isFleetManager)
    this.isFleetManager = !this.isFleetManager;
  }

  submitRegisterForm() {
    this.isFormSubmitted = true;
    let formValue = this.fleetForm.value;
    if(!this.isFleetManager) {
      let salutation = this.salutations.filter((salutation) => { 
        return salutation.taxonomyId == parseInt(formValue.salutation.salutationId) 
      });
      let updatedValue = {
        salutationId: salutation[0]?.taxonomyId || 0,
        salutationName: salutation[0]?.taxonomyName || ""
      };
     
      formValue = { ...formValue, ...{salutation : updatedValue}}
      this.fleetForm.setValue(formValue);
      console.log("this.fleetForm ", this.fleetForm.valid, this.fleetForm.value, this.fleetForm);
    }
    console.log("this.customerForm ", this.customerForm.valid, this.customerForm.value);
    console.log("this.isFleetManager", this.isFleetManager);
    if(this.isAgreed && this.customerForm.valid && ((!this.isFleetManager && this.fleetForm.valid) || this.isFleetManager)) {
      let customerFormData = this.customerForm.value;
      let fleetFormData = this.fleetForm.value;
      let registrationData = 
      {
        // "secondaryOwnerRegId": this.vinVerficationData.secondaryOwnerRegId,
        // "vehicleVin": {
        //   "vehicleVin": this.vinVerficationData.vehicleVin.vehicleVin,
        //   "vehicleId": this.vinVerficationData.vehicleVin.vehicleId
        // },
        "secondaryOwnerRegId":  '',
        "vehicleVin": {
          "vehicleVin":  this.vehicleVin ,
          "vehicleId":  ''
        },
        "customer": {
          "customerName": customerFormData.customerName,
          "customerContactFirstname": customerFormData.firstName,
          "customerContactLastname": customerFormData.lastName,
          "streetAddress": customerFormData.address,
          "phoneNo": customerFormData.phoneNumber,
          "city": customerFormData.city,
          "state": {
            "stateId": customerFormData.state.stateId,
            "stateName": customerFormData.state.stateName
          },
          "country": {
            "countryId": customerFormData.country.countryId,
            "countryName": customerFormData.country.countryName
          },
          "zipcode": customerFormData.zipcode,
          "phoneNumber": customerFormData.phoneNumber,
          "emailAddress": customerFormData.email
        },
        "insightFleetManager": {
          "isInsightFleetManager": this.isFleetManager,
          "insightContactSalutation": fleetFormData.salutation || null,
          "insightContactFirstname": fleetFormData.firstName || null,
          "insightContactLastname": fleetFormData.lastName || null,
          "insightEmailAddress": fleetFormData.email || null 
        },
        "serviceStartDate": new Date().getTime(),
        "serviceStartEnd": new Date().getTime(),
        "createdOn": new Date().getTime(),
        "isEmailSent": 0,
        "emailSentOn": new Date().getTime()
      }
      console.log("submit form ", registrationData);
      this.secondayOwnershipService.sendOwnershipDetails(registrationData).then(response => {
        console.log("success");
        this.isSubmittedSuccess = true;
        this.successMessage();
      }, error => {
        console.log("error while saving the registration data");
        this.errorMessage(error.message);
      });
    }
  }

  successMessage(){  
    let messageHeading = (this.selectedLanguage == 'en') 
                          ? 'Thank you...'
                          : "Merci...";
    let messageContent = (this.selectedLanguage == 'en') 
                          ? "Your registration form has been submitted successfully. "
                          : "Votre formulaire d'inscription a été soumis avec succès.";
    Swal.fire(messageHeading, messageContent, 'success').then(function() {
      window.location.href = authSettings['b2c_auth'];
    });
  } 
  errorMessage(msg?: any){  
    let messageHeading = (this.selectedLanguage == 'en') 
                          ? "Oops, Something wasn't right..."
                          : "Oups, quelque chose n'allait pas...";
    let messageContent = (this.selectedLanguage == 'en') 
                          ? "The VIN you entered is incorrect Please try entering again. If problem persists, please send an email to Ultimatesupport@hino.com."
                          : "Le VIN que vous avez entré est incorrect. Veuillez réessayer. Si le problème persiste, veuillez envoyer un e-mail à Ultimatesupport@hino.com.";
    
    if(!msg) {
      messageContent =  (this.selectedLanguage == 'en') 
      ? "The VIN you entered is incorrect Please try entering again. If problem persists, please send an email to Ultimatesupport@hino.com."
      : "Le VIN que vous avez entré est incorrect. Veuillez réessayer. Si le problème persiste, veuillez envoyer un e-mail à Ultimatesupport@hino.com.";
    } else {
      let frenchMessages = [];
      frenchMessages["Error In Processing the Data"] = "Erreur dans le traitement des données";
      frenchMessages["Secondary Owner already exist"] = "Le propriétaire secondaire existe déjà";
      frenchMessages["Vehicle Vin is a mandatory field"] = "Le Vin du véhicule est un champ obligatoire";
      messageContent =  (this.selectedLanguage == 'en') ? msg : (frenchMessages[msg] ? frenchMessages[msg] : messageContent);
    }
    Swal.fire(messageHeading, messageContent, 'error')  
  } 

  customerFormControls() { return this.customerForm.controls; }
  fleetFormControls() { return this.fleetForm.controls; }

  cancelRegisterForm() {
    this.isAgreed = false
    window.location.href = authSettings['b2c_auth'];
  }

  openTermsAndConditions() {
    this.open(this.termsAndConditions);
  }

  open(loaderModal) {
    this.modalRefVariable = this.modalService.open(loaderModal, { size: 'lg', 
        ariaLabelledBy: 'modal-basic-title', backdrop: 'static', keyboard: false
    });
    this.modalRefVariable.result.then(
        (result) => {
          if (result  == 'isAgreed') {
            this.isAgreed = true;
          }
        },
        (reason) => {
        }
    );
  }

  languageChange(selectedLanguage) {
    console.log("Selecetd Language", selectedLanguage);
    this.selectedLanguage = selectedLanguage;
    this.translateService.setDefaultLang(selectedLanguage || 'en');
    this.translateService.use(selectedLanguage || 'en');
    this.vinPlaceHolder = (this.selectedLanguage == 'en') 
                          ? 'Please enter your VIN to get started'
                          : 'Veuillez entrer votre VIN pour commencer';
    this.fleetInfo = (this.selectedLanguage == 'en') 
                          ? 'The Fleet Manager Contact will have administrative access and receive fault notification emails'
                          : 'Le contact du gestionnaire de flotte aura un accès administratif et recevra des e-mails de notification de panne';
  }

  onAgree(content){
    this.isAgreed = true;
    this.modalService.dismissAll();
  
  }
}
