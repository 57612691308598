import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { vehicleModels } from '@config/vehicle-modals';
import { getVehicleModelImage } from '@core/helpers/app.helper';
import { ToastService } from '@core/services/toast.service';

@Component({
    selector: 'app-vehicle-header',
    templateUrl: './vehicle-header.component.html',
    styleUrls: ['./vehicle-header.component.scss']
})
export class VehicleHeaderComponent implements OnInit {
    @Input() vinNumber;
    @Input() vehicle;
    vehicleImageUrl = 'https://afunstoragehinodev.blob.core.windows.net/static/hinob2c/assets/images/web/default_truck_img.png';
    constructor(
        private toastService: ToastService
    ) { }

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        this.vehicleImageUrl = getVehicleModelImage(this.vinNumber, this.vehicle?.vehicleModel)
        if (!this.vehicleImageUrl) this.vehicleImageUrl = vehicleModels["5PV_Standard_L6_L7"];
        // if (!this.vehicleImageUrl)
        //     this.vehicleImageUrl = 'https://afunstoragehinodev.blob.core.windows.net/static/hinob2c/assets/images/web/default_truck_img.png';
    }

    // function to show success toast (content is copied)
    openToastForSuccess() {
        this.toastService.success('Copied')
    }

}
