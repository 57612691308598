import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseHttpService } from '@core/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class GlobalSearchService {
  myFleetsearch: BehaviorSubject<any> = new BehaviorSubject(null);

  constructor() { }
}
