import { Component, OnInit } from '@angular/core';
import { authSettings } from '@config/oauth';

@Component({
  selector: 'app-access-denied',
  templateUrl: './access-denied.component.html',
  styleUrls: ['./access-denied.component.scss']
})
export class AccessDeniedComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.logout();
  }

  logout() {
    localStorage.clear();
    // window.location.href = authSettings['b2c_auth'];
}

}
