<div class="container">
  <div class="header">
    <h1 class = "mx-auto" style="width: 200px; color: #C01125; text-align: center;">HINO ULTIMATE</h1>
    <h2 class="col-sm"  style="text-align: center;">CORPORATE LOGIN</h2>
  </div>
  <div class="row">
    <div class="col-md-6" (click)="hmcLogin()" style="text-align: center;">
      <img src="../../../../assets/images/home/hino_header_logo_small_ca.png"  style="width: 400px; cursor: pointer;">
    </div>
    <div class="col-md-6" (click)="hmsLogin()" style="text-align: center;">
      <img  src="../../../../assets/images/home/Hino_US_Logo.png"   style="width: 400px; cursor: pointer;">
    </div>
  </div>
</div>