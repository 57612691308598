

import { environment } from '@environments/environment';
export const authSettings = {
    aad_auth : `https://login.microsoftonline.com/4f9b812f-2524-4992-a20e-fd64cf0c6e1f/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=${encodeURIComponent(environment.aadClientId)}&redirect_uri=${encodeURIComponent(environment.aadRedirectUrl)}&state=eyJpZCI6ImFjZGJkN2ZmLTBjYjMtNGQ4NC1iNjU4LTBhNTBiY2I0YzBlZiIsInRzIjoxNTk0MTExMDM4LCJtZXRob2QiOiJwb3B1cEludGVyYWN0aW9uIn0%3D&nonce=d1a32185-7ec5-4a5b-bf72-1525627beb7c&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.3.2&client-request-id=5898a409-b845-4f66-bffe-e973680e3eb1&response_mode=fragment`,
    aad_auth_hmc : `https://login.microsoftonline.com/0af0bf69-64b6-42b5-b46c-fc723114840f/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=${encodeURIComponent(environment.aadClientIdhmc)}&redirect_uri=${encodeURIComponent(environment.aadRedirectUrl)}&state=eyJpZCI6ImFjZGJkN2ZmLTBjYjMtNGQ4NC1iNjU4LTBhNTBiY2I0YzBlZiIsInRzIjoxNTk0MTExMDM4LCJtZXRob2QiOiJwb3B1cEludGVyYWN0aW9uIn0%3D&nonce=d1a32185-7ec5-4a5b-bf72-1525627beb7c&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.3.2&client-request-id=5898a409-b845-4f66-bffe-e973680e3eb1&response_mode=fragment`,
    aad_change_password: `https://account.activedirectory.windowsazure.com/ChangePassword.aspx?BrandContextID=O365&ruO365=`,
    b2c_auth: `https://hinob2c.b2clogin.com/hinob2c.onmicrosoft.com/oauth2/v2.0/authorize?p=${encodeURIComponent(environment.b2cSignInKey)}&client_id=${encodeURIComponent(environment.b2cClientId)}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(environment.b2cRedirectUrl)}&scope=openid&response_type=id_token&prompt=login`,
    b2c_change_password: `https://hinob2c.b2clogin.com/hinob2c.onmicrosoft.com/oauth2/v2.0/authorize?p=${encodeURIComponent(environment.b2cResetPasswordKey)}&client_id=${encodeURIComponent(environment.b2cClientId)}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(environment.changePasswordRedirectUrl)}&scope=openid&response_type=id_token&prompt=login`,
    aad_rd_auth: `https://login.microsoftonline.com/4f9b812f-2524-4992-a20e-fd64cf0c6e1f/oauth2/v2.0/authorize?response_type=id_token&scope=openid%20profile&client_id=${encodeURIComponent(environment.aadClientId)}&redirect_uri=${encodeURIComponent(environment.remoteDiagnosisRedirectUrl)}&state=eyJpZCI6ImFjZGJkN2ZmLTBjYjMtNGQ4NC1iNjU4LTBhNTBiY2I0YzBlZiIsInRzIjoxNTk0MTExMDM4LCJtZXRob2QiOiJwb3B1cEludGVyYWN0aW9uIn0%3D&nonce=d1a32185-7ec5-4a5b-bf72-1525627beb7c&client_info=1&x-client-SKU=MSAL.JS&x-client-Ver=1.3.2&client-request-id=5898a409-b845-4f66-bffe-e973680e3eb1&response_mode=fragment`,
    b2c_rd_auth: `https://hinob2c.b2clogin.com/hinob2c.onmicrosoft.com/oauth2/v2.0/authorize?p=${encodeURIComponent(environment.b2cSignInKey)}&client_id=${encodeURIComponent(environment.b2cClientId)}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(environment.remoteDiagnosisRedirectUrl)}&scope=openid&response_type=id_token&prompt=login`,
    b2c_forgot_password: `https://hinob2c.b2clogin.com/hinob2c.onmicrosoft.com/oauth2/v2.0/authorize?p=B2C_1_RESET_PWD&client_id=${encodeURIComponent(environment.b2cClientId)}&nonce=defaultNonce&redirect_uri=${encodeURIComponent(environment.b2cRedirectUrl)}&scope=openid&response_type=id_token&prompt=login`,
    b2c_forgot_password_profile: `${environment.b2cRedirectUrl}#error=access_denied&error_description=AADB2C90118:+The+user+has+forgotten+their+password.%0D%0ACorrelation+ID:+4bc4cb9b-fd01-430e-9b29-765848577319%0D%0ATimestamp:+2022-03-29+15:31:18Z%0D%0A`,
    b2c_forgot_password_profile_uat: `${environment.b2cRedirectUrl}#error=access_denied&error_description=AADB2C90118:+The+user+has+forgotten+their+password.%0D%0ACorrelation+ID:+502ad38d-ad9f-4a55-9c63-492de6d382d3%0D%0ATimestamp:+2022-03-29+10:05:27Z%0D%0A`
};