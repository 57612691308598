

export const colorsSettings = {
    driving: '#32936f',
    idle: '#2274a5',
    pto: '#ffbf00',
    stopped: '#3e3934',
    faulty: '#bf1e2e',
    def: '#4281A4',
    fuelEconomy: '#6DA34D',
    idleTime: '#9CFFFA',
    peersFE: '#D55672',
    peersIdle: '#9CFFFA',
    amazonFF: '#6DA34D',
    amazonIdle: '#2274a5'
};