import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { authSettings } from '@config/oauth';
@Injectable({
  providedIn: 'root'
})
export class AutoLogoutService {
  private userActivity: any;
  private userInactive: Subject<any> = new Subject();

  constructor(private router: Router) {
    this.setTimeout();
    this.userInactive.subscribe(() => {
      this.logout();
    });
    this.activityWatcher();
  }


  private setTimeout() {
    this.userActivity = setTimeout(() => this.userInactive.next(undefined), 1800000); // 30mins
  }

  
  private resetTimeout() {
    clearTimeout(this.userActivity);
    this.setTimeout();
  }

  // Monitor user activity 
  private activityWatcher() {
    document.body.addEventListener('mousemove', () => this.resetTimeout());
    document.body.addEventListener('keydown', () => this.resetTimeout());
  }

  
  private logout() {
    
    localStorage.clear();
    document.cookie = "sessionCookie=; max-age=0; path=/;";
    window.location.href = authSettings['b2c_auth'];
  }
}
