export const depotTableDataConfig = {
    multiselect: true,
    showTruckIconColumn: false,
    expandRow: false,
    draggable: true,
    columnNames: [
        { title: 'VIN', addOns: { enabled: false, title: '', iconType: '' } },
        { title: 'tag', addOns: { enabled: false, title: '', iconType: '' } },
        {
            title: 'customer name',
            addOns: { enabled: false, title: '', iconType: '' },
        },
        {
            title: 'vocation',
            addOns: { enabled: false, title: '', iconType: '' },
        },
    ],
    dataFields: [
        {
            title: 'VIN',
            fieldName: 'vin',
            fieldPath: "vehicle.vehicleVin",
            dataFormat: {
                type: 'String',
                url: '',
                params: '',
                icon: { enabled: true, title: '', iconType: 'exchange' }
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'tag',
            fieldName: 'tags',
            fieldPath: 'tags',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'customer name',
            fieldName: 'customerName',
            fieldPath: 'currentOwnership.ownerName',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'vocation',
            fieldName: 'vocation',
            fieldPath: 'vocation.vocationName',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
    ],
};
