import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DashboardRoutingModule } from './dashboard-routing.module';
import { DashboardViewComponent } from './components/dashboard-view/dashboard-view.component';
import { SharedModule } from '@shared/shared.module';
import { RightPanelComponent } from './components/right-panel/right-panel.component';
import { ListViewComponent } from './components/list-view/list-view.component';
import { MapDashboardComponent } from './components/map-dashboard/map-dashboard.component';
import { FilterModalDashboardComponent } from './components/filter-modal-dashboard/filter-modal-dashboard.component';
import { NearestDealersTableComponent } from './components/nearest-dealers-table/nearest-dealers-table.component';
import { LocationHistoryTableDashboardComponent } from './components/location-history-table-dashboard/location-history-table-dashboard.component';
import { DashboardDefChartComponent } from './components/dashboard-def-chart/dashboard-def-chart.component';
import { DashboardUtilizationComponent } from './components/dashboard-utilization/dashboard-utilization.component';
import { DashboardFuelEconomyComponent } from './components/dashboard-fuel-economy/dashboard-fuel-economy.component';


@NgModule({
    declarations: [
        DashboardViewComponent,
        RightPanelComponent,
        ListViewComponent,
        MapDashboardComponent,
        FilterModalDashboardComponent,
        NearestDealersTableComponent,
        LocationHistoryTableDashboardComponent,
        DashboardDefChartComponent,
        DashboardUtilizationComponent,
        DashboardFuelEconomyComponent,
    ],
    imports: [
        CommonModule,
        SharedModule,
        DashboardRoutingModule,
    ],
    exports: [
        DashboardViewComponent,
    ]
})
export class DashboardModule { }
