import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { colorsSettings } from '@config/color-config';

@Component({
    selector: 'app-idle-time',
    templateUrl: './idle-time.component.html',
    styleUrls: ['./idle-time.component.css']
})
export class IdleTimeComponent implements OnInit {
    @Input() chartApiData;
    @Input() canvasOnly = false;
    graphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            yAxes: [{
                ticks: {
                    reverse: false,
                    min: 0,
                    suggestedMax: 10,
                    stepSize: 10,
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Idle Time'
                }
            }],
            xAxes: [{
                ticks: {
                    reverse: false,
                    stepSize: 50
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                }
            }]
        },
        legend: {
            display: true,
            position: 'top',
            align: 'start', 
        }
    };
    labels;
    data;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue) {
            const longLabels = this.chartApiData?.chartLabels?.filter(el => el.axis === 'x').map(el => el.labels).flat(Infinity);
            this.labels = longLabels?.map(x => x.slice(0, 3) + x.slice(x.length - 2));
            this.data = this.chartApiData?.chartData.map(el => ({ 
                data: el.data, 
                label: 'Idle Time',
                backgroundColor: colorsSettings.amazonIdle,
                borderColor: colorsSettings.amazonIdle,
                hoverBackgroundColor: colorsSettings.amazonIdle
            }));
        }
    }
}
