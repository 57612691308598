import { CdkScrollable } from '@angular/cdk/overlay';
import {
    Component,
    Input,
    Output,
    OnInit,
    SimpleChanges,
    EventEmitter, 
    ChangeDetectorRef
} from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
import { vehicleModels } from '@config/vehicle-modals';
import { AuthService } from '@core/services/auth.service';
import { CryptoService } from '@core/services/crypto.service';
import { environment } from '@environments/environment';

@Component({
    selector: 'app-pagination-list-control',
    templateUrl: './pagination-list-control.component.html',
    styleUrls: ['./pagination-list-control.component.scss'],
})
export class PaginationListControlComponent implements OnInit {
    // properties
    @Input() config;
    @Input() pageData;
    @Input() tableDataList;
    @Input() selectedDataList;
    @Input() isLoadingActive;
    @Input() isLoadingAsssign;
    @Output() outPaginationData = new EventEmitter();
    @Output() outputSelectedRows = new EventEmitter();
    @Output() outputSortSelection = new EventEmitter();
    @Output() outputDragStarted = new EventEmitter();
    @Output() outputDragMoved = new EventEmitter();
    @Output() outputDragAndDrop = new EventEmitter();
    @Output() removeMouseOverListener = new EventEmitter();
    @Output() outputButtonAction = new EventEmitter(); 

    isHinoInternal: boolean;
    pageEvent: PageEvent;
    datasource: any;
    pageIndex: number;
    pageSize: number;
    length: number;
    start = 0;
    itemSizeHistory = 0;
    totalPages = 0;
    displayedColumns = ['select'];
    allSelectionEnabled = false;
    selectedRows = [];
    selectionHistory = {
        selectedRows: [],
        pageData: {},
        sort: {
            field: '',
            order: 1,
        },
    };
    action : string;
    previousRow: any;
    selectedRow: any;
    // Sort settings
    sortOrder: any = 1; //ASC
    blankFieldText = environment.blankFieldText;
    tableHeight = '500px';
    constructor(private authService: AuthService,
        private inputChanges: ChangeDetectorRef,
        private cryptoService: CryptoService) { }

    ngOnInit(): void {
        this.isHinoInternal = this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate;
    }

    ngOnChanges(changes: SimpleChanges) {
        this.isHinoInternal = this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate;
        this.allSelectionEnabled = false;
        this.selectedRows = [];
        this.selectedRow = null;
        if (changes?.tableDataList?.currentValue) { 
            // extract column names
            this.config.columnNames.forEach((column) => {
                let index = this.displayedColumns.findIndex((item) => {
                    return column.title == item;
                });
                if (index == -1) {
                    if(this.isHinoInternal) {
                        this.displayedColumns.push(column.title); 
                    } else {
                        if (column.title !== 'customer Name') {
                            this.displayedColumns.push(column.title);
                        }
                    }
                }
            });
            if(!this.isHinoInternal) {
                this.tableDataList.forEach((field, index) => {
                    if (field.title == 'customer Name')
                        this.tableDataList.splice(index, 1);
                });
            }
            this.config.dataFields.forEach((field) => {
                let fieldNames = field.fieldPath.split('.');
                this.tableDataList.forEach((data, index) => {
                    this.tableDataList[index][field.fieldName] = this.getData(data, fieldNames) ;
                });
            });
            if(this.selectedDataList?.length) {
                let selectionCount = 0;
                let index;
                this.selectedRows = this.selectedDataList;
                if(this.selectedDataList?.length == 0) this.allSelectionEnabled = false;
                this.selectedRows.forEach(data => {
                    if(data != null) {index = this.getIndex(this.tableDataList, data);}
                    if(index > -1) {
                        this.tableDataList[index].isChecked = true;
                        this.tableDataList[index].isSelected = true;
                        selectionCount++;
                    }
                    if(selectionCount == this.selectedDataList?.length) this.allSelectionEnabled = true;
                });
            }

            //Set data Source- for table structure
            this.datasource = this.tableDataList;
            // console.log("Data list ---- pagiation ", this.tableDataList)
            let calculatedHeight = ((2 * this.tableDataList.length) + 2) * 16;
            this.tableHeight = (this.tableDataList?.length > 1 && calculatedHeight > 500) ? '500px' : 'auto';
        }
        
        // get page data
        if (this.pageData && this.pageData.page) {
            this.pageIndex = this.pageData['page'].number - 1;
            this.length = this.pageData['page'].totalElements;
            // let size = parseInt(this.pageData['page'].size);
            // this.pageSize = size < this.length ? size : this.pageSize;
            this.pageSize = parseInt(this.pageData['page'].size);
            this.itemSizeHistory = this.pageSize;
            this.totalPages = this.pageData['page'].totalPages;
        }

        if (changes?.selectedDataList?.currentValue) {
            // console.log("update -----selectedDataList-----", this.selectedDataList)
            this.selectedRows = this.selectedDataList;
            if(this.selectedDataList?.length == 0) this.allSelectionEnabled = false;
            else if(this.selectedDataList?.length == this.tableDataList?.length) this.allSelectionEnabled = true;
        }
        if(this.pageSize == 30) this.tableHeight = 'auto';
    }

    public pagination(event?: PageEvent) {
        let currentPageSettings = {
            start: this.getStartValue(event),
            pageSize: event.pageSize,
        };
        this.outPaginationData.emit(currentPageSettings);
        this.selectionHistory.pageData = currentPageSettings;
        this.allSelectionEnabled = false;
        return event;
    }

    getStartValue(pageValues) {
        let start = 0;
        let newStart = this.selectionHistory.pageData['start'];
        let currentStartValue = this.selectionHistory.pageData['start'];
        if (this.itemSizeHistory != pageValues.pageSize) {
            start = 0;
            this.itemSizeHistory = pageValues.pageSize;
        } else {
            if (pageValues.pageIndex == this.totalPages) {
                newStart = pageValues.pageSize * pageValues.pageIndex;
            } else if (pageValues.pageIndex == 0) {
                newStart = 0;
            } else {
                if (pageValues.previousPageIndex < pageValues.pageIndex) {
                    newStart =
                        (currentStartValue
                            ? parseInt(currentStartValue.toString())
                            : 0) + parseInt(this.pageSize.toString());
                } else if (
                    pageValues.previousPageIndex > pageValues.pageIndex
                ) {
                    let difference =
                        parseInt(currentStartValue.toString()) -
                        parseInt(this.pageSize.toString());
                    newStart = difference >= 0 ? difference : newStart;
                }
            }
            start = newStart < this.length ? newStart : start;
        }
        return start;
    }

    allSelection() {
        this.action = '';
        // console.log("allSelectionEnabled -- ", this.allSelectionEnabled)
        if (!this.allSelectionEnabled) {
            this.allSelectionEnabled = true;
            this.tableDataList.map((data: any) => {
                data.isSelected = true;
                data.isChecked = true;
            });
            this.selectedRows = [...this.tableDataList];
            this.action = 'allSelection';
        } else {
            this.allSelectionEnabled = false;
            this.tableDataList.map((data: any) => {
                data.isSelected = false
                data.isChecked = false
            });
            this.selectedRows = [];
        }
        this.outputSelectedRows.emit({
            selectedRows: this.selectedRows,
            selectedRow:  null,
            action : this.action
        });
    }

    rowSelection(row, source) {
        this.action = '';
        let flag = 0;
        if (row.isSelected) { 
            if (this.config.multiselect) {
                if(source == 'checkbox' && row.isChecked == false) {  
                    this.selectedRows.splice(this.getIndex(this.selectedRows, row), 1);
                    this.tableDataList.map((element) => {
                        if(!element?.isChecked) element.isSelected = false;
                    });
                    row.isSelected = true;
                    row.isChecked = true;
                    this.selectedRow = null;
                    this.selectedRows.push(row);
                   
                }   
                else if(source == 'checkbox' && row.isChecked == true) {
                    this.selectedRows.splice(this.getIndex(this.selectedRows, row), 1);
                    this.tableDataList.map((element) => {
                        if(!element?.isChecked) element.isSelected = false;
                    });
                    this.selectedRow = null;
                    row.isSelected = false;
                    row.isChecked = false;
                    this.action = 'deselection';
                }   
                else if(source == 'row' && row.isChecked == false)  {
                    this.selectedRows.splice(this.getIndex(this.selectedRows, row), 1);
                    this.selectedRow = null;
                    this.tableDataList.map((element) => {
                        if(!element?.isChecked) {element.isSelected = false;this.selectedRow = null;}
                        else if(element?.isChecked) {this.selectedRows.push(element);this.selectedRow = row;}
                    });
                    row.isSelected = false;
                    this.action = 'deselection';
                }
                else if(source == 'row' && row.isChecked == true)  {
                    this.selectedRows.splice(this.getIndex(this.selectedRows, row), 1);
                    this.tableDataList.map((element) => {
                        if(element?.isChecked) {element.isSelected = true;}
                    });
                    row.isSelected = false;
                    row.isChecked = false;
                    this.action = 'deselection';

                }
            } else {
                this.selectedRows = [];
                this.selectedRow = null;
                row.isSelected = false;
            }
            
        } else {
            if (this.config.multiselect) {
                if(source == 'checkbox') {
                    this.tableDataList.map((element) => {
                        if(!element?.isChecked) element.isSelected = false;
                    });
                    this.selectedRows.push(row);
                    this.selectedRow = null;
                    row.isChecked = true;
                    row.isSelected = true;
                  
                } else {
                    flag = 0;
                    this.tableDataList.map((element) => {
                        if(!element?.isChecked) {element.isSelected = false}
                        else if (element?.isChecked) {
                            flag = 1;
                            element.isSelected = true;
                           }
                    });
                    if(flag == 1){
                        row.isChecked = true;
                        row.isSelected = true;
                        this.selectedRow = null;
                        this.selectedRows.push(row);
                    }
                    else {this.selectedRows = [];
                        this.selectedRow = row;
                        row.isSelected = true;
                        row.isChecked = false;}

                }
            } 
            else {
                this.selectedRows.map((element) => {
                    if(element !=null) {
                        element.isSelected = false 
                        element.isChecked = false
                    }  
                });
                this.selectedRows = [row];
                this.selectedRow = row;
                row.isSelected = true;
            }
            this.previousRow = row;
        }
        if (this.selectedRows.length == this.tableDataList.length)
            this.allSelectionEnabled = true;
        else this.allSelectionEnabled = false;
        this.outputSelectedRows.emit({
            selectedRows: this.selectedRows,
            selectedRow: this.selectedRow,
            action: this.action
        });
    }

    getIndex(list, key) {
        let index = list.findIndex((item) => {
            return item[this.config.idField] == key[this.config.idField];
        });
        return index;
    }

    sortColumn(sortField) {
        if (this.selectionHistory.sort?.field != sortField) this.sortOrder = 1;
        else this.sortOrder = !this.sortOrder;
        let sortSettings = {
            sort_column: sortField,
            sort_order: this.sortOrder ? 'ASC' : 'DESC',
        };
        this.selectionHistory.sort = {
            field: sortField,
            order: this.sortOrder,
        };
        this.outputSortSelection.emit(sortSettings);
    }

    dragAndDrop(event) {
        this.outputDragAndDrop.emit(event);
    }

    dragStarted(event) {
        this.outputDragStarted.emit(event);
    }

    onDragMoved(event) {
        this.outputDragMoved.emit(event) 
    }

    removeMouseOverListenerEvent() {
        this.removeMouseOverListener.emit(event);
    }

    buttonAction(event, element) {
        this.outputButtonAction.emit({
            event,
            element,
        });
    }

    getData(data, fieldNames) {
        let value = data;
        fieldNames.forEach(name => {
            value = value ? value[name] : ""
        });
        return value;
    }

    // retainInitialSelection() {
    //     if(this.selectionHistory.selectedRows.length > 0) {
    //         this.datasource.data.forEach((row, idx) => {
    //             let index = this.checkIndex(this.selectionHistory.selectedRows, row);
    //             if(index > -1) {
    //                 row.isChecked = true;
    //                 this.selection.select(row);
    //                 this.selectionHistory.selectedRows[index] = row;
    //             }
    //             return row;
    //         })
    //     }
    // }
}
