import { Injectable } from '@angular/core';
import { Observable, ObservableInput, throwError } from 'rxjs';
import {
    HttpEvent,
    HttpRequest,
    HttpHandler,
    HttpInterceptor,
    HttpErrorResponse,
    HttpResponse,
    HttpHeaders
} from '@angular/common/http';
import { AppService } from '@core/services/app.service';
import { map, catchError, finalize } from 'rxjs/operators';
import { ToastService } from '@core/services/toast.service';
import { CryptoService } from '@core/services/crypto.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { environment } from '@environments/environment';
import { TokenCredential } from 'azure-maps-rest';
import { authSettings } from '@config/oauth';

@Injectable({
    providedIn: 'root',
})
export class AppHttpInterceptorService implements HttpInterceptor {
    // authentication token

    activeRequests = 0;

    constructor(
        private appService: AppService,
        private toast: ToastService,
        private cryptoService: CryptoService,
        private deviceService: DeviceDetectorService
    ) { }

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        this.appService.appLoader$.next(true);
        let token = null;
        let cv_country_id = null ;
        if (this.deviceService.isMobile() || this.deviceService.isTablet()) {
            let deviceCookie = document.cookie;
            let extractCookies = deviceCookie.split(';');
            extractCookies.forEach((key) => {
                if (key.indexOf('sessionCookie') > -1) {
                    token = key.split('=')[1];
                }
                if(key.indexOf('cv_country_id') > -1) {
                    cv_country_id = key.split('=')[1];
                }if(cv_country_id == null){
                    
                    cv_country_id = this.cryptoService.decryptData(localStorage.getItem('cv_country_id'))
                }
            })
        } else {
            if (localStorage.getItem('token')) {
                token = this.cryptoService.decryptData(localStorage.getItem('token'));
            }
            cv_country_id = this.cryptoService.decryptData(localStorage.getItem('cv_country_id'))
        }

        let authorizedRequest = req.clone({
            headers: (token)
                ? req.headers
                    .set('cv_country_id' , cv_country_id)
                    .set('authorization', (this.deviceService.isMobile() || this.deviceService.isTablet()) ? token : `Bearer ${token}`)
                    .set('Ocp-Apim-Subscription-Key', environment.subscriptionKey)
                : (req.url.indexOf('https://api.ipify.org/?format=json') > -1) ? null : req.headers.set('Ocp-Apim-Subscription-Key', environment.subscriptionKey)
        });

        this.activeRequests++;
        return next.handle(authorizedRequest).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse)
                    // this.appService.appLoader$.next(false);
                return event;
            }),
            catchError(this.handleAppError.bind(this)),
            finalize(() => {
                console.log("finalize called");
                this.stopLoader();
            })
        );
    }

    handleAppError(error): ObservableInput<any> {
        let previousToken = localStorage.getItem('previousToken')
        this.appService.appLoader$.next(false);
        if(error?.status == 401 && !previousToken) {
            this.logout();
        }
        return throwError(error);
    }

    logout() {
        localStorage.clear();
        window.location.href = authSettings['b2c_auth'];
    }

    stopLoader() {
        this.activeRequests--;
        if(this.activeRequests == 0) {
            this.appService.appLoader$.next(false);
        }
    }
}
