import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { CryptoService } from '@core/services/crypto.service';
import { authSettings } from '@config/oauth';

@Component({
  selector: 'app-auth-mismatch',
  templateUrl: './auth-mismatch.component.html',
  styleUrls: ['./auth-mismatch.component.scss']
})
export class AuthMismatchComponent implements OnInit {

  constructor( 
    private cryptoService: CryptoService
  ) { }

  ngOnInit(): void {
  }


  goToRDLogin() {
    if(this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate) {
        window.open(authSettings['aad_rd_auth'], "_blank");
    } else {
        window.open(authSettings['b2c_rd_auth'], "_self");
    }
  }
}
