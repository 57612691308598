import { IDropdownSettings } from 'ng-multiselect-dropdown';

export const MultiSelectConfig: IDropdownSettings = {
    singleSelection: false,
    idField: 'taxonomyCode',
    textField: 'taxonomyName',
    selectAllText: 'Select All',
    unSelectAllText: 'UnSelect All',
    itemsShowLimit: 2,
    allowSearchFilter: false,
};