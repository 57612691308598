<ng-template #accordion>
    <mat-accordion fxLayout="column" style="margin-top:8px;background-color:#bcbdc0">
        <mat-expansion-panel fxLayout="column" fxFlex="100">
            <mat-expansion-panel-header style="background-color:#fafafa">
                <mat-panel-title>
                    <h4>{{'COMPARISON CHART HEADER' |translate |titlecase }}</h4>
                </mat-panel-title>
                <mat-panel-description>
                </mat-panel-description>
            </mat-expansion-panel-header>
            <ng-container *ngIf="chartApiData; else noData">
                <div>
                    <canvas baseChart style="height:20em;width:20em" [datasets]="data" [chartType]="'bar'" [labels]="labels" [options]="graphOptions"></canvas>
                </div>
            </ng-container>
            <ng-template #noData>
                <div class="no-data"><span>{{'NO DATA FOUND' |translate |titlecase }}</span></div>
            </ng-template>
        </mat-expansion-panel>
    </mat-accordion>
</ng-template>
<ng-container *ngIf="canvasOnly; else accordion">
    <ng-container *ngIf="data">
        <canvas baseChart style="height:20em;width:20em" [datasets]="data" [chartType]="'bar'" [labels]="labels" [options]="graphOptions"></canvas>
    </ng-container>
</ng-container>