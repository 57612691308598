import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { getCurrentMonth } from '@core/helpers/app.helper';
import { ChartType, ChartOptions, Chart } from 'chart.js';
import { colorsSettings } from '@config/color-config';

@Component({
    selector: 'app-utilization',
    templateUrl: './utilization.component.html',
    styleUrls: ['./utilization.component.css']
})
export class UtilizationComponent implements OnInit, OnChanges {
    @Input() chartApiData;
    @Input() canvasOnly = false;
    @ViewChild('my-legend-con') legendDiv: ElementRef;
    @ViewChild('myChart') myChartEmt: ElementRef;

    labels;
    data;
    currentMonth: string;
    statusColors = {
      driving: colorsSettings.driving,
      idle: colorsSettings.idle,
      pto: colorsSettings.pto,
      stopped: colorsSettings.stopped,
      faulty: colorsSettings.faulty
    };
    pieChartColors;
    options = {
        responsive: true,
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
              }
            }
          }
    }
    chartOptions = {
        responsive: true,
        legend: { 
            display: true,
            position: 'left',
            align: 'start'
        },
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
              }
            }
          }
    }
    myChart
    constructor() {
    }

    ngOnInit(): void {
        const date = new Date(); 
        this.currentMonth = date.toLocaleString('default', { month: 'long' });
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue) {
            this.labels = this.chartApiData.chartLabels;
            this.data = this.chartApiData.chartData[0]?.data || {};
            this.pieChartColors = [{
                backgroundColor: this.labels.map(el => this.statusColors[el.toLowerCase()])
            }];
            // this.myChart = new Chart(document.getElementById('myChart') as HTMLCanvasElement, {
            //     type: 'pie',
            //     data: {
            //         labels: this.labels,
            //         datasets: [{
            //             label: '',
            //             data: this.data,
            //             backgroundColor:  this.labels.map(el => this.statusColors[el.toLowerCase()])
            //         }]
            //     },
            //     options: {
            //         responsive: true,
            //         legend:  { display: false },
            //         legendCallback: function(chart) {
            //             var legendHtml = [];
            //             legendHtml.push('<ul>');
            //             var item = chart.data.datasets[0];
            //             for (var i=0; i < item.data.length; i++) {
            //                 legendHtml.push('<li>');
            //                 legendHtml.push('<span class="chart-legend" style="background-color:' + item.backgroundColor[i] +'"></span>');
            //                 legendHtml.push('<span class="chart-legend-label-text">' + item.data[i] + ' person - '+chart.data.labels[i]+' times</span>');
            //                 legendHtml.push('</li>');
            //             }

            //             legendHtml.push('</ul>');
            //             return legendHtml.join("");
            //         },
            //         tooltips: {
            //              enabled: true,
            //              mode: 'label',
            //              callbacks: {
            //                 label: function(tooltipItem, data) {
            //                     var indice = tooltipItem.index;
            //                     return data.datasets[0].data[indice] + " person visited " + data.labels[indice] + ' times';
            //                 }
            //              }
            //          },
            //     }
            // });
            // var el = document.getElementById('my-legend-con'),
            // elChild = document.createElement('div');
            // elChild.innerHTML = this.myChart.generateLegend();

            // // Prepend it
            // el.insertBefore(elChild, el.firstChild);
        }

    }


}
