import { Pipe, PipeTransform, Inject, LOCALE_ID } from '@angular/core';
import { DatePipe } from '@angular/common';
import * as moment from "moment-timezone";
import { UserService } from '@modules/user-management/services/user.service';
import { MOMENTTIMEZONEDATA } from '@core/models/moment-timezone-data'

@Pipe({
    name: 'vehicleTimeHandler'
})
export class VehicleTimeHandlerPipe extends DatePipe implements PipeTransform {

    constructor(
        @Inject(LOCALE_ID) locale: string,
        private userService: UserService,
    ) {
        super(locale);
    }

    transform(
        value: string | Date,
        format: string = this.userService.getUserDateFormat(),
    ): string {
        // console.log(this.userService.getUserDateFormat());
        const timezone = this.userService.extractTimeZone('vehicle');
        moment.tz.add(MOMENTTIMEZONEDATA.zones);
        const timezoneOffset = moment(value).tz(timezone).format(format);
        return super.transform(value, format, timezoneOffset);
    }
}

