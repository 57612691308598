import { Component, OnInit, Input, OnChanges, SimpleChanges, ViewChild, ElementRef, AfterViewInit } from '@angular/core';

import { ChartType, ChartOptions, Chart } from 'chart.js';
import { colorsSettings } from '@config/color-config';

@Component({
    selector: 'app-fuel-economy',
    templateUrl: './fuel-economy.component.html',
    styleUrls: ['./fuel-economy.component.css']
})
export class FuelEconomyComponent implements OnInit, OnChanges, AfterViewInit {
    @Input() chartApiData;
    @Input() canvasOnly = false;
    @ViewChild('fuelChart') fuelChart: ElementRef;
    graphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                }
            }],
            yAxes: [{
                ticks: {
                    reverse: false,
                    suggestedMax: 10,
                    stepSize: 10,
                },
                legend: {
                    display: false
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Fuel Economy Count'
                }
            }]
        },
        legend: {
            display: true,
            position: 'top',
            align: 'start', 
        }
    };
    labels;
    data;
    
    constructor() {}

    ngOnInit(): void {

    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue) {
            this.labels = this.chartApiData?.chartLabels?.filter(el => el.axis === 'x')[0].labels?.map(x => x.slice(0, 3) + x.slice(x?.length - 2));
            this.data = this.chartApiData?.chartData?.map(el => ({ 
                data: el.data, 
                label: 'Fuel Economy' ,
                backgroundColor: colorsSettings.fuelEconomy,
                hoverBackgroundColor: colorsSettings.fuelEconomy
            }));
        }
    }

    ngAfterViewInit() {
    }

}
