import { vehicleModels } from '@config/vehicle-modals';
import { DomSanitizer, SafeResourceUrl, } from '@angular/platform-browser';
import { Description } from '@material-ui/icons';
// app-wide helper functions

/** returns string in pascal case format */
export const pascalCase = (input: string): string => {
    return input.split(' ').filter(Boolean).map(part => part.slice(0, 1).toUpperCase() + part.slice(1)).join(' ');
}

/** return first characters of input string */
export const getNameInitials = (input: string): string => {
    const splitString = input.split(' ');
    return splitString?.length > 1
        ? (`${splitString[0].charAt(0)}${splitString[1].charAt(0)}`).toUpperCase()
        : input.slice(0, 2).toUpperCase();
}

/** convert string or date object to epoch */
export const dateToEpoch = (date: string | Date) => new Date(date).getTime() / 1000.0;

/** get tags as string for a vehicle */
export const getTags = (tagsArray: string[]): string => {
    const tags = tagsArray.map((el: any) => el?.tagName).reduce((prev, curr) => prev += `${curr.toLowerCase()}, `, '');
    return tags?.length ? tags.slice(0, -2) : '';
}
/** get tags as string for a vehicle */
export const getVehicleTags = (vehicleInstance) => {
    vehicleInstance.originalTags = vehicleInstance?.tags || []
    vehicleInstance.tags = getTags(vehicleInstance.originalTags);
    if(!vehicleInstance.hasOwnProperty('vehicle')) vehicleInstance.vehicle = vehicleInstance;
    return vehicleInstance
}

/** response handler for active/transferred tables */
export const activeTransferTableHandler = (vehicleInstance) => {
    const {
        tags,
        cases,
        tsps,
        // drivingStatus: { transmissionStatus: status },
        vehicle: { vehicleVin, vehicleId },
        lastLocation: { lastReportedLocation, lastReportedtime, geoTag },
        vocation: { vocationName },
        currentOwnership: { ownerId: accountId, ownerName, transferredOn },
    } = vehicleInstance;
    const processedTags = getTags(tags);
    let instance = {
        accountId,
        vehicleId,
        cases,
        tsps,
        ownerName,
        depotName: vehicleInstance?.depot?.depotName || 'unassigned',
        customerName: vehicleInstance.depot?.ownership?.customerName || '',
        status: vehicleInstance?.drivingStatus?.transmissionStatus || 'Faulty',
        statusLogo: vehicleInstance?.drivingStatus?.transmissionStatus || 'Faulty',
        lastLocation: geoTag?.geometry?.coordinates,
        currentLocation: geoTag?.geometry?.coordinates,
        originalTags: tags,
        tags: processedTags,
        allTags: processedTags.split(',').map(x => x.trim()),
        lastReportedLocation,
        lastReportedtime,
        vocationName,
        vocation: vehicleInstance?.vocation,
        vehicleVin,
        vin: vehicleVin,
        poll: '',
        currentOwnership: { ownerId: accountId, ownerName, transferredOn },
        vehicleModel: vehicleInstance.vehicle.vehicleModel
    };
    if(!instance.hasOwnProperty('vehicle')) instance['vehicle'] = (vehicleInstance.hasOwnProperty('vehicle')) ? vehicleInstance.vehicle : vehicleInstance;
    return instance;
}

/** date to string */
export const dateFormatter = (date: Date): string => {
    const _date = date.getDate();
    const _month = date.getMonth() + 1;
    const _year = date.getFullYear();
    return `${_date}/${_month}/${_year}`;
}

/** response handler for depot table */
export const depotTableHandler = (vehicleInstance) => {
    const processedTags = getTags(vehicleInstance.tags);
    const {
        vehicleVin: vin,
        vehicleId,
        vocation: { vocationName },
    } = vehicleInstance;
    let props = {
        vin,
        vehicleId,
        tags: processedTags,
        vehicle: vehicleInstance
    };
    return { ...vehicleInstance, ...props};
}

/** depot trucks statistics */
export const depotStatistics = (prev, curr) => {
    let totalTrucks = 0;
    const { depotId, depotName } = curr;
    const transmissionStatus = curr.transmissionStatus.map(el => {
        totalTrucks += +el.transmissionStatusCount;
        return { transmissionStatusCode: el.transmissionStatusCode, transmissionStatusCount: el.transmissionStatusCount || 0, truckStatus: el.transmissionStatus };
    });
    prev.push({
        depotId,
        depotName,
        totalTrucks,
        transmissionStatus,
        depotInitials: depotName && getNameInitials(depotName) || '',
    });
    return prev;
}

export const depotStatisticsAssigned = (prev, curr) => {
    let totalTrucks = 0;
    const transmissionStatus = curr.map(el => {
        if(el.transmissionStatusCode != 'FAULTY'){
        totalTrucks += +el.transmissionStatusCount;
        }
        return { transmissionStatusCode: el.transmissionStatusCode, transmissionStatusCount: el.transmissionStatusCount || 0 };
    });
    let transmissionStatusList = [];
    let statusCodes = [
        { code: 'DRIVING', statusText: 'Driving' },
        { code: 'STOPPED', statusText: 'Stopped' },
        { code: 'IDLE', statusText: 'Idle' },
        { code: 'FAULTY', statusText: 'Faulty' }
    ];
    statusCodes.forEach(status => {
        let data = null;
        let statusIndex = transmissionStatus.findIndex((el) => {
            data = el;
            return el.transmissionStatusCode == status.code
        });
        if(statusIndex == -1) {
            transmissionStatusList.push({ transmissionStatusCode: status.code, transmissionStatusCount: 0, truckStatus: status.statusText })
        } else {
            transmissionStatusList.push({...data});
        }
    });
    prev.push({
        totalTrucks,
        transmissionStatus: transmissionStatusList,
    });
    return prev;
}

/** response handler for dashboard table */
export const dashboardTableHandler = (response): [] => {
    return response.data.features.map(el => {
        const {
            vehicleId,
            vehicleVin,
            currentOwner,
            depotName,
            tag,
            transmission: { status }
        } = el.properties;
        return {
            vehicleId,
            vin: vehicleVin,
            currentOwner,
            customerName: currentOwner,
            depotName,
            tags: tag,
            lastLocation: el?.geometry?.coordinates || '',
            status,
            statusLogo: status,
        };
    });
}

/** response handler for geofence table */
export const geofenceTableHandler = (instance) => {
    const {
        geofenceId,
        category,
        title,
        description,
        code,
        type,
        imageUrl,
        isActive,
        created_by_name,
        created_on,
        modified_on,
        zipcode
    } = instance;
    return {
        geofenceId,
        category,
        title,
        description: removeNonASCII(description),
        code,
        type,
        imageUrl,
        isActive,
        geometry: instance?.geometry,
        geometryAsString: JSON.stringify(instance?.geometry, null, 3),
        createdBy: created_by_name,
        createdOn: created_on,
        modifiedOn: modified_on,
        zipcode: zipcode || ""
    };
}

/** return current month as string */
export const getCurrentMonth = (): string => new Date().toLocaleDateString('en-US', { month: 'long' }).toUpperCase();

/** image upload in geofence */
export function onImageSelected(event, inputId: string, labelId: string, componentRef) {
    if (event.target.files && event.target.files.length) {
        const reader = new FileReader();
        const uploadedImage = event.target.files[0];
        reader.readAsDataURL(uploadedImage);
        const fileName = uploadedImage.name.length > 40 ? uploadedImage.name.slice(0, 40) + '...' : uploadedImage.name;
        if ((uploadedImage.size / 1024) > 80) {
            imageValidation('Image size is above 80kb.', inputId, labelId, componentRef);
            return;
        }
        if (!['image/png', 'image/jpg', 'image/jpeg'].includes(uploadedImage.type)) {
            imageValidation('Image type not supported.', inputId, labelId, componentRef);
            return;
        }
        reader.onload = () => {
            const img = new Image();
            img.src = reader.result as string;
            img.onload = () => {
                const height = img.naturalHeight;
                const width = img.naturalWidth;
                if (width > 60) {
                    imageValidation('Image width is above 60.', inputId, labelId, componentRef);
                    return;
                }
                if (height > 60) {
                    imageValidation('Image height is above 60.', inputId, labelId, componentRef);
                    return;
                }
                (document.getElementById(labelId) as HTMLLabelElement).innerText = fileName;
                if (componentRef.constructor.name === 'GeofencingViewComponent') {
                    componentRef.sidebarFormValue = { ...componentRef.sidebarFormValue, imageUrl: reader.result };
                } else {
                    componentRef.imageData = reader.result;
                    componentRef.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
                }
            };
        };
    }
}

export function imageValidation(errorMessage: string, inputId: string, labelId: string, componentRef) {
    componentRef.toast.error(errorMessage + ' Please use another image!');
    (document.getElementById(inputId) as HTMLInputElement).value = '';
    (document.getElementById(labelId) as HTMLLabelElement).innerText = 'Choose file';
    if (componentRef.constructor.name === 'CreateGeofenceComponent') {
        componentRef.imageData = null;
        componentRef.formGroup.updateValueAndValidity({ onlySelf: false, emitEvent: true });
    }
}

export const casemanagementTableHandler = (vehicleInstance) => {
    const {
        tags,
        cases,
        vehicle: { vehicleVin, vehicleId },
    } = vehicleInstance;
    let formatted = {
        vehicleId,
        cases,
        depotName: vehicleInstance?.depot?.depotName || 'unassigned',
        customerName: vehicleInstance.depot?.ownership?.customerName || '',
        status: vehicleInstance?.vehicle?.drivingStatus?.transmissionStatus || '',
        statusLogo: vehicleInstance?.vehicle?.drivingStatus?.transmissionStatus || '',
        originalTags: tags,
        vehicleVin,
        vin: vehicleVin,
        poll: '',
    };
    return { ...vehicleInstance, ...formatted };
}

/** 
 * Add filter form control names & assign a string to be displayed in applied filter section.
 * To hide a particular filter control in applied filter section, assign empty string
*/
const staticFilterLabels = {
    vin: 'vin',
    tag: 'tags',
    status: 'status',
    lastreportedtime: 'last reported time',
    lastreportedlocation: 'last reported location',
    vocation: 'vocation',
    depot_id: 'depot',
    fleetcode: 'fleet code',
    // depot_association: 'depot',
    customerName: 'customer name',
    customer_name: 'customer name',
    name: 'name',
    emailId: 'email Id',
    emailid: 'email Id',
    role: 'role',
    role_id: 'role',
    categories: 'category',
    code: 'code',
    dtc_description: 'description',
    description: 'description',
    title: 'title',
    caseID: 'case Id',
    depotName: 'depot name',
    transferreddate: 'transferred date',
    transferredby: 'transferred by',
    def_level: 'def level',
    customer_id: 'customer',
    dtc_mapping_id: 'mapping',
    severity_id: 'severity',
    component_id: 'component',
    create_case: 'create case',
    engine_model: 'engine model'
};
/** set label fo filter status */
export const filterLabels = (filters: Array<[string, string]>): Array<string> => {
    filters.forEach(el => {
    });
    return filters.map(el => !!el[1] && staticFilterLabels[el[0].toLocaleLowerCase()]).filter(Boolean);
}

export const removeNonASCII = (input: string): string => input.replace(/[\u{0080}-\u{FFFF}]/gu, ' ');

/** generate query params string from object */
export const generateQueryParams = (params: {} = {}): string => {
    if (Object.keys(params).length) {
        return Object.entries(params)
            .reduce((prev, curr) => {
                if (!!curr[1]) prev += `${curr[0]}=${curr[1]}&`;
                return prev;
            }, '?')
            .slice(0, -1);
    }
    return '';
}


/** depot trucks statistics */
export const depotStatisticsMap = (prev, curr) => {
    let totalTrucks = 0;
    const { depotId, depotName } = curr;
    const transmissionStatus = curr.transmissionStatus.map(el => {
        totalTrucks += +el.transmissionStatusCount;
        return { transmissionStatusCode: el.transmissionStatusCode, transmissionStatusCount: el.transmissionStatusCount || 0 };
    });
    if (totalTrucks > 0) {
        prev.push({
            depotId,
            depotName,
            totalTrucks,
            transmissionStatus,
            depotInitials: depotName && getNameInitials(depotName) || '',
        });
    }
    return prev;
}


export const createQueryString = (queryObject) => {
    let queryKeys = Object.keys(queryObject);
    let queryStringarray = [];
    queryKeys.forEach(key => {
        if (key == "transferredDate") {
            queryObject['transferred_start_date'] = queryObject[key]
            key = 'transferred_start_date';
        }
        if (key == "transferredBy") {
            queryObject['transfer_by'] = queryObject[key]
            key = 'transfer_by';
        }
        let filterKey = queryObject[key]?.toString() || '';
        if (filterKey != undefined && filterKey != '' && filterKey != null )
            queryStringarray.push(`${key}=${filterKey}`);
    });
    return queryStringarray.join('&');
}
export const getVehicleModelImage = (vehicleVin, vehicleModel) => {
    let vehicleModelImage = ''
    //vehicleVin = '5PVNJ8AT0M5T50036';
    console.log(vehicleVin, vehicleModel)
    if (vehicleVin?.includes("JHH")) {
        if (vehicleVin.charAt(3) == 'L' || vehicleVin.charAt(3) == 'P' || vehicleVin?.charAt(3) == 'V' || vehicleVin.charAt(3) == 'W') {
            vehicleModelImage = vehicleModels.JHH_Crew;

        } else {
            vehicleModelImage = vehicleModels.JHH_Standard;
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin?.charAt(3) == 'N') && (vehicleVin?.charAt(4) == 'E' || vehicleVin?.charAt(4) == 'J' || vehicleVin?.charAt(3) == 'F')) {
            if (vehicleModel?.includes("L6") || vehicleModel?.includes("L7")) {
                vehicleModelImage = vehicleModels["5PV_Standard_L6_L7"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin.charAt(3) == 'N') && (vehicleVin.charAt(4) == 'E' || vehicleVin.charAt(4) == 'J' || vehicleVin.charAt(4) == 'F')) {
            if (vehicleModel?.includes("238") || vehicleModel?.includes("258ALP") || vehicleModel?.includes("258LP") || vehicleModel?.includes("268") || vehicleModel?.includes("268A") || vehicleModel?.includes("338")) {
                vehicleModelImage = vehicleModels["5PV_238_front"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin.charAt(3) == 'N') && (vehicleVin.charAt(4) == 'E' || vehicleVin.charAt(4) == 'J' || vehicleVin.charAt(4) == 'F') && (vehicleVin.charAt(6) == 'C')) {
            if (vehicleModel?.includes("L6") || vehicleModel?.includes("L7")) {
                vehicleModelImage = vehicleModels["5PV_L_Extended_L6_L7"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin.charAt(3) == 'N') && (vehicleVin.charAt(4) == 'E' || vehicleVin.charAt(4) == 'J' || vehicleVin.charAt(4) == 'F')&& (vehicleVin.charAt(6) == 'G')) {
            if (vehicleModel?.includes("L6") || vehicleModel?.includes("L7")) {
                vehicleModelImage = vehicleModels["5PV_L_Crew_L6_L7"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin.charAt(3) == 'N') && (vehicleVin.charAt(4) == 'V' || vehicleVin.charAt(4) == 'H' || vehicleVin.charAt(4) == 'M')) {
            if (vehicleModel?.includes("XL7") || vehicleModel?.includes("XL8")) {
                vehicleModelImage = vehicleModels["5PV_XL_Standard_XL7_XL8"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin.charAt(3) == 'N') && (vehicleVin.charAt(4) == 'V' || vehicleVin.charAt(4) == 'H' || vehicleVin.charAt(4) == 'M') && (vehicleVin.charAt(6) == 'C')) {
            if (vehicleModel?.includes("XL7") || vehicleModel?.includes("XL8")) {
                vehicleModelImage = vehicleModels["5PV_Extended_Cab_XL7_XL8"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("5PV")) {
        if ((vehicleVin.charAt(3) == 'N') && (vehicleVin.charAt(4) == 'V' || vehicleVin.charAt(4) == 'H' || vehicleVin.charAt(4) == 'M') && (vehicleVin.charAt(6) == 'G')) {
            if (vehicleModel?.includes("XL7") || vehicleModel?.includes("XL8")) {
                vehicleModelImage = vehicleModels["5PV_XL_Crew_Cab_XL7_XL8"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    if (vehicleVin?.includes("7H4")) {
        if ((vehicleVin.charAt(3) == 'T') && (vehicleVin.charAt(4) == 'H' || vehicleVin.charAt(4) == 'M')) {
            if (vehicleModel?.includes("XL7") || vehicleModel?.includes("XL8")) {
                vehicleModelImage = vehicleModels["7H4_XL_Tractor_XL7_XL8"];
            } 
            // else {
            //     vehicleModelImage = vehicleModels["5PV_238_front"];
            // }
        } else {
        }
    }
    return vehicleModelImage;

}

/** depot trucks statistics */
export const depotStatisticsByUser = (prev, curr) => {
    console.log("Cur=", curr)
    const { depotId, depotName, vehicleCount } = curr;
    curr["transmissionStatus"] = [{ transmissionStatusCode: "", transmissionStatusCount: vehicleCount, truckStatus: ""}];
    let transmissionStatus = curr["transmissionStatus"] ;
    let totalTrucks = vehicleCount;
    prev.push({
        depotId,
        depotName,
        totalTrucks,
        transmissionStatus,
        depotInitials: depotName && getNameInitials(depotName) || '',
    });
    return prev;
}

/** response handler for geofence table */
export const tspSubscriptionsHandler = (instance) => {
    let _sanitizer: DomSanitizer;
    const { tsp : {tspImageUrl} } = instance;
    instance.tsp.tspImageUrl = isBase64(tspImageUrl) ? tspImageUrl : getImageUrl(tspImageUrl);
    return instance;
}
export const tspSubscriptionsHandlerCorp = (instance) => {
    let _sanitizer: DomSanitizer;
    instance.tspImageUrl = isBase64(instance.tspImageUrl) ? instance.tspImageUrl : getImageUrl(instance.tspImageUrl);
    return instance;
}
export const isBase64 = (base64String) => {
    let base64regex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/g;
    return base64regex.test(base64String);
  }

export const getImageUrl = (url) => {
    if(url != null) {
        let imageStoragePath = url.split("https://afunstoragehinodev.blob.core.windows.net/assets/uploads/");
        if(imageStoragePath.length > 2) {
            return `https://afunstoragehinodev.blob.core.windows.net/assets/uploads/${imageStoragePath[imageStoragePath.length-1]}`;
        } else {
            return url;
        }
    }
}

