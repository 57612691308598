import { Component, OnInit, Input, OnChanges, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { SelectionModel } from '@angular/cdk/collections';
import { ToastService } from '@core/services/toast.service';
import { BaseHttpService } from '@core/services/base-http.service';
import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { AuthService } from '@core/services/auth.service';
import { Router } from '@angular/router';
import { CryptoService } from '@core/services/crypto.service'; 
import { environment } from '@environments/environment';

export interface LocationHistoryTable {
    checked: boolean;
    location: any;
    time: any;
    vin: string;
    tag: string;
}
const Location_History_Data: LocationHistoryTable[] = [
    {
        checked: true,
        vin: '5PVNE8JN3L4S51043',
        tag: '743395',
        location: '2260 W 56th Ave',
        time: 'Jun 08 2020 17: 23: 10 '
    },
    {
        checked: true,
        vin: '5PVNE8JN3L4S51043',
        tag: '743395',
        location: '3260 W 56th Ave',
        time: 'Jun 08 2020 18: 13: 14 '
    },
    {
        checked: true,
        vin: '5PVNE8JN3L4S51043',
        tag: '743395',
        location: '5260 W 56th Ave',
        time: 'Jun 09 2020 14: 20: 04 '
    },
    {
        checked: true,
        vin: '5PVNE8JN3L4S51043',
        tag: '743395',
        location: 'PO Box 862 - Whittlesey Rd.',
        time: 'Jun 09 2020 10: 23: 10 '
    }
];

@Component({
    selector: 'app-right-side-bar-details',
    templateUrl: './right-side-bar-details.component.html',
    styleUrls: ['./right-side-bar-details.component.scss']
})
export class RightSideBarDetailsComponent implements OnInit, OnChanges {
    displayedColumns: string[] = ['location', 'time', 'select'];
    dataSource = new MatTableDataSource<LocationHistoryTable>(Location_History_Data);
    selection = new SelectionModel<LocationHistoryTable>(true, []);
    @Input() selectedRowData;
    @Input() locationHistoryDetails;
    @Input() vocationDropdowns;
    @Input() module;
    tagEditEnable = false;
    vocationEditEnable = false;
    depotEdit = true;
    base64String : string = ''
    telematicsImageUrl;
    tagEdit = true;
    vehicleData: any;
    vocationEdit = true;
    @Output() setVehicleData = new EventEmitter();
    isDepot: boolean;
    readonly separatorKeysCodes: number[] = [ENTER, COMMA];
    isHinoInternal: boolean;
    isDecisivCasemanagementEnabled = true;
    blankFieldText = environment.blankFieldText;
    locationTooltip = 'Detailed panel displays the exact location details. It may differ from the table view details as that displays the overall location details'
    constructor(
        private toast: ToastService,
        private http: BaseHttpService,
        private authService: AuthService,
        private router: Router,
        private cryptoService: CryptoService,
        private _sanitizer: DomSanitizer,
    ) { }

    ngOnInit(): void {
        this.isDepot = this.module === 'depot';
        this.isHinoInternal = this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate;
        let casemanagementInsight = localStorage.getItem('isCaseManagementEnabled');
        if(Array.isArray(this.selectedRowData?.tsps) && this.selectedRowData?.tsps.length>0){
        this.base64String = this.selectedRowData?.tsps[0]?.tspImageUrl;}
        this.telematicsImageUrl = this.isBase64(this.base64String) ? this.base64String : this._sanitizer.bypassSecurityTrustResourceUrl(this.getImageUrl(this.base64String));
        if(casemanagementInsight) {
            this.isDecisivCasemanagementEnabled = this.cryptoService.decryptData(casemanagementInsight).isCaseManagementEnabled;
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.selectedRowData?.currentValue?.vehicle?.vehicleId) {
            this.vocationEditEnable = false;
            this.tagEditEnable = false;
            let caseIDs = ``;
            this.selectedRowData.cases.forEach(element => {
                caseIDs += '\nCase ID - ' +  element.caseId;
            });
            this.selectedRowData.caseList = caseIDs;
            let currentLocation = this.selectedRowData.lastLocation?.geoTag?.geometry?.coordinates;

            for( var i = 0;i < this.selectedRowData?.tsps.length;i++){
                this.base64String = this.selectedRowData?.tsps[i]?.tspImageUrl;
               this.selectedRowData.tsps[i].tspImageUrl =  this.isBase64(this.base64String) ? this.base64String : this._sanitizer.bypassSecurityTrustResourceUrl(this.getImageUrl(this.base64String));
            }
            // this.base64String = this.selectedRowData?.tsps[0]?.tspImageUrl;
            // this.telematicsImageUrl = this.isBase64(this.base64String) ? this.base64String : this._sanitizer.bypassSecurityTrustResourceUrl(this.getImageUrl(this.base64String));
            // if(currentLocation) {
            //     this.http.get(`dashboard/maps/reverse-geocoding?lat=${currentLocation[1]}&lon=${currentLocation[0]}`).subscribe((locationDataRes) => {
            //         if(locationDataRes?.status == "success") {
            //             let freeformAddress = locationDataRes.data?.addresses[0]?.address?.freeformAddress;
            //             let country = locationDataRes.data?.addresses[0]?.address?.country;
            //             this.selectedRowData.lastLocation.lastReportedLocation = (freeformAddress)? `${freeformAddress}${country? ',' + country: ''}`: this.selectedRowData.lastLocation.lastReportedLocation;
            //             localStorage.setItem('currentLocation', this.cryptoService.encryptData(this.selectedRowData.lastLocation.lastReportedLocation));
            //         }
    
            //     });
            // }
        }
    }

    isAllSelected() {
        const numSelected = this.selection.selected.length;
        const numRows = this.dataSource.data.length;
        return numSelected === numRows;
    }

    /** Selects all rows if they are not all selected; otherwise clear selection. */
    masterToggle() {
        this.isAllSelected() ?
            this.selection.clear() :
            this.dataSource.data.forEach(row => this.selection.select(row));
    }

    editTag() {
        this.tagEdit = false;
        this.tagEditEnable = true;
        setTimeout(() => {
            (document.querySelector('#matChipInput') as HTMLInputElement).focus();
        });
    }

    toggleVocation(status: boolean) {
        if (status) {
            this.vocationEdit = false;
            this.vocationEditEnable = true;
        } else {
            this.vocationEdit = true;
            this.vocationEditEnable = false;
        }
    }
    tagChange() {
        // const tagsPayload = this.selectedRowData.originalTags.map(el => {
        //     return {
        //         tagId: el.tagId || '',
        //         tagName: el.tagName,
        //         modifiedOn: 0,
        //         modifiedBy: 0
        //     }
        // });
        this.tagEdit = true;
        this.tagEditEnable = false;
    }
    vocationChange(event) {
        const vocationName = this.vocationDropdowns.filter(el => +el.taxonomyId === +event.target.value)[0]['taxonomyName'];
        const vocationPayload = { vocationName, vocationId: +event.target.value };
        const vocationIdPayload = { vocationId: +event.target.value }
        this.selectedRowData.vocation.vocationName = vocationName;
        this.toggleVocation(false);
        this.setVehicleData.emit({ vocationPayload });
        this.http.post(`vehicles/${this.selectedRowData.vehicle.vehicleId}/vocations`,vocationIdPayload ).subscribe(
            (response) => { this.toast.success('Vocation data updated successfuly')},
            (error) => { this.toast.error('Error in updating vocation data')}
        );
       
    }

    addTag(event: MatChipInputEvent): void {
        if (event.value?.length > 150) {
            this.toast.error('Single tag can\'t contain more than 150 characters');
            return;
        } else {
            const existing = this.selectedRowData.originalTags
            .filter(el => el.tagName == event.value);
            if(existing.length == 0) {
                const input = event.input;
                const value = event.value;
                if ((value || '').trim()) {
                    this.http.post(`vehicles/${this.selectedRowData.vehicle.vehicleId}/tags`, {
                        tags: [
                            { tagName: value.trim() }
                        ]
                    }).subscribe((response) => {
                        if (input) {
                            input.value = '';
                        }
                        this.http.get(`vehicles/${this.selectedRowData.vehicle.vehicleId}/tags`).subscribe(response => {
                            const existing = this.selectedRowData.originalTags
                                .filter(el => el.tagName == event.value);
                            if (existing.length == 0) {
                                this.selectedRowData.originalTags.push({ tagName: value.trim(), tagId: response.data[response.data.length - 1].tagId });
                            }
                        });
                        // reset tags in view
                        
                        const currentTags = this.selectedRowData.originalTags
                            .map(el => `${el.tagName}, `)
                            .filter(Boolean);
                        const tagsInputValue = currentTags?.length ? currentTags.join('').slice(0, -2) : currentTags[0];
                        this.selectedRowData.tags = tagsInputValue;
                        this.setVehicleData.emit({});
                        // reset the input value
                        this.toast.success('Tag added')
                    },
                        (errorResponse) => {
                            this.toast.error(errorResponse.error.message)
                        }
                    );
                }
            }
        }
    }

    removeTag(tag) {
        const { tagId, tagName } = tag;
        const _deleteTag = {
            tagId,
            tagName,
            modifiedOn: 0,
            modifiedBy: 0,
        }
        const deletePayload = {
            body: { tags: [_deleteTag] }
        };
        this.http.delete(`vehicles/${this.selectedRowData.vehicle.vehicleId}/tags`, deletePayload).subscribe(_ => this.toast.success('Tag removed'));
        this.selectedRowData.originalTags = this.selectedRowData.originalTags.filter(el => el.tagName !== tagName);
        // reset tags in view
        const currentTags = this.selectedRowData.originalTags
            .filter(el => el.tagName !== tagName)
            .map(el => `${el.tagName}, `);
        const tagsInputValue = currentTags?.length ? currentTags.join('').slice(0, -2) : currentTags[0];
        this.selectedRowData.tags = tagsInputValue;
        this.setVehicleData.emit({});
    }

    navigateToCaseManagement() {
        this.router.navigate([`/hino/case-management`], {queryParams: { page: 'case', vehicleVin: this.selectedRowData.vehicle.vehicleVin }});
    }

    navigateToTelematicsPage(){
        this.router.navigate([`/hino/telematics`]);
    }

    isBase64(base64String) {
        let base64regex = /data:image\/([a-zA-Z]*);base64,([^\"]*)/g;
        return base64regex.test(base64String);
      }
    
      getImageUrl(url) {
        if (url != null) {
          let imageStoragePath = url.split("https://afunstoragehinodev.blob.core.windows.net/assets/uploads/");
          if (imageStoragePath.length > 2) {
            return `https://afunstoragehinodev.blob.core.windows.net/assets/uploads/${imageStoragePath[imageStoragePath.length - 1]}`;
          } else {
            return url;
          }
        }
      }
      viewInGoogleMaps(){
        const url = `https://www.google.com/maps?q=${this.selectedRowData.lastLocation.geoTag.geometry.coordinates[1]},${this.selectedRowData.lastLocation.geoTag.geometry.coordinates[0]}`
        const newTab = window.open(url,'_blank');
        newTab.focus();
      }

      openDetailPage() {
        this.router.navigate([`/hino/my-fleet/vehicle/${this.selectedRowData.vehicle?.vehicleId}/details`]);
    }
}
