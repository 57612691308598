<!-- <p>Loading...</p> -->
<section class="route-container">
    <div *ngIf="!isPrivacyChecked" class="gear-loader login-loader">
        <p>Loading, Please wait...</p>
    </div>

    <div *ngIf="isPrivacyChecked" class="noData">
        <span>Can not render in an iFrame</span>
    </div>
</section>


