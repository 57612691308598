import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class AppService {
    pageTitle$: BehaviorSubject<string> = new BehaviorSubject<string>('');
    appLoader$: Subject<boolean> = new Subject<boolean>();

    constructor() { }
}
