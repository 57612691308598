<div class="modal-body">
    <div class="terms-conditions">
        <section class="hino-telematics-end-user-Terms">
            <div class="terms-main-heading">
                Hino Connected Vehicle End User Terms
            </div>
            <ol>
                <li>
                    These Hino Connected Vehicle End User Terms (“<span class="strong-content">Terms</span>”)
                    and the Hino Connected Vehicle Privacy Policy (“<span class="strong-content">Privacy
                        Policy</span>”)
                    (together, this "<span class="strong-content">Agreement</span>") is a binding agreement
                    between you, or the company or organization you
                    represent ("you") and Hino Motors Sales U.S.A., Inc. ("<span
                        class="strong-content">Hino</span>"). This Agreement governs your use of the Hino
                    telematics services, which includes all related hardware and software (“<span
                        class="strong-content">Telematics Device</span>”) installed on
                    your Hino truck (your “<span class="strong-content">Hino Truck</span>”) as well as any
                    software and services made available through the Hino Ultimate Ownership Portal
                    (the “<span class="strong-content">Portal</span>”) and the Hino connected vehicle mobile
                    application (the “<span class="strong-content">Mobile Application</span>”). Together, the
                    Telematics Device, the Portal, the Mobile Application and the associated
                    data and information are referred to as the “<span class="strong-content">Services</span>”.
                </li>
                <li>
                    BY CLICKING THE "AGREE" BUTTON OR BY ACCESSING OR USING THE SERVICES, YOU AGREE ON YOUR
                    BEHALF, OR ON BEHALF
                    OF THE COMPANY OR ORGANIZATION YOU REPRESENT, TO BE BOUND BY THESE TERMS AND OUR <span
                        class="strong-content">PRIVACY POLICY located at <a
                            href="https://www.hino.com/">Hino.com</a> under EDGE Privacy
                        Policy</span>. YOU
                    REPRESENT AND WARRANT THAT (A) YOU HAVE READ AND UNDERSTAND THIS AGREEMENT; AND, (B) IF YOU
                    REPRESENT A
                    COMPANY OR ORGANIZATION, YOU HAVE THE POWER TO ENTER INTO THIS AGREEMENT ON THEIR BEHALF. TO
                    ACCESS OR USE
                    THE SERVICES YOU MUST AGREE TO THESE TERMS AND OUR <span class="strong-content">PRIVACY
                        POLICY located at <a href="https://www.hino.com/">Hino.com</a> under EDGE Privacy
                        Policy</span>. IF YOU DO NOT AGREE TO
                    THESE TERMS AND OUR
                    <span class="strong-content">PRIVACY POLICY located at <a
                            href="https://www.hino.com/">Hino.com</a> under EDGE Privacy
                        Policy</span>, DO NOT ACCESS OR USE THE SERVICES.
                </li>
                <li>
                    <span class="sub-headings">Hino Services; License.</span>
                    <ol type="a">
                        <li>
                            <span class="sub-headings">Description.</span> The Services Hino provides you may
                            include (i) information relating to the operation of your
                            Hino Truck, (ii) information relating to the diagnosis and repair of your Hino
                            Truck, and (iii) other
                            information related to your Hino Truck. Hino may from time to time change the
                            Services it provides you.
                            Additional descriptions of the Services are available www.hino.com (the “<span
                                class="strong-content">Website”</span>). The Services are
                            provided as “Software as a Service” through the Dashboard available at the Website
                            as well as the Mobile
                            Application. Hino may from time to time in its sole discretion develop and provide
                            updates to the Services,
                            which may include upgrades, bug fixes, patches, other error corrections, and/or new
                            features (collectively,
                            including related documentation, "<span class="strong-content">Updates</span>").
                            Updates may also modify or delete in their entirety certain
                            features and functionality. Hino has no obligation to provide any Updates or to
                            continue to provide or
                            enable any particular features or functionality. Updates will be deemed part of the
                            Services and be subject
                            to all terms and conditions of this Agreement.
                        </li>
                        <li>
                            <span class="sub-headings">License.</span>
                            <ol type="i">
                                <li>
                                    Subject to the terms and conditions of this Agreement, Hino hereby grants to
                                    you a limited, non-exclusive,
                                    non-transferable license to: (1) access and use the Services for internal
                                    purposes only (2) download and
                                    install one copy of the Mobile Application on each permitted device or
                                    computer system.
                                </li>
                                <li>
                                    Certain of the Services offered by Hino include third-party code licensed to
                                    Hino for use and redistribution
                                    under open source licenses, including those listed in the attached as <span
                                        class="highlight-content">Exhibit "A</span> and made a part of this
                                    Agreement by this reference.
                                    The OSS Software Licenses are made a part of this Agreement by this
                                    reference.Notwithstanding anything to the
                                    contrary in this Agreement, the provisions of these open source licenses may
                                    be applicable to your use of
                                    the Services. The list of open source software and licenses was prepared
                                    with reference to third-party
                                    software incorporated into the Services as of the date the list was
                                    generated. The list may be updated by
                                    Hino from time to time. ALL INFORMATION RELATING TO THESE OPEN SOURCE
                                    LICENSES IS PROVIDED “AS IS,” AND HINO
                                    AND ITS SERVICE PROVIDERS MAKE NO REPRESENTATIONS OR WARRANTIES EXPRESS OR
                                    IMPLIED, REGARDING LIST OR ITS
                                    ACCURACY OR COMPLETENESS.
                                </li>
                            </ol>
                        </li>
                    </ol>
                </li>
                <li>
                    <span class="sub-headings">Third Party Telematics Services.</span> Hino has relationships
                    with third party telematics providers (“<span class="strong-content">Telematics
                        Providers</span>”) that, for a fee, may offer services different from Hino or additional
                    to those Hino provides.If you subscribe to services offered by Telematics Providers,
                    the terms of those services will be governed by separate agreements with them. Hino is not
                    liable to you are anyone else for the services provided by Telematics Providers.
                </li>
                <li>
                    <span class="sub-headings">Fee for Hino Services.</span> The Services for the initial term
                    of the Agreement are included in the price when you purchase or lease your new Hino Truck.
                    If you subscribe to services offered by a Telematics Provider,
                    the fees and other terms of service will be established directly between you and the
                    Telematics Provider.
                </li>
                <li>
                    <span class="sub-headings">Third Party Service Providers.</span> Hino uses third party
                    service providers (“<span class="strong-content">Service Providers</span>”) and
                    its authorized network of Hino dealers (“<span class="strong-content">Hino Dealers</span>”)
                    in providing the Services to you.
                </li>
                <li>
                    <span class="sub-headings">Data Management.</span>
                    Collection, Use and Disclosure. In order to provide the Services to you, Hino, Hino Dealers,
                    and Service
                    Providers must collect, store, use and disclose data and information related to you (“<span
                        class="strong-content">Personal Information</span>”)
                    and to your Hino Truck (“<span class="strong-content">Vehicle Information</span>”). Personal
                    Information includes, but is not limited to, such
                    categories as name, postal address, email address, employment information, and other similar
                    information
                    that may identify or be linked to you. Vehicle Information includes, but is not limited to,
                    such categories
                    as vehicle identification number, vehicle location, direction of travel, distance traveled,
                    speed, braking,
                    fuel usage, engine performance, systems monitoring and other similar information generated
                    by your Hino
                    Truck. Personal Information and Vehicle Information are collectively referred to as “<span
                        class="strong-content">Information”</span>.
                </li>
                <li>
                    <span class="sub-headings">CONSENT.</span> YOU CONSENT TO THE COLLECTION, STORAGE, USE, AND
                    DISCLOSURE OF INFORMATION DESCRIBLED IN THESE
                    TERMS AND AS FURTHER DETALED IN OUR <span class="strong-content">PRIVACY POLICY located at
                        <a href="https://www.hino.com/">Hino.com</a> under EDGE Privacy
                        Policy</span>. YOUR CONSENT REMAINS EFFECTIVE UNTIL
                    WITHDRAWN IN THE
                    MANNER PROVIDED IN OUR <span class="strong-content">PRIVACY POLICY located at <a
                            href="https://www.hino.com/">Hino.com</a> under EDGE Privacy
                        Policy</span>. YOU WILL NOTIFY AND OBTAIN CONSENT FROM ANY USERS OR
                    OCCUPANTS OF
                    YOUR HINO TRUCK REGARDING THE COLLECTON, STORAGE, USE AND DISCLOSURE OF INFORMATION UNDER
                    THIS AGREEMENT.
                </li>
                <li>
                    <span class="sub-headings">Third-Party Materials.</span> The Services may display, include,
                    or make available third-party content (including
                    data, information, applications, and other products, services, and/or materials) or provide
                    links to
                    third-party websites or services ("<span class="strong-content">Third-Party
                        Materials</span>"). Hino is not responsible for Third-Party
                    Materials, including their accuracy, completeness, timeliness, quality, or any other aspect
                    thereof. Hino
                    does not assume and will not have any liability or responsibility to you or any other person
                    or entity for
                    any Third-Party Materials. Third-Party Materials and links thereto are provided solely as a
                    convenience to
                    you, and you access and use them entirely at your own risk and subject to such third
                    parties' terms and
                    conditions.
                </li>
                <li>
                    <span class="sub-headings">Restrictions on Your Use of Services.</span> You shall not: (i)
                    modify, translate, adapt, or otherwise create
                    derivative works or improvements, of the Services; (ii) reverse engineer, disassemble,
                    decompile, decode, or
                    otherwise attempt to derive or gain access to the source code of the Services or any part
                    thereof; (iii)
                    remove, delete, alter, or obscure any trademarks or any copyright, trademark, patent, or
                    other intellectual
                    property or proprietary rights notices from the Services, including any copy thereof; (iv)
                    rent, lease,
                    lend, sell, sublicense, assign, distribute, publish, transfer, or otherwise make available
                    the Services, or
                    any features or functionality of the Services, to any third party for any reason; (v) use
                    the Services for
                    personal, family or household purposes; (vi) use the Services in any manner that would
                    violate any
                    applicable law, intellectual property, or other third party right.
                </li>
                <li>
                    <span class="sub-headings">Reservation of Rights.</span> You acknowledge and agree that the
                    Services provided under license, and not sold, to
                    you. You do not acquire any ownership interest in the Services under this Agreement, or any
                    other rights
                    thereto other than to use the Services in accordance with the license granted, and subject
                    to all terms,
                    conditions, and restrictions, under this Agreement. Hino and its licensors and Service
                    Providers reserve
                    their entire right, title, and interest in and to the Services, including all copyrights,
                    trademarks, and
                    other intellectual property rights therein or relating thereto, except as expressly granted
                    to you in this
                    Agreement.
                </li>
                <li>
                    <span class="sub-headings">Term and Termination.</span> The initial term of this Agreement
                    is for five (5) years from the earlier of (i) the
                    date you purchase or lease your Hino Truck, or (ii) the date you accept the terms of this
                    Agreement (the
                    “<span class="strong-content">Term</span>”). Hino may suspend or terminate this Agreement
                    and discontinue the Services in its sole discretion at
                    any time without notice and without liability to you. <span class="strong-content">You may
                        terminate this Agreement and withdraw your
                        consent to Hino’s collection, use, storage and disclosure of Information by completing
                        the web form at <a href="https://www.HinoUltimate.com">HinoUltimate.com</a></span>. If
                    the Agreement terminates for any reason,
                    you will immediately lose access to the Services and all
                    rights granted to you under this Agreement will also terminate. If the Agreement terminates
                    for any reason
                    and you have not withdrawn your consent as provided above, Hino may continue to collect,
                    use, store and
                    disclose your Information. Termination will not limit any of Hino's rights or remedies at
                    law or in equity.
                </li>
                <li>
                    <span class="sub-headings">Sale or Transfer of Your Hino Truck.</span> If you sell or
                    transfer your Hino Truck or your lease ends, the
                    Services will terminate as described above. You must promptly notify us by sending an email
                    to
                    <span class="highlight-content">ultimatesupport@hino.com</span>. Whether we are notified of
                    the sale, transfer, or lease end of your Hino Truck by you
                    or by someone acting on your behalf (such as a Hino dealer or new owner), we will terminate
                    your Services.
                </li>
                <li>
                    <span class="sub-headings">Disclaimer of Warranties. </span>
                    THE SERVICES ARE PROVIDED TO YOU "AS IS" AND WITH ALL FAULTS AND DEFECTS WITHOUT
                    WARRANTY OF ANY KIND. TO THE MAXIMUM EXTENT PERMITTED UNDER APPLICABLE LAW, HINO, ON ITS OWN
                    BEHALF AND ON
                    BEHALF OF ITS AFFILIATES AND ITS AND THEIR RESPECTIVE LICENSORS AND SERVICE PROVIDERS,
                    EXPRESSLY DISCLAIMS
                    ALL WARRANTIES, WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, WITH RESPECT TO THE
                    SERVICES, INCLUDING
                    ALL IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND
                    NON-INFRINGEMENT,
                    AND WARRANTIES THAT MAY ARISE OUT OF COURSE OF DEALING, COURSE OF PERFORMANCE, USAGE, OR
                    TRADE PRACTICE.
                    WITHOUT LIMITING THE FOREGOING, HINO PROVIDES NO WARRANTY AND MAKES NO REPRESENTATION OF ANY
                    KIND THAT THE
                    SERVICES WILL MEET YOUR REQUIREMENTS, ACHIEVE ANY INTENDED RESULTS, BE COMPATIBLE, OR WORK
                    WITH ANY OTHER
                    SOFTWARE, SYSTEMS, OR SERVICES, OPERATE WITHOUT INTERRUPTION, MEET ANY PERFORMANCE OR
                    RELIABILITY STANDARDS,
                    OR BE ERROR-FREE, OR THAT ANY ERRORS OR DEFECTS CAN OR WILL BE CORRECTED.
                </li>
                <li>
                    <span class="sub-headings">Limitation of Liability.</span>
                    TO THE FULLEST EXTENT PERMITTED BY APPLICABLE LAW, IN NO EVENT WILL HINO OR ITS
                    AFFILIATES, OR ANY OF ITS OR THEIR RESPECTIVE LICENSORS OR SERVICE PROVIDERS, HAVE ANY
                    LIABILITY ARISING
                    FROM OR RELATED TO YOUR USE OF OR INABILITY TO USE THE SERVICES OR THE CONTENT AND SERVICES
                    FOR: (A)
                    PERSONAL INJURY, PROPERTY DAMAGE, LOST PROFITS, LOSS OF DATA, LOSS OF GOODWILL, BUSINESS
                    INTERRUPTION, OR
                    ANY OTHER CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, OR PUNITIVE DAMAGES; (B)
                    DIRECT DAMAGES
                    IN AMOUNTS THAT IN THE AGGREGATE EXCEED THE AMOUNT ACTUALLY PAID BY YOU FOR THE SERVICES.
                    THE FOREGOING
                    LIMITATIONS WILL APPLY WHETHER SUCH DAMAGES ARISE OUT OF BREACH OF CONTRACT, TORT (INCLUDING
                    NEGLIGENCE), OR
                    OTHERWISE AND REGARDLESS OF WHETHER SUCH DAMAGES WERE FORESEEABLE OR HINO WAS ADVISED OF THE
                    POSSIBILITY OF
                    SUCH DAMAGES.
                </li>
                <li>
                    <span class="sub-headings">Indemnification.</span>
                    You agree to indemnify, defend, and hold harmless Hino, Service Providers and their
                    respective officers, directors, shareholders, employees, agents, affiliates, successors, and
                    assigns from
                    and against any and all losses, damages, liabilities, deficiencies, claims, actions,
                    judgments, settlements,
                    interest, awards, penalties, fines, costs, or expenses of whatever kind, including
                    reasonable attorneys'
                    fees, arising from or relating to your use or misuse of the Services or your breach of this
                    Agreement.
                </li>
                <li>
                    <span class="sub-headings">Wireless Carrier.</span>
                    Your use of the wireless service in connection with the Services is further subject to the
                    terms in the addendum attached as <span class="strong-content">Exhibit “A”</span> and made a
                    part of this Agreement by this reference.
                </li>
                <li>
                    <span class="sub-headings">Third Party Beneficiaries.</span>
                    All Service Providers are intended third-party beneficiaries of the protections
                    of this Agreement. This Agreement does not give you any rights against any Service Provider,
                    however, the
                    protections contained in this Agreement, including without limitation, the disclaimers of
                    warranty,
                    limitation of liability, indemnification extent to Service Providers.
                </li>
                <li>
                    <span class="sub-headings">Modification of Agreement.</span>
                    We may modify the terms of this Agreement by giving you notice. We may give you
                    notice by posting the modified Agreement to <span class="highlight-content"><a
                            href="https://www.HinoUltimate.com">HinoUltimate.com</a></span>.
                    If you do not agree with any modification, you may not
                    use the Services. If you use the Services, your use will be deemed as acceptance of the
                    modified Agreement.
                </li>
                <li>
                    <span class="sub-headings">Compliance with Laws.</span>
                    You will comply with all applicable laws when using the Services.
                </li>
                <li>
                    <span class="sub-headings">Severability.</span>
                    If any provision of this Agreement is illegal or unenforceable under applicable law, the
                    remainder of the provision will be amended to achieve as closely as possible the effect of
                    the original term
                    and all other provisions of this Agreement will continue in full force and effect.
                </li>
                <li>
                    <span class="sub-headings">Assignment.</span>
                    Hino may assign this Agreement or any rights or obligations under this Agreement. You may
                    not
                    assign this Agreement or any rights or obligations under this Agreement without Hino’s
                    written consent,
                    which my be withheld in its sole discretion. Any attempted assignment by you will be null
                    and void.
                </li>
                <li>
                    <span class="sub-headings">Governing Law.</span>
                    This Agreement is governed by and construed in accordance with the internal laws of the
                    State
                    of Michigan without giving effect to any choice of law provision. Any legal suit, action, or
                    proceeding
                    arising out of or related to this Agreement or the Services shall be instituted exclusively
                    in the federal
                    courts located in the State of Michigan.
                </li>
                <li>
                    <span class="sub-headings">Entire Agreement.</span>
                    These Terms and our Privacy Policy constitute the entire agreement between you and Hino
                    with respect to the Services and supersedes all prior or contemporaneous understandings and
                    agreements,
                    whether written or oral, with respect to the Services.
                </li>
                <li>
                    <span class="sub-headings">Waiver.</span> No failure to exercise, and no delay in
                    exercising, on the part of either party, any right or any
                    power hereunder shall operate as a waiver thereof, nor shall any single or partial exercise
                    of any right or
                    power hereunder preclude further exercise of that or any other right hereunder.
                </li>
            </ol>

        </section>
        <section class="wireless-carrier-pass-through-terms-addendum">
            <div class="terms-main-heading">
                Exhibit A
            </div>
            <div class="terms-main-heading">
                List of Open Source Software
            </div>
            <table style="width: 100%;">
                <tr>
                    <th style="text-align: center;">
                        Open Source Software
                    </th>
                    <th style="text-align: center;">
                        Software Description
                    </th>

                </tr>
                <tr>
                    <td style="text-align: center;">
                        Linux BSP (kernel & drivers)
                    </td>
                    <td style="text-align: center;">
                        Community Linux modification by Pengutronix
                    </td>
                </tr>
            </table>
        </section>
        <section class="wireless-carrier-pass-through-terms-addendum">
            <div class="terms-main-heading">
                Exhibit B
            </div>
            <div class="terms-main-heading">
                Wireless Carrier Pass-through Terms Addendum
            </div>
            <ol>
                <li>
                    You have no contractual relationship with, and are not a third-party beneficiary of, any
                    agreement between
                    Hino and the wireless service carrier. You agree that the wireless service carrier or Hino
                    will have no
                    legal, equitable or other liability of any kind to the you.
                </li>
                <li>
                    Subject to FCC Number portability rules, you have no property or other rights in any
                    assigned number and
                    such number can be changed at any time.
                </li>
                <li>
                    You understand service may be temporarily refused, interrupted, curtailed or limited because
                    of atmospheric,
                    terrain, or other natural or artificial conditions and may be temporarily interrupted or
                    curtailed due to
                    usage concentrations, modifications, upgrades, relocation and repairs of transmission
                    network. You agree
                    that the wireless service carrier or Hino will not be responsible for such interruptions of
                    the Services or
                    the inability to use the Services within or outside the authorized areas.
                </li>
                <li>
                    You understand that the Services cannot be used to place E911 calls.
                </li>
                <li>
                    You understand that the wireless service carrier or Hino cannot guarantee the security of
                    wireless
                    transmissions and will not be liable for any lack of security relating to the use of the
                    Services.
                </li>
                <li>
                    You understand and agree that the liability and obligations of Hino to you under the Hino
                    Telematics End
                    User Terms for the Services may be strictly controlled and limited by the wireless service
                    carrier’s tariff,
                    if any, and the laws, rules and regulations of the FCC and other United States or foreign
                    governmental
                    authorities which from time to time have jurisdiction. In any event, regardless of the form
                    of action,
                    whether for breach of contract, warranty, negligence, strict liability in tort or otherwise,
                    your exclusive
                    remedy and the total liability of the wireless service carrier, Hino, and/or any supplier of
                    services to
                    Hino arising in any way in connection with the Hino Telematics End User Terms, for any cause
                    whatsoever,
                    including, but not limited to, any failure or disruption of the Services provided, will be
                    limited to
                    payment by Hino of damages in an amount equal to the amount charged to you, if any, for the
                    Services
                    provided under Hino Telematics End User Terms. In no event will Hino and/or the wireless
                    service carrier be
                    liable for any cost, delay, failure or disruption of the Services, lost profits, or
                    incidental, special,
                    punitive or consequential damages.
                </li>
                <li>
                    Hino and/or the wireless service carrier will not be liable for the failure or
                    incompatibility of any
                    equipment in connection with the Services or changes in the network that may impact the
                    service you receive.
                    You will use any equipment at your own risk.
                </li>
                <li>
                    You will indemnify, defend and hold Hino, the wireless service carrier and the officers,
                    employees and
                    agents of each of them harmless from and against all claims, causes of action, losses,
                    expenses, liability
                    or damages (including reasonable attorneys’ fees and costs), and including without
                    limitation for any
                    personal injury or death, arising in any way directly or indirectly in connection with the
                    Hino Telematics
                    End User Terms; the provision or use of the Services; or the use, failure to use or
                    inability to use the
                    Services. This provision will survive the termination of the Hino Telematics End User Terms.
                </li>
                <li>
                    You acknowledge that the Hino Telematics End User Terms are assignable by Hino.
                </li>
                <li>
                    Service may be temporarily suspended or permanently terminated without notice if Hino’s
                    agreement with the
                    wireless service carrier is terminated for any reason, or you violate the network rules and
                    policies. You
                    waive any and all claims against the underlying wireless service carrier, including any
                    roaming carrier, for
                    such suspension or termination.
                </li>
                <li>
                    You will not engage in any prohibited usage, including:
                    <ol type="a">
                        <li>
                            Using the Services for machine to machine based remote monitoring to provide
                            life-sustaining medical care
                            for any individual, including without limitation, in health care and assisted living
                            environments;
                        </li>
                        <li>
                            Using, or attempting to use, the Services for voice communications on a non-T-Mobile
                            branded (white label)
                            SIM card, or in any manner that creates, or appears to create a new “brand” of
                            wireless service;
                        </li>
                        <li>
                            Using the Services for monitoring third parties without their permission, including,
                            but not limited to,
                            prisoner and parolee monitoring;
                        </li>
                        <li>
                            Using the Services to make E911 calls; and
                        </li>
                        <li>
                            Using the Services to enable a voice line of service except as specifically detailed
                            in this Agreement.
                        </li>
                    </ol>
                </li>
            </ol>
        </section>
    </div>
</div>
