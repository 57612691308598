import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class BaseHttpService {
    apiUrl: string;

    constructor(
        private httpClient: HttpClient,
    ) {
        const { url, version } = environment.apiConfig;
        if (version) this.apiUrl = `${url}/${version}/`;
        else this.apiUrl = `${url}/`;
    }

    get(endPoint: string, option?: any): Observable<any> {
        return this.httpClient.get(`${this.apiUrl}${endPoint}`, option);
    }

    post(endPoint: string, payload?: any, option = {}): Observable<any> {
        return this.httpClient.post(`${this.apiUrl}${endPoint}`, payload, option);
    }

    delete(endPoint: string, options = {}): Observable<any> {
        return this.httpClient.delete(`${this.apiUrl}${endPoint}`, options);
    }

    put(endPoint: string, payload?: any): Observable<any> {
        return this.httpClient.put(`${this.apiUrl}${endPoint}`, payload);
    }

    patch(endPoint: string, payload?: any, option?: any): Observable<any> {
        return this.httpClient.patch(`${this.apiUrl}${endPoint}`, payload, option);
    }
}
