import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '@modules/user-management/services/user.service';

type UOMType = number | string;

@Pipe({
    name: 'uomConverter'
})
export class MeasurementPreferencePipe implements PipeTransform {
    appUOMConfig;

    constructor(
        private userService: UserService,
    ) { 
        this.setUOM();
    }

    /** default UOM as imperial metric sytem or from API if available */
    setUOM() {
        if (this.userService.getUOMPreferences()) {
            this.appUOMConfig = this.userService.getUOMPreferences();
        } else {
            this.appUOMConfig = {
                distance: {
                    code: 'MILES',
                },
                volume: {
                    code: 'GAL_U.S._GALLONS',
                },
                weight: {
                    code: 'LB_POUNDS',
                },
                temperature: {
                    code: 'DEGREE_F_FAHRENHEIT',
                },
                pressure: {
                    code: 'PSI_POUNDS_PER_SQUARE_INCH',
                }
            };
        }
    }

    transform(value: UOMType, unit: string): UOMType {
        if (!Number.isNaN(+value)) {
            switch (unit) {
                case 'distance':
                    return this.transformDistance(+value);
                case 'volume':
                    return this.transformVolume(+value);
                case 'weight':
                    return this.transformWeight(+value);
                case 'temperature':
                    return this.transformTemperature(+value);
                case 'pressure':
                    return this.transformPressure(+value);
                default:
                    return value;
            }
        }
        else return value;
    }

    transformPressure(value: number): UOMType {
        switch (this.appUOMConfig.pressure.code) {
            case 'KPA_KILOPASCAL':
                return +(value * 100).toFixed(1);
            case 'PSI_POUNDS_PER_SQUARE_INCH':
                return +(value * 14.504).toFixed(1);
            default:
                return value;
        }
    }

    transformTemperature(value: number): UOMType {
        switch (this.appUOMConfig.temperature.code) {
            case 'DEGREE_F_FAHRENHEIT':
                return +((value * 9 / 5) + 32).toFixed(1);
            case 'K_KELVIN':
                return +(value + 273.15).toFixed(1);
            default:
                return value;
        }
    }

    transformWeight(value: number): UOMType {
        switch (this.appUOMConfig.weight.code) { 
            case 'LB_POUNDS':
                return +(value * 2.205).toFixed(1);
            case 'T_METRIC_TONS_(1000_KG)':
                return +(value / 1000).toFixed(1);
            case 'T_U.S._SHORT_TONS_(2000_LB)':
                return +(value / 907).toFixed(1);
            case 'T_U.K_LONG_TONS_(2240_LB)':
                return +(value / 1016).toFixed(1);
            default:
                return value;
        }
    }

    transformVolume(value: number): UOMType {
        switch (this.appUOMConfig.volume.code) {
            case 'GAL_U.S._GALLONS':
                return +(value / 3.785).toFixed(1);
            case 'GAL_IMPERIAL_GALLONS':
                return +(value / 4.546).toFixed(1);
            default:
                return value;
        }
    }

    transformDistance(value: number): UOMType {
        return this.appUOMConfig.distance.code === 'MILES' ? `${((value / 1000)*0.621371097).toFixed(2)} mi` : `${(value / 1000).toFixed(2)} km`;
    }
}
