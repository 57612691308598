import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ticf',
  templateUrl: './ticf.component.html',
  styleUrls: ['./ticf.component.scss']
})
export class TicfComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  goToTicf() {
    window.open("//"+'ticfpayments.radiusone.com','_blank');
  }

}