import { Component, OnInit, OnDestroy, Output, EventEmitter, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { SubSink } from 'subsink';
import { AuthService } from '@core/services/auth.service';
import { MultiSelectConfig } from '@config/multi-select.config';
import { CryptoService } from '@core/services/crypto.service'; 

@Component({
    selector: 'app-filter-modal-dashboard',
    templateUrl: './filter-modal-dashboard.component.html',
    styleUrls: ['./filter-modal-dashboard.component.scss']
})
export class FilterModalDashboardComponent implements OnInit {
    @Input() mapView;
    @Input() depotList;
    @Input() customerList;
    @Input() statusList;
    @Input() defLevels;
    @Output() filterFormValue = new EventEmitter();
    formGroup: FormGroup;
    subSink = new SubSink();
    isHinoInternal: boolean;
    dropdownSettings = MultiSelectConfig;
    depotDropdownSettings = MultiSelectConfig;
    defDropdownSettings = MultiSelectConfig;
    cusDropdownSettings = MultiSelectConfig;
    selectedStatuses = [];
    selectedDepot = [];
    selectedCustomer = [];
    selectedDefLevel = [];

    constructor(
        private formBuilder: FormBuilder,
        private authService: AuthService,
        private cryptoService: CryptoService
    ) { 

        // Single selection drop down is used for Depots and DEF Levels
        this.depotDropdownSettings = {
            ...this.depotDropdownSettings,
            ...{
                singleSelection: true,
                closeDropDownOnSelection: true,
                idField: 'depotId',
                textField: 'depotName',
                allowSearchFilter: true
            },
        };
        this.defDropdownSettings = {
            ...this.defDropdownSettings,
            ...{
                singleSelection: true,
                closeDropDownOnSelection: true,
                idField: 'taxonomyValue',
            },
        };
        this.cusDropdownSettings = {
            ...this.cusDropdownSettings,
            ...{
                singleSelection: false,
                idField: 'account_id',
                textField: 'account_name',
                itemsShowLimit: 1,
                allowSearchFilter: true
            },
        };
    }

    ngOnInit(): void {
        this.isHinoInternal = this.cryptoService.decryptData(localStorage.getItem('userAccount')).isCorporate;
        this.formGroup = this.formBuilder.group({
            VIN: [''],
            tag: [''],
            status: [''],
            lastReportedTime: [''],
            depot_id: [''],
            fleetCode: [''],
            def_level: [''],
            ...(this.isHinoInternal ? { customer_id: [''] } : {})
        });

        // get selected filter options from  local-storage and populate 
        if (localStorage.getItem('dashboard-filters')) {
            let storedFilters  =  JSON.parse(localStorage.getItem('dashboard-filters'));
            this.prepopulateValues(storedFilters);
        }
        this.subSink.sink = this.formGroup.valueChanges.subscribe(formValue => {
            const multiStatus = this.selectedStatuses.map(el => el.taxonomyCode).join(',');
            const depots = this.selectedDepot.map(el => el.depotId).join(',');
            const customer = this.selectedCustomer.map(el => el.account_id).join(',');
            formValue.status = multiStatus;
            formValue.depot_id = depots;
            formValue.customer_id = customer;
            // Format filter options and Send to Parent component - Dashboard View Component
            this.emitToParent(formValue)
        });
    }

    emitToParent(formValue) {
        formValue = {
            ...formValue,
            ...(formValue.depot_id ? { depot_association: 1 } : {})
        };
        localStorage.setItem('dashboard-filters', JSON.stringify(formValue));
        this.filterFormValue.emit(formValue);
    }

    ngOnDestroy() {
        this.emitToParent(this.getFormValue());
        this.subSink.unsubscribe();
    }

    setMultiStatus() {
        const multiStatus = this.selectedStatuses.map(el => el.taxonomyCode).join(',')
        this.formGroup.get('status').setValue(this.selectedStatuses);
    }

    onStatusDeSelect(event) {
        this.selectedStatuses = this.selectedStatuses.filter(el => el.taxonomyName !== event.taxonomyName);
        this.setMultiStatus();
        if (event.taxonomyCode == "FAULTY") {
            this.statusList = this.statusList.map(item => {
                item.isDisabled = false;
                return item;
            })
        } else {
            this.statusList = this.statusList.map(item => {
                if (item.taxonomyCode == "FAULTY" && this.selectedStatuses.length == 0) {
                    item.isDisabled = false;
                }
                return item;
            })
        }
    }

    onStatusSelect(event) {
        if(event.taxonomyCode != "FAULTY") {
            this.statusList = this.statusList.map(item => {
                if(item.taxonomyCode == "FAULTY") {
                    item.isDisabled = true;
                } else {
                    item.isDisabled = false;
                }
                return item;
            })
        } else {
            this.statusList = this.statusList.map(item => {
                if(item.taxonomyCode != "FAULTY") {
                    item.isDisabled = true;
                } else {
                    item.isDisabled = false;
                }
                return item;
            })
        }
        this.selectedStatuses.push(event);
        this.setMultiStatus();
    }

    onStatusSelectAll(event) {
        event.forEach((element) => {
            let index = event.findIndex((status: any) => {
                return status.taxonomyCode == "FAULTY";
            });
            if (index > -1) {
                this.statusList = this.statusList.map(item => {
                    if(item.taxonomyCode == "FAULTY") {
                        item.isDisabled = true;
                    }
                    return item;
                })
                event.splice(index, 1);
            }
        });
        this.selectedStatuses = event;
        this.setMultiStatus();
    }

    onStatusDeSelectAll(event) {
        this.statusList = this.statusList.map(item => {
            item.isDisabled = false;
            return item;
        })
        this.selectedStatuses.length = 0;
        this.setMultiStatus();
    }

    setMultiCustomer() {
        const multiCustomer = this.selectedCustomer.map(el => el.account_id).join(',')
        this.formGroup.get('customer_id').setValue(this.selectedCustomer);
    }

    onCustomerSelectAll(event) {
        this.selectedCustomer = event;
        this.setMultiCustomer();
    }

    onCustomerDeSelectAll(event) {
        this.selectedCustomer.length = 0;
        this.setMultiCustomer();
    }

    onCustomerDeSelect(event) {
        this.selectedCustomer = this.selectedCustomer.filter(el => el.account_id !== event.account_id);
        this.setMultiCustomer();
    }

    onCustomerSelect(event) {
        this.selectedCustomer.push(event);
        this.setMultiCustomer();
    }

    onDepotDeSelect(event) {
        this.selectedDepot = [];
        this.formGroup.get('depot_id').setValue(this.selectedDepot);
    }

    onDepotSelect(event) {
        this.selectedDepot = [event];
        this.formGroup.get('depot_id').setValue(this.selectedDepot);
    }
    onDefLevelDeSelect(event) {
        this.selectedDefLevel = [];
        this.formGroup.get('def_level').setValue(this.selectedDefLevel);
    }

    onDefLevelSelect(event) {
        this.selectedDefLevel = [event];
        this.formGroup.get('def_level').setValue(this.selectedDefLevel);
    }

    // To populate filter popup with already selected options
    prepopulateValues(storedFilters) {
        let statuses = storedFilters.status?.split(',') || [];
        let customer = storedFilters.customer_id?.split(',') || [];
        let depots = storedFilters.depot_id?.toString().split(',') || [];
        let defLevels = storedFilters.def_level?.toString().split(',') || [];
        statuses.forEach((element) => {
            let index = this.statusList.findIndex((status: any) => {
                return status.taxonomyCode == element;
            });
            if (index > -1) this.selectedStatuses.push(this.statusList[index]);
        });
        customer.forEach((element) => {
            let index = this.customerList.findIndex((customer: any) => {
                return customer.account_id == element;
            });
            if (index > -1) this.selectedCustomer.push(this.customerList[index]);
        });
        depots.forEach((element) => {
            let index = this.depotList.findIndex((depot) => {
                return depot.depotId == element;
            });
            if (index > -1) this.selectedDepot.push(this.depotList[index]);
        });
        defLevels.forEach((element) => {
            let index = this.defLevels.findIndex((level) => {
                return level.taxonomyValue == element;
            });
            if (index > -1) this.selectedDefLevel.push(this.defLevels[index]);
        });
        storedFilters.status = this.selectedStatuses;
        storedFilters.customer_id = this.selectedCustomer;
        storedFilters.depot_id = this.selectedDepot;
        storedFilters.def_level = this.selectedDefLevel;
        this.formGroup.patchValue(storedFilters);
    }

    clearDropdowns() {
        this.selectedStatuses = [];
        this.selectedDepot = [];
        this.selectedDefLevel = [];
        this.selectedCustomer = [];
        this.formGroup.get('status').setValue([]);
        this.formGroup.get('depot_id').setValue([]);
        this.formGroup.get('def_level').setValue([]);
        if(this.isHinoInternal) this.formGroup.get('customer_id').setValue([]);
        this.statusList = this.statusList.map(item => {
            item.isDisabled = false;
            return item;
        })
    }

    getFormValue() {
        let formValue = this.formGroup.value;
        const multiStatus = this.selectedStatuses.length
            ? this.selectedStatuses.map((el) => el.taxonomyCode).join(',')
            : '';
        const multiCustomer = this.selectedCustomer.length
            ? this.selectedCustomer.map((el) => el.account_id).join(',')
            : '';
        const depots = this.selectedDepot.length
            ? this.selectedDepot.map((el) => el.depotId).join(',')
            : '';
        const defLevel = this.selectedDefLevel.length
            ? this.selectedDefLevel.map((el) => el.taxonomyValue).join(',')
            : '';
        formValue.status = multiStatus;
        formValue.customer_id = multiCustomer;
        formValue.depot_id = depots;
        formValue.def_level = defLevel;
        return formValue;
    }
}
