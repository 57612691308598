import { Injectable } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BaseHttpService } from '@core/services/base-http.service';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, map } from 'rxjs/operators';
@Injectable({
    providedIn: 'root',
})
export class SecondayOwnershipService {
    constructor(
        // private http: HttpClient,
        private route: ActivatedRoute,
        private http: BaseHttpService
    ) {}

    verifyVin(vehicleVin) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`secondary-ownership/verify-vin`)
                .subscribe(
                    (response) => {
                        console.log('verify vin ', response);
                        // response.data = {
                        //   "secondaryOwnerRegId": 0,
                        //   "vehicleVin": {
                        //     "vehicleVin": "5PVNJ8AV3M5T50002",
                        //     "vehicleId": 0
                        //   },
                        //   "customer": {
                        //     "customerName": "Amazon/Element",
                        //     "customerContactFirstname": "Sam",
                        //     "customerContactLastname": "Son",
                        //     "streetAddress": "187 Main St",
                        //     "phoneNo": "306-512-2853",
                        //     "city": "Kindersley",
                        //     "state": {
                        //       "stateId": 0,
                        //       "stateName": "Saskatchewan"
                        //     },
                        //     "country": {
                        //       "countryId": 0,
                        //       "countryName": "Canada"
                        //     },
                        //     "zipcode": "S0L 1S0",
                        //     "phoneNumber": "306-512-2853",
                        //     "emailAddress": "sam@gmail.com"
                        //   },
                        //   "insightFleetManager": {
                        //     "isInsightFleetManager": "string",
                        //     "insightContactSalutation": {
                        //       "salutationId": 0,
                        //       "SalutationName": "string"
                        //     },
                        //     "insightContactFirstname": "string",
                        //     "insightContactLastname": "string",
                        //     "insightEmailAddress": "string"
                        //   },
                        //   "serviceStartDate": 1619144738719,
                        //   "serviceStartEnd": 1619144738719,
                        //   "createdOn":1619144738719,
                        //   "isEmailSent": 1619144738719,
                        //   "emailSentOn": 1619144738719
                        // }
                        resolve(response.data);
                    },
                    (error) => {
                        reject(error);
                    }
                );
        });
    }

    sendOwnershipDetails(data) {
        return new Promise((resolve, reject) => {
            this.http
                .post(`secondary-ownership/verify-vin`, data)
                .subscribe((response) => {
                    console.log('sendOwnershipDetails --  vin ', response);
                    if(response.statusCode == 200) resolve(response);
                    else reject(response);
                });
        });
    };

    getSecondaryOwnershipMailConfig(): Observable<any> {
        return this.http
            .get(`common/configuration/SECONDARY_OWNERSHIP`)
            .pipe(tap((data) => {}));
    }

    saveSecondaryOwnershipMailConfig(mailConfig): Observable<any> {
        return this.http
            .post(`common/configuration/SECONDARY_OWNERSHIP`, mailConfig)
            .pipe(tap((data) => {
              console.log(data);

            }));
    }
}
