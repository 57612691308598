import { Component, OnInit } from '@angular/core';
import { authSettings } from '@config/oauth';

@Component({
  selector: 'app-corporate-login',
  templateUrl: './corporate-login.component.html',
  styleUrls: ['./corporate-login.component.scss']
})
export class CorporateLoginComponent implements OnInit {
  isPrivacyChecked: boolean;

  constructor() {
    this.isPrivacyChecked = true;
    try {
      this.isPrivacyChecked = window.self !== window.top;
    } catch(e) {
      this.isPrivacyChecked = true;
    }
    window.open(authSettings['aad_auth'], '_self');
   }
  ngOnInit(): void {
  }
}
