
<div class="container">
    <div class="error-main">
        <h1>Oops!</h1>
        <p class="error-message">Authentication Mismatch Occured.</p>
        <div class="error-heading" (click)="goToRDLogin()">
            <span class="material-icons">
                refresh
            </span>
        </div>
    </div>
</div>