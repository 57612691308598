import { Component, OnInit, Output, EventEmitter, Input, SimpleChanges, ChangeDetectorRef, NgZone } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { AliasUserService } from '@modules/alias-user/services/alias-user.service';
import { AppService } from '@core/services/app.service';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-alias-user',
    templateUrl: './alias-user.component.html',
    styleUrls: ['./alias-user.component.scss'],
})
export class AliasUserComponent implements OnInit {
    @Input() users = [];
    @Input() fleet = [];
    @Input() isCorporate:boolean; 
    @Output() selectedUser: EventEmitter<any> = new EventEmitter();
    displayedColumns: string[] = ['name', 'radioButton'];
    dataSource = new MatTableDataSource<any>(this.users);
    fleetDataSource = new MatTableDataSource<any>(this.fleet);
    message = `Please type company name / Fleet Code to get users list`;
    isLoading: boolean;
    subSink = new SubSink();
    @Input() aliasPopupLoading:boolean;
    constructor(
      private aliasUserService: AliasUserService,
      private changeDetectorRef: ChangeDetectorRef,
      private appService: AppService,
      private ngZone: NgZone
      ) {}

    ngOnInit(): void {
       // subscribe to loading events
        this.ngZone.runOutsideAngular(() => {
            // this.subSink.sink = this.appService.appLoader$.subscribe(value => {
            //     this.isLoading = value;
            //     this.changeDetectorRef.detectChanges();
            // });
        });
    }

    ngOnChanges(changes: SimpleChanges) {
      if (changes?.users?.currentValue) {
        this.dataSource = new MatTableDataSource<any>(this.users);
      }
      this.fleetDataSource = new MatTableDataSource<any>(this.fleet);
    }

    selectedUserForSimulation(user) {
        this.selectedUser.emit(user);
    }

    /** search */
    searchOutput(searchQuery: string) {
      this.isLoading = true;
       var searchParam = searchQuery.toString().split('&').join('%26');
        this.aliasUserService.aliasUserSearch(searchParam).then((response) => {
          this.users = response['users'];
          this.dataSource = new MatTableDataSource<any>(this.users);
          this.isLoading = false;
          this.message = (this.users.length == 0 && searchParam) ? `No Records Found`: `Please type company name / Fleet Code to get users list`;
        });
    }
}
