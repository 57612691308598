import { NgModule, InjectionToken } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { LoginComponent } from '@core/components/login/login.component';
import { BaseComponent } from '@core/components/base/base.component';
import { TestRouteComponent  } from '@core/components/test-route/test-route.component';
import { PrivacyPolicyComponent } from '@core/components/privacy-policy/privacy-policy.component';
import { TermsAndConditionsComponent } from '@core/components/terms-and-conditions/terms-and-conditions.component';
import { DashboardViewComponent } from '@modules/dashboard/components/dashboard-view/dashboard-view.component';
import { AuthGuard } from '@core/guards/auth.guard';
import { NotFoundComponent } from '@core/components/not-found/not-found.component';
import { SignupComponent } from '@core/components/signup/signup.component';
import { AccessDeniedComponent } from '@core/components/access-denied/access-denied.component';
import { AuthMismatchComponent } from '@core/components/auth-mismatch/auth-mismatch.component';
import { FirstComponentComponent } from './first-component/first-component.component';
import { CorporateLoginComponent } from './core/components/corporate-login/corporate-login.component';
import { LoginRedirectGuard } from '@core/guards/login-redirect.guard';
import { MyfleetVehicleViewComponent } from '@modules/myfleet/components/myfleet-vehicle-view/myfleet-vehicle-view.component';
import { HelpComponent } from '@core/components/help/help.component';
import { RegisterVinFormComponent } from '@core/components/secondary-ownership/register-vin-form/register-vin-form.component';
import { CustomerLoginComponent } from './core/components/customer-login/customer-login.component';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
const externalUrlProvider = new InjectionToken('externalUrlRedirectResolver');
import { authSettings } from '@config/oauth';
import { TicfComponent } from '@core/components/ticf/ticf.component';
import { CorpLoginComponent } from './core/components/corp-login/corp-login.component';

// hino app base routes
const appRoutes: Routes = [
    { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
    // {
    //     path: 'dashboard',
    //     component: DashboardViewComponent,
    //     children: [{ path: 'vehicle/:vehicleId/details', component: MyfleetVehicleViewComponent }],
    //     data: { title: 'dashboard' }
    // },
    {
        path: 'dashboard',
        loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
        data: { title: 'dashboard' }
    },
    {
        path: 'my-fleet',
        loadChildren: () => import('./modules/myfleet/myfleet.module').then(m => m.MyfleetModule),
        data: { title: 'my fleet' }
    },
    {
        path: 'telematics',
        loadChildren: () => import('./modules/telematics/telematics.module').then(m => m.TelematicsModule),
        data: { title: 'telematics' }
    },
    // {
    //     path: 'remote-diagnostics',
    //     loadChildren: () => import('./modules/remote-diagnostics/remote-diagnostics.module').then(m => m.RemoteDiagnosticsModule),
    //     data: { title: 'remote diagnostics' }
    // },
    
    {
        path: 'case-management',
        loadChildren: () => import('./modules/case-management/case-management.module').then(m => m.CaseManagementModule),
        data: { title: 'case management' }
    },
    {
        path: 'user-management',
        loadChildren: () => import('./modules/user-management/user-management.module').then(m => m.UserManagementModule),
        data: { title: 'user management' }
    },
    {
        path: 'depot-management',
        loadChildren: () => import('./modules/depot-management/depot-management.module').then(m => m.DepotManagementModule),
        data: { title: 'depot management' }
    },
    {
        path: 'geofence',
        loadChildren: () => import('./modules/geofencing/geofencing.module').then(m => m.GeofencingModule),
        data: { title: 'geofence' }
    },
    {
        path: 'dtc-error',
        loadChildren: () => import('./modules/dtc-error/dtc-error.module').then(m => m.DtcErrorModule),
        data: { title: 'Fault Code Management' }
    },
    {
        path: 'ticf', component: TicfComponent,
        data: { title: 'TICF' }
    },
    {
        path: 'help', component: HelpComponent,
        data: { title: 'Help' }
    }
];

const routes: Routes = [
    { path: '', redirectTo: 'hino', pathMatch: 'full' },
    { path: 'hino', component: BaseComponent, canActivateChild: [AuthGuard], children: appRoutes },
    {
        path: 'login', component: LoginComponent, children:
            [
                { path: 'aad-seamless', component: LoginComponent }
            ]
    },
    {
        path: 'corporate-login', component: CorporateLoginComponent
    },
    {
        path: 'corp-login', component: CorpLoginComponent
    },
    {
        path: 'customer-login',
        resolve: {
            url: externalUrlProvider,
        },
        canActivate: [externalUrlProvider],
        component: CustomerLoginComponent,
        data: {
            externalUrl: ''
        }
    },
    {
        path: 'vin-register', component: RegisterVinFormComponent
    },
    { path: 'signup', component: SignupComponent },
    {
        path: 'mobile',
        loadChildren: () => import('./mobile/mobile.module').then(m => m.MobileModule)
    },
    {
        path: 'privacy-policy', component: PrivacyPolicyComponent
    },
    {
        path: 'terms-and-conditions', component: TermsAndConditionsComponent
    },
    { path: 'access-denied', component: AccessDeniedComponent },
    { path: 'auth-mismatch', component: AuthMismatchComponent },
    { path: 'first-component', component: FirstComponentComponent },
    { path: '404', component: NotFoundComponent },
    { path: '**', redirectTo: '/404' }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', preloadingStrategy: PreloadAllModules, onSameUrlNavigation: 'reload' })],
    exports: [RouterModule],
    providers: [
        {
            provide: externalUrlProvider,
            useValue: (route: ActivatedRouteSnapshot) => {
                const externalUrl = authSettings['b2c_auth'];
                window.open(externalUrl, '_self');
            },
        },
    ],
})
export class AppRoutingModule { }
