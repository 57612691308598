import { Component, OnInit, EventEmitter, Output, Input, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { debounceTime, filter } from 'rxjs/operators';
import { SubSink } from 'subsink';

@Component({
    selector: 'app-search',
    templateUrl: './search.component.html',
    styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit, OnDestroy {
    // default debounce time
    @Input() debounceTime: number = 1000;
    // emit search query to parent component after debounce time
    @Output() searchOutput$ = new EventEmitter<string>();
    search = new FormControl('');
    subsink = new SubSink();
    @Input() searchPlaceholder: string = '';
    @Input() placeholderText: string = '';

    constructor() { 
    }

    ngOnInit(): void {
        this.subsink.sink = this.search.valueChanges.pipe(
            debounceTime(this.debounceTime),
            filter(query => {
                if (!query) {
                    this.searchOutput$.emit('');
                    return false;
                }
                return true;
            }),
            filter(query => query.length > 1),
        ).subscribe(value => {
            this.searchOutput$.emit(value);
        });
    }

    ngOnDestroy() {
        this.subsink.unsubscribe();
    }

    clearSearch() {
        this.search.setValue('');
        this.searchOutput$.emit('');
    }
}
