import { Component, OnInit, Input, SimpleChanges } from '@angular/core';
import { ChartOptions } from 'chart.js';
import { colorsSettings } from '@config/color-config';

@Component({
  selector: 'app-dashboard-utilization',
  templateUrl: './dashboard-utilization.component.html',
  styleUrls: ['./dashboard-utilization.component.scss']
})
export class DashboardUtilizationComponent implements OnInit {
    @Input() chartApiData;
    graphOptions: ChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        legend: { 
            display: true,
            position: 'left',
            align: 'start'
        },
        tooltips: {
            callbacks: {
              label: function(tooltipItem, data) {
                return data['labels'][tooltipItem['index']] + ': ' + data['datasets'][0]['data'][tooltipItem['index']] + '%';
              }
            }
          }
    };
    labels;
    data;
    statusColors = {
        driving: colorsSettings.driving,
        idle: colorsSettings.idle,
        pto: colorsSettings.pto,
        stopped: colorsSettings.stopped,
        faulty: colorsSettings.faulty,
    };
    pieChartColors;

    constructor() {
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue) {
            this.labels = this.chartApiData.chartLabels;
            this.data = this.chartApiData?.chartData[0]?.data.filter(Boolean) || [];
            this.chartApiData?.chartData[0]?.data.forEach((value, index) => {
            if (value == null) {
              this.labels.splice(index)
            }
            });
            this.pieChartColors = [{
                backgroundColor: this.labels.map(el => this.statusColors[el.toLowerCase()])
            }];
        }
    }

}
