import { Injectable } from '@angular/core';
import { BaseHttpService } from '@core/services/base-http.service';
import { AuthService } from '@core/services/auth.service';
import { LoginService } from '@core/services/login.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class AliasUserService {
    broadcastSimulation: BehaviorSubject<any> = new BehaviorSubject(null);
    constructor(
        private http: BaseHttpService,
        private authService: AuthService,
        private loginService: LoginService
    ) {}

    getusers(query) {
        return new Promise((resolve, reject) => {
            this.http
                .get(query, { observe: 'response' })
                .subscribe((aliasResponse) => {
                    if (aliasResponse.status == 200) {
                        resolve(aliasResponse.body.data);
                    }
                });
        });
    }

    aliasUserSearch(search) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`users/accounts/search?user_type=1&rows=100${(search)? '&search=' + search : ''}`, { observe: 'response' })
                .subscribe((aliasResponse) => {
                    if (aliasResponse.status == 200) {
                        resolve(aliasResponse.body.data);
                    }
                }, (errorResponse) => {
                    if(errorResponse.status == 404) {
                        resolve({ users: [] })
                    }
                });
        });
    }


    userFleetFetch(email, fleetCode) {
        return new Promise((resolve, reject) => {
            this.http
                .get(`fetchAllAccountsOfUser?email_id=${(email)}&fleet_code=${(fleetCode)}`, { observe: 'response' })
                .subscribe((aliasResponse) => {
                    if (aliasResponse.status == 200) {
                        resolve(aliasResponse.body);
                    }
                }, (errorResponse) => {
                    if(errorResponse.status == 404) {
                        resolve({ users: [] })
                    }
                });
        });
    }
}
