<section class="route-container">
    <section class="main-title">
        <div class="btn-group-sm segmentedButton">
            <button type="button" class="btn" (click)="closeMapViewFunction(); showAssignmentHistory=false;"
                [style.background-color]="
            basicInfo ? '#b03034' : 'white'
            " [style.color]="
            basicInfo ? 'white' : 'black'
            ">
                {{ 'BASIC INFO' |translate |titlecase}}
            </button>
            <button type="button" class="btn"
                (click)="locationHistory=true; basicInfo=false; showMapView(); showAssignmentHistory=false;"
                [style.background-color]="
            locationHistory ? '#b03034' : 'white'
            " [style.color]="
            locationHistory ? 'white' : 'black'
            ">
                {{ 'LOCATION HISTORY' |translate |titlecase}}
            </button>
            <button type="button" class="btn"
                (click)="closeMapViewFunction(); basicInfo=false; showAssignmentHistory=true;"
                [style.background-color]="showAssignmentHistory ? '#b03034' : 'white'"
                [style.color]="showAssignmentHistory ? 'white' : 'black'">
                {{ 'DEPOT ASSIGNMENT HISTORY' |translate |titlecase}}
            </button>
        </div>
        <div class="main-title-right-panel">
            <!-- <a (click)="deleteVehicleDetails()" [title]="'REMOVE VEHICLE FROM DEPOT' |translate |titlecase"
                href="javascript:void(0)">
                <mat-icon style="color: #d1565a">delete</mat-icon>
            </a> -->
            <a href="javascript:void(0)" (click)="goToMyFleets()"
                *ngIf="basicInfo && !showAssignmentHistory && !assignmentHistory">
                <mat-icon>cancel</mat-icon>Close Details
            </a>
            <a href="javascript:void(0)" (click)="goToDepot()"
                *ngIf="assignmentHistory && (basicInfo || showAssignmentHistory)">
                <mat-icon>cancel</mat-icon>Close Details
            </a>
            <a (click)="closeMapViewFunction()" href="javascript:void(0)" class="close-map-view" *ngIf="openMapView">
                <mat-icon>cancel</mat-icon>{{'CLOSE MAP' |translate |titlecase}}
            </a>
        </div>
    </section>
    <section>
        <div class="row vehicle-charts" *ngIf="!showAssignmentHistory">
            <div class="col-12">
                <div class="basic-info" *ngIf="vehicleData">
                    <div class="row" *ngIf="source === 'depot' && vehicleData?.currentOwnership?.transferredOn">
                        <div class="col-12" style="padding: 2% 4%;">
                            <span class="transfer-label">Transferred</span>
                        </div>
                    </div>
                    <div class="cards">
                        <div class="data-field">
                            <p><strong>{{ 'VEHICLE VIN' |translate |titlecase}}</strong></p>
                            <span>{{vehicleData.vehicle.vehicleVin}}
                                <mat-icon class="mat-cell" ngxClipboard [cbContent]="vehicleData.vehicle.vehicleVin"
                                    (click)="openToastForSuccess()"
                                    style="margin-top: 4px; font-size: 16px; cursor: pointer; color: rgb(176, 48, 52);">
                                    file_copy
                                </mat-icon>
                            </span>
                        </div>
                        <div class="data-field">
                            <p>
                                <strong>
                                    <span>{{ 'TAGS' |translate |titlecase}}</span>
                                </strong>
                            </p>
                            <p class="textValue" *ngIf="!tagEditEnable">
                                <label [title]="vehicleData.tags" style="text-transform: lowercase;">
                                    {{ vehicleData.tags?.length > 20 ? vehicleData.tags?.slice(0, 20) + '...' :
                                    (vehicleData?.tags || blankFieldText ) }}</label>
                                <mat-icon *ngIf="!isDepot && !isHinoInternal" class="mat-cell"
                                    style="margin: 0 0 0 10px; font-size:16px; cursor: pointer;" (click)="editTag()">
                                    edit
                                </mat-icon>
                            </p>
                            <!-- tags edit section -->
                            <p *ngIf="tagEditEnable">
                                <mat-icon *ngIf="!isDepot && tagEditEnable" class="mat-cell"
                                    style="margin: 12px 8px 0 0; font-size:16px; cursor: pointer;"
                                    (click)="tagChange()">close
                                </mat-icon>
                                <mat-form-field class="tags-edit-section">
                                    <mat-chip-list #chipList>
                                        <mat-chip *ngFor="let tag of vehicleData.originalTags" [selectable]="true"
                                            [removable]="true" (removed)="removeTag(tag)">
                                            {{ tag.tagName | lowercase }}
                                            <mat-icon matChipRemove>cancel</mat-icon>
                                        </mat-chip>
                                        <input id="matChipInput" placeholder="Add Tags" [matChipInputFor]="chipList"
                                            [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                                            [matChipInputAddOnBlur]="true" (matChipInputTokenEnd)="addTag($event)">
                                    </mat-chip-list>
                                </mat-form-field>
                            </p>
                        </div>
                        <div class="data-field">
                            <p><strong>{{ 'DEPOT' |translate |titlecase}}</strong></p>
                            <p>{{vehicleData.depot?.depotName ? vehicleData.depot?.depotName : 'unassigned' | titlecase}}</p>
                        </div>
                        <div class="data-field">
                            <p><strong>{{ 'LAST REPORTED LOCATION' |translate |titlecase}}</strong></p>
                            <p>{{vehicleData.lastLocation.lastReportedLocation ?
                                (vehicleData.lastLocation.lastReportedLocation) : blankFieldText }}</p>
                        </div>
                        <div class="data-field">
                            <p><strong>{{ 'LAST REPORTED TIME' |translate |titlecase}}</strong></p>
                            <p>{{vehicleData.lastLocation?.lastReportedtime | vehicleTimeHandler}}</p>
                        </div>
                        <div class="data-field">
                            <p><strong>{{ 'VOCATION' |translate |titlecase}}</strong></p>
                            <!-- <p>{{vehicleData.vocation.vocationName ? vehicleData.vocation.vocationName : blankFieldText }}</p> -->
                            <p *ngIf="!vocationEditEnable">
                                <label class="textValue">{{vehicleData.vocation || blankFieldText }}</label>
                                <mat-icon *ngIf="!isDepot && !isHinoInternal" class="mat-cell"
                                    style="margin-left:2px; font-size:16px; cursor: pointer;"
                                    (click)="toggleVocation(true)">edit
                                </mat-icon>
                            </p>
                            <p *ngIf="vocationEditEnable" class="textValue" style="display: flex;">
                                <mat-icon *ngIf="!isDepot && vocationEditEnable" class="mat-cell"
                                    style="margin: 12px 8px 0 0; font-size:16px; cursor: pointer;"
                                    (click)="toggleVocation(false)">close
                                </mat-icon>
                                <select class="editable" (change)="vocationChange($event)" class="form-control"
                                    [(ngModel)]="selecetdVocationId">
                                    <option value="">{{'CHOOSE VOCATION' |translate |titlecase }}</option>
                                    <option *ngFor="let option of vocationDropdowns" [value]="option.taxonomyId"
                                        [selected]="selecetdVocationId == option.taxonomyId">
                                        {{ option.taxonomyName |translate }}</option>
                                </select>
                            </p>
                        </div>
                        <div class="data-field">
                            <p><strong>{{ 'CASEMANAGEMENTALLTABLES.STATUS' |translate |titlecase}} </strong></p>
                            <p>{{vehicleData.drivingStatus?.transmissionStatus ?
                                vehicleData.drivingStatus?.transmissionStatus : blankFieldText }}</p>
                        </div>
                        <div class="data-field" *ngIf="isDecisivCasemanagementEnabled">
                            <p><strong>{{ 'CASES' |translate |titlecase}}</strong></p>
                            <p>
                                <!-- <ng-container *ngFor="let case of vehicleData.cases">
                                <a href="javascript:void(0)">
                                 <img  matTooltip [matTooltip]="'Case Id - ' + case?.caseId"
                                        [matTooltipPosition]="'right'" style="margin-top: -10px; width: 32px;" src="../../../../assets/images/case_module/Case MGT.png" width="60px">
                                </a>
                            </ng-container> -->
                                <ng-container *ngIf="vehicleData.cases?.length">
                                    <a href="javascript:void(0)" (click)="navigateToCaseManagement()">
                                        <img matTooltip [matTooltip]="vehicleData.caseList"
                                            [matTooltipPosition]="'right'" matTooltipClass="line-broken-tooltip"
                                            matTooltipHideDelay="50" src="../../../../assets/images/case_module/Case MGT.png"
                                            style="width: 32px;" style="margin-top: -10px; width: 32px;"
                                            src="../../../../assets/images/case_module/Case MGT.png" width="60px">
                                    </a>
                                </ng-container>
                            </p>
                        </div>
                        <div class="data-field">
                            <p><strong><span>{{ 'ODOMETER' |translate |titlecase}} : </span></strong></p>
                            <span>{{ (currentUserPreference.uom?.distance?.code === 'MILES') ? (vehicleData.odometer / 1.609).toFixed(2) || '0' : vehicleData.odometer || '0'}}</span>&nbsp;
                            <span *ngIf="currentUserPreference">{{ (currentUserPreference.uom?.distance?.code === 'MILES') ? 'mi' : 'km'}}</span>
                        </div>
                        <!-- <div class="data-field">
                            <p><strong>{{ 'ACTIVE FAULT CODE' |translate |titlecase}}</strong></p>
                            <span>{{vehicleData.activeFaults[0].dtc_cummins_id || vehicleData.activeFaults[0].dtc_id || '0'}}</span>
                        </div> -->
                        <div class="data-field">
                            <p><strong>{{ 'ACTIVE FAULT CODES & DESCRIPTION' |translate |titlecase}}</strong></p>
                            <div *ngIf="vehicleData.activeFaults?.length; else noActiveFaults">
                                <ng-container *ngFor="let faults of vehicleData.activeFaults; let lastItem = last">
                                  {{ faults.fault_cd }} - {{ faults.dtc_description || faults.fault_cd_desc }} - {{ faults.ts | vehicleTimeHandler}}
                                  <br *ngIf="!lastItem" />
                                </ng-container>
                              </div>                              
                            <ng-template #noActiveFaults>{{"No Active Faults"}}</ng-template>
                            <!-- <span>{{vehicleData.activeFaults[0].fault_cd_desc || vehicleData.activeFaults[0].dtc_description || "No Active Faults" }}</span> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row vehicle-charts" *ngIf="!showAssignmentHistory">
            <div class="col-lg-6 col-md-12 col-sm-12" style="padding-right: 0 !important;">
                <div class="graph-section">
                    <div style="width:100%; border-bottom: 1px solid #eaeaea;  margin-bottom: 1%;">
                        <p class="chart-heading">{{'UTILIZATION' |translate |titlecase }} <span
                                style="text-transform: lowercase;">{{'FOR' |translate |titlecase }}</span> {{ 'MONTHS.'
                            + currentMonth | translate |titlecase }}</p>
                    </div>
                    <div style="width:100%;" fxLayout="column">
                        <app-utilization [chartApiData]="utilizationChart" [canvasOnly]="true">
                        </app-utilization>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="graph-section">
                    <div style="width:100%; border-bottom: 1px solid #eaeaea;  margin-bottom: 1%;">
                        <p class="chart-heading">{{'IDLE TIME' |translate |titlecase }}</p>
                    </div>
                    <div style="width:100%;" fxLayout="column">
                        <app-idle-time [chartApiData]="idleTimeChart" [canvasOnly]="true" style="margin-top: 2em;">
                        </app-idle-time>
                    </div>
                </div>
            </div>
        </div>
        <div class="row vehicle-charts" *ngIf="!showAssignmentHistory">
            <div class="col-lg-6 col-md-12 col-sm-12" style="padding-right: 0 !important;">

                <div class="graph-section">
                    <div style="width:100%; border-bottom: 1px solid #eaeaea;  margin-bottom: 1%;">
                        <p class="chart-heading">{{'FUEL ECONOMY' |translate |titlecase }}</p>
                    </div>
                    <div style="width:100%;" fxLayout="column">
                        <app-fuel-economy [chartApiData]="fuelEconomyChart" [canvasOnly]="true">
                        </app-fuel-economy>
                    </div>
                </div>
            </div>
            <div class="col-lg-6 col-md-12 col-sm-12">
                <div class="graph-section">
                    <div style="width:100%; border-bottom: 1px solid #eaeaea;  margin-bottom: 1%;">
                        <p class="chart-heading">{{'COMPARISON CHART HEADER' |translate |titlecase }}</p>
                    </div>
                    <div style="width:100%;" fxLayout="column">
                        <app-comparison [chartApiData]="comparisonChart" [canvasOnly]="true" style="margin-top: 2em;">
                        </app-comparison>
                    </div>
                </div>
            </div>
        </div>
        <!-- <div class="row vehicle-charts" *ngIf="showAssignmentHistory"> -->
        <div fxLayout="column" class="outerDiv" *ngIf="showAssignmentHistory">
            <div class="innerDiv" *ngIf="locationHistoryList">
                <div class="imageDiv">
                    <!-- <img src="https://tiq.azureedge.net/hino-website/Uploads/2020-02/7dcf66ed-8ee8-4a1f-9c48-9ca0aae3e917{69758}[600x400].jpg"
                            style="border-radius: 50%; width: 150px; padding: 10px;" /> -->
                    <app-vehicle-header *ngIf="vehicleData" [vinNumber]="vehicleData?.vin"
                        [vehicle]="vehicleData?.vehicle"></app-vehicle-header>
                    <!-- <h3> {{ 'VEHICLE VIN' |translate |titlecase}} : {{vehicleData.vehicle.vehicleVin}}</h3> -->
                </div>
                <div class="row basicInfo" *ngIf="locationHistoryList.length>0">
                    <div class="col-12">
                        <table class="table table-striped">
                            <thead>
                                <th>{{ 'DEPOT NAME' |translate |titlecase}}</th>
                                <th>{{ 'DATE' |translate |titlecase}}</th>
                                <th>{{ 'TRANSFERRED BY' |translate |titlecase}}</th>
                            </thead>
                            <tbody>
                                <tr style="background: white;" *ngFor="let instance of locationHistoryList">
                                    <td>{{ instance.depot.depotName}} </td>
                                    <td>{{ checkIsNumber(instance.assignedOn) ? (instance.assignedOn |
                                        vehicleTimeHandler): blankFieldText }}</td>
                                    <td>{{ instance.assignmentBy.fullName || blankFieldText }}</td>
                                </tr>
                            </tbody>
                        </table>
                        <mat-paginator #locationHistoryPaginator [length]="length" [pageIndex]="pageIndex" [pageSize]="pageSize"
                        [pageSizeOptions]="[15, 30]" (page)="pageEvent = getServerData($event)" showFirstLastButtons></mat-paginator>
                    </div>
                </div>
                <div class="noData">
                    <span *ngIf="locationHistoryList.length==0">No data found!</span>
                </div>
            </div>
        </div>
        <!-- </div> -->
    </section>


    <div>
        <mat-sidenav-container class="rightBar sidenav-container">
            <mat-sidenav #rightBar class="fleet-sidenav" [(opened)]="rightBarState" position="end" mode="side" #drawer
                fixedInViewport="true" [fixedTopGap]="95">
                <mat-icon class="fleet-sidenav-close" color='warn' (click)="closeRightBar()">close</mat-icon>
                <section class="vehicle-header-wrapper">
                    <app-vehicle-header *ngIf="vehicleData" [vinNumber]="vehicleData?.vin"
                        [vehicle]="vehicleData?.vehicle"></app-vehicle-header>
                </section>
                <!-- map sidebar -->
                <div #mapSidebar>
                    <div class="text-center">
                        <label class="vehicle-data">{{'LOCATION HISTORY' |translate |titlecase}}</label>
                    </div>
                    <!-- location search -->
                    <div class="col-12" style="margin: 20px 0; padding: 0px 22px 0 20px">
                        <app-search [searchPlaceholder]="'routes'" (searchOutput$)="searchOutput($event)"></app-search>
                    </div>
                    <!-- route repeater -->
                    <section *ngIf="routesData?.data?.length; else noRoutes" class="routes">
                        <div class="route-table-title">
                            <span>{{'FROM' |translate |titlecase}}</span>
                            <span>{{'TO' |translate |titlecase}}</span>
                            <span>{{'DATE' |translate |titlecase}}</span>
                            <span>&nbsp;</span>
                        </div>
                        <a *ngFor="let pathData of routesData?.data; index as i;"
                            (click)="setSelection($event, pathData, i);"
                            [attr.data-selection]="isRouteSelected(pathData) ? '1' : '0'"
                            [class.active]="isRouteSelected(pathData)" href="javascript:void(0)" class="path-instance">
                            <span [title]="pathData.start">{{ pathData.start }}</span>
                            <span [title]="pathData.end">{{ pathData.end }}</span>
                           <!-- <span>{{ pathData.date ? (pathData.date | vehicleTimeHandler) : blankFieldText }}</span> -->
                           <span>{{ pathData.dateInText }}</span>
                            <span><input type="checkbox" [checked]="isRouteSelected(pathData)"></span>
                        </a>
                    </section>
                    <ng-template #noRoutes>
                        <div class="no-data">
                            <span *ngIf="isLoading">{{'FETCHING ROUTES' |translate |titlecase}}</span>
                            <span *ngIf="!isLoading && routesData?.data?.length==0">{{'NO ROUTES FOUND' |translate
                                |titlecase}}</span>
                        </div>
                    </ng-template>
                </div>
            </mat-sidenav>
        </mat-sidenav-container>
    </div>
    <!-- map view -->
    <app-azure-map *ngIf="openMapView" (click)="rightBarState = true" [routesData]="routesData"
        [selectedVehicle]="vehicleData" [currentLocation]="vehicleData.currentLocation"
        (mapRoutes)="showMapRoutes($event)">
    </app-azure-map>
</section>

<!-- Confirmaton Modal -->
<ng-template #confirmationModal let-modal>
    <app-confirm-dialog (isAccepted)="confirmationResponse($event , modal)" [acceptButtonText]="'Ok'"
        [declineButtonText]="'Cancel'" [action]="'Remove'" [entity]="'vehicle from ' + vehicleData.depot?.depotName">
    </app-confirm-dialog>
</ng-template>