export const dashboardTableDataConfig = {
    multiselect: false,
    showTruckIconColumn: true,
    expandRow: false,
    draggable: false,
    columnNames: [
        { title: 'logo', addOns: { enabled: false, title: '', iconType: '' } },
        { title: 'vin', addOns: { enabled: false, title: '', iconType: '' } },
        { title: 'customer Name', addOns: { enabled: false, title: '', iconType: '' } },
        { title: 'tags', addOns: { enabled: false, title: '', iconType: '' } },
        {
            title: 'last Location',
            addOns: { enabled: false, title: '', iconType: '' },
        },
        {
            title: 'status',
            addOns: { enabled: false, title: '', iconType: '' },
        },
        {
            title: 'depot Name',
            addOns: { enabled: false, title: '', iconType: '' },
        },
    ],
    dataFields: [
        {
            title: 'logo',
            fieldName: '',
            fieldPath: '',
            dataFormat: {
                type: '',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: false,
            logoType: 'truck',
        },
        {
            title: 'vin',
            fieldName: 'vin',
            fieldPath: 'vehicle.vehicleVin',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'customer Name',
            fieldName: 'customer Name',
            fieldPath: 'currentOwnership.ownerName',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'tags',
            fieldName: 'tags',
            fieldPath: 'tags',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'last Location',
            fieldName: 'lastReportedLocation',
            fieldPath: 'lastLocation.lastReportedLocation',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'status',
            fieldName: 'status',
            fieldPath: 'drivingStatus.transmissionStatus',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: true,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
        {
            title: 'depot Name',
            fieldName: 'depotName',
            fieldPath: 'depot.depotName',
            dataFormat: {
                type: 'String',
                url: '',
                params: ''
            },
            isSortRequired: false,
            toolTip: { enabled: false, title: '' },
            display: true,
            logoType: '',
        },
    ],
};

