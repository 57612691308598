import { Injectable, EventEmitter } from '@angular/core';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BaseHttpService } from '@core/services/base-http.service';
import { map } from 'rxjs/operators';
import * as fs from 'file-saver';
import { Workbook } from 'exceljs';

@Injectable({
    providedIn: 'root'
})
export class MyfleetService {
    invokeTransferTruckFunction = new EventEmitter();
    invokeCloseMapViewFunction = new EventEmitter();
    subsVar: Subscription;
    invokeVisibleButtons = new EventEmitter();
    setRoute$: Subject<any> = new Subject<any>();
    pollVehicleAction: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private baseHttp: BaseHttpService,
    ) { }

    transferTruckFunctionInMyFleet() {
        this.invokeTransferTruckFunction.emit();
    }
    closeMapViewFunctionInMyFleet() {
        this.invokeCloseMapViewFunction.emit();
    }

    getTransferredVehicles() {
        let searchParams = new HttpParams();
        searchParams = searchParams.append('subscription-key', '55bf9b1e264b4f158d5fe9864f97c82f');
        searchParams = searchParams.append('ownership_status', 'transferred');
        return this.http.get(`${this.baseHttp.apiUrl}vehicles`, { params: searchParams });
    }

    // Single Vehicle Details
    getSelectedVehicleDetails(vehicleID) {
        return this.http.get(`${this.baseHttp.apiUrl}vehicles/${vehicleID}`);
    }
    exportToExcel(vehicles: any[],table: string){
        // const title = 'MyFleet Sheet'
        const header = Object.keys(vehicles[0])
        const workbook = new Workbook()

        const worksheet = table == 'active' ? workbook.addWorksheet('Active vehicles data') : (table == 'preDelivery' ? workbook.addWorksheet('PreDelivery vehicles data') : workbook.addWorksheet('Transferred vehicles data'))
        //const titleRow = worksheet.addRow([title])
       // worksheet.addRow([])
        const headerRow = worksheet.addRow(header)
        vehicles.forEach(d => {
            let row = worksheet.addRow(Object.values(d))
            
            })
            workbook.xlsx.writeBuffer().then(excelData => {
                const blob = new Blob([excelData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
                fs.saveAs(blob, 'MyFleet Sheet.xlsx')
                }) 
    }
    
}
