import { Injectable } from '@angular/core';
import {
    ActivatedRouteSnapshot,
    RouterStateSnapshot,
    UrlTree,
    Router,
    CanActivateChild,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '@core/services/auth.service';
import { CryptoService } from '@core/services/crypto.service';
import { authSettings } from '@config/oauth';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivateChild {
    permissions = [];
    constructor(
        private authService: AuthService,
        private router: Router,
        private cryptoService: CryptoService,
    ) {
        let userPermissions = this.cryptoService.decryptData(
            localStorage.getItem('permissions')
        );
        this.permissions = userPermissions ? userPermissions.urls : [];
    }

    canActivateChild(
        childRoute: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ):
        | Observable<boolean | UrlTree>
        | Promise<boolean | UrlTree>
        | boolean
        | UrlTree {
        this.resetToken();
        return this.authService.isUserLoggedIn()
            ? this.permissions.indexOf(state.url) == -1
                ? state.url.includes('?page') == true ||
                    state.url.includes('details') == true
                    ? true
                    : state.url.indexOf('hino/remote-diagnostics') > -1 &&
                        state.url.indexOf('id_token') > -1
                        ? this.setPreviousToken()
                        : state.url.indexOf('hino/remote-diagnostics') > -1 &&
                            state.url.indexOf('vehicle_vin') > -1
                            ? this.checkRD(childRoute.queryParams.vehicle_vin)
                            : (this.router.navigateByUrl('/access-denied'), false)
                : state.url.indexOf('hino/remote-diagnostics') > -1 &&
                    state.url.indexOf('id_token') == -1
                    ? this.checkRD()
                    : true
            : state.url.includes('mobile') == true
                ? this.checkCookie(state.url) : (this.router.navigateByUrl('/customer-login'), false);
    }

    checkRD(childRoute?) {
        if (childRoute) localStorage.setItem('vehicleVin', childRoute);
        localStorage.setItem('previousToken', localStorage.getItem('token'));
        localStorage.removeItem('token');
        if (
            this.cryptoService.decryptData(localStorage.getItem('userAccount'))
                .isCorporate
        ) {
            window.open(authSettings['aad_rd_auth'], '_self');
        } else {
            window.open(authSettings['b2c_rd_auth'], '_self');
        }
        return false;
    }

    setPreviousToken() {
        let token = localStorage.getItem('token');
        let previousToken = localStorage.getItem('previousToken');
        localStorage.setItem('previousToken', localStorage.getItem('token'));
        localStorage.removeItem('token');
        return true;
    }

    resetToken() {
        let token = localStorage.getItem('token');
        let previousToken = localStorage.getItem('previousToken');
        if (!token && previousToken) {
            localStorage.setItem('token', localStorage.getItem('previousToken'));
            localStorage.removeItem('previousToken');
        }
    }

    checkCookie(url) {
        if (url.indexOf('sessionCookie') > -1) {
            let extractCookies = url.split('?')[1].split('&');
            extractCookies.forEach((key) => {
                if (key.indexOf('sessionCookie') > -1) {
                    let token = key.split('=')[1];
                    token = token.replace("%20", " ");
                    document.cookie = `sessionCookies=${token}`;
                }
            })
        }
        return true;
    }
}
