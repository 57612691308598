import { Component, OnInit, SimpleChanges, Input, OnChanges } from '@angular/core';
import { colorsSettings } from '@config/color-config';

@Component({
    selector: 'app-comparison',
    templateUrl: './comparison.component.html',
    styleUrls: ['./comparison.component.css']
})
export class ComparisonComponent implements OnInit, OnChanges {
    @Input() chartApiData;
    @Input() canvasOnly = false;
    graphOptions = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            xAxes: [{
                scaleLabel: {
                    display: true,
                    labelString: 'Month'
                }
            }],
            yAxes: [{
                ticks: {
                    min: 0,
                    suggestedMax: 10,
                    stepSize: 10,
                },
                legend: {
                    display: false
                },
                scaleLabel: {
                    display: true,
                    labelString: 'Time in Hours'
                }
            }]
        },
        legend: {
            display: true,
            position: 'top',
            align: 'start', 
        }
    };
    labels;
    data;

    constructor() { }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.chartApiData?.currentValue) {
            this.labels = this.chartApiData.chartLabels?.filter(el => el.axis === 'x')[0].labels.map(x => x.slice(0, 3) + x.slice(x.length - 2));
            this.data = this.chartApiData.chartData[0]?.data?.map(el => {
                const _key = Object.keys(el)[0];
                return {
                    label: _key,
                    data: el[_key],
                    backgroundColor: (_key === 'Idle Hrs') ? colorsSettings.idle : (_key == 'PTO Hrs') ? colorsSettings.pto : colorsSettings.driving,
                    hoverBackgroundColor: (_key === 'Idle Hrs') ? colorsSettings.idle : (_key == 'PTO Hrs') ? colorsSettings.pto : colorsSettings.driving
                }
            });
        }
    }

}
