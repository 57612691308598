import { Component, OnInit, Input, Output, EventEmitter, OnChanges } from '@angular/core';

@Component({
    selector: 'app-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent implements OnInit , OnChanges{
    @Output() isAccepted: EventEmitter<any> = new EventEmitter();
    @Input() listView;
    @Input() action;
    @Input() entity;
    text;
    @Input() acceptButtonText;
    @Input() declineButtonText;
    constructor() {}

    ngOnInit(): void {}
    ngOnChanges() {
        this.text = this.listView === true ? 'deactivate' : 'activate';
        this.text = this.action ? this.action : this.text;
        this.entity = this.entity ? this.entity : 'user';
    }
    cancel() {
        this.isAccepted.emit(false);
    }
    confirm() {
        this.isAccepted.emit(true);
    }
}
