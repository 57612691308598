import { Component, OnInit } from '@angular/core';
import { authSettings } from '@config/oauth';

@Component({
  selector: 'app-corp-login',
  templateUrl: './corp-login.component.html',
  styleUrls: ['./corp-login.component.scss']
})
export class CorpLoginComponent implements OnInit {
  isPrivacyChecked: boolean;

  constructor() { 
    localStorage.clear();
  }

  ngOnInit(): void {
    localStorage.setItem('isCorpAccount', '1');
  }
  hmsLogin(){
    this.isPrivacyChecked = true;
    try {
      this.isPrivacyChecked = window.self !== window.top;
    } catch(e) {
      this.isPrivacyChecked = true;
    }
    localStorage.setItem('countryId', '2');
    window.open(authSettings['aad_auth'], '_self');
  }
  hmcLogin(){
    this.isPrivacyChecked = true;
    try {
      this.isPrivacyChecked = window.self !== window.top;
    } catch(e) {
      this.isPrivacyChecked = true;
    }
    localStorage.setItem('countryId', '3');
    window.open(authSettings['aad_auth_hmc'], '_self');
  }
}
