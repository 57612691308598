export const environment = {
    production: false,
    apiConfig: {
        url: 'https://hino-dev-services.azure-api.net/api',
        version: 'v1',
    },
    apiCallInterval: 120000,
    azureMapsSubscriptionKey: "7_s4Si17Mh2PYUG9Pg0wENmJFcrZObBgeA2BetBbLLk",
    aadRedirectUrl: 'https://dev.hinoultimate.com/login',
    b2cRedirectUrl: 'https://dev.hinoultimate.com/login',
    changePasswordRedirectUrl: 'https://dev.hinoultimate.com/hino/user-management',
    viewProfileDecisivRedirectUrl: 'http://www.example.com',
    aadClientId: 'da02efea-e7a1-4808-a3fc-50978fae4d76',
    // aadClientIdhmc: '36914edf-878a-4ae7-b7bc-090b9f663f92',
    aadClientIdhmc: '36914edf-878a-4ae7-b7bc-090b9f663f92',
    b2cClientId: '814e7f6e-01b7-4727-8c80-daf094e88009',
    remoteDiagnosisRedirectUrl: 'https://dev.hinoultimate.com/hino/remote-diagnostics',
    hostName: 'https://ng-web-dev.azurewebsites.net/',
    subscriptionKey: '37ac7ddafb4549af99cc08334333a4f0',
    baseAppUrl: 'https://dev.hinoultimate.com/',
    caseGoToDetailsBaseUrl: 'https://hinopg.staging.decisivapps.com/fleet/estimates/',
    caseGoToDetailsBaseUrlQueryParam: 'HINODEMOFLEET',
    projectOpportunitiesLearnMore: 'https://devapi.hinoultimate.com',
    b2cSignInKey: 'B2C_1_SIGNIN_WEB_DEV',
    b2cResetPasswordKey: 'B2C_1_RESET_PWD_WEB_DEV',
    blankFieldText: '',
    tstSubscriptionKey: '221135c1eec941f2bc1df6a16c5414cf', 
    tstApiHost: 'https://hino-dev-services.azure-api.net/api/v1', 
    goToDecisivCreateCaseBaseUrl: 'https://hinopg.staging.decisivapps.com/integrations/hino/nexgen/provider_search',
    pollVehicleInterval: 120000
};
