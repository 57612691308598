<section class="route-container">
    <section class="main-title">
    </section>
    <div style="background-color: #fff; height: 100% !important;" class="pt-2 pl-2 pr-2 mt-2 mr-2 ml-2">
        <div style="display: flex;justify-content:center">
            <img src="./assets/images/menus/TICF_LOGO.png" height="150" width="200">
        </div>
        <div style="display: flex;justify-content:center">
            <h1>
                Hino Truck and Toyota Industries Commercial Finance – A Partnership for the Long Haul</h1>
        </div>

        <div class="pl-4 pr-4">
            Toyota Industries Commercial Finance (TICF) is proud to be the captive finance company for Hino Trucks. And,
            because TICF and Hino Trucks both belong to the Toyota Group of companies, a shared vision exists to create
            a
            value-added ownership experience for you, our customers. By working together, we draw on each other's
            strengths
            while delivering financing options designed to help meet your business needs.
        </div>
        <div class="pl-4 pr-4 pt-2">
            We are pleased that you chose TICF as your financial partner for your Hino truck. In our continuous effort
            to
            best serve you, we are excited to introduce our online Customer Portal to conveniently manage your TICF
            account
            24x7 from the comfort of your computer.
        </div>
        <div class="pl-4 pr-4 pt-2">
            Benefits of our Customer Portal Include:
            <ul>
                <li>
                    Track the balance due on your financed equipment by viewing account history and transaction details
                </li>
                <li>Review TICF invoices and set up payment reminders with a simple click of a button</li>
                <li>Make a one-time payment</li>
                <li>Set up automatic payments</li>
                <li>Enroll in ACH for payment processing</li>
                <li>View payment history</li>
                <li>Manage account user access</li>
                <li>Receive electronic late payment notices</li>
                <li>Review invoices, and more</li>
            </ul>
            <a href="javascript:void(0)" (click)="goToTicf()">
                Visit our Customer Portal here
            </a>

        </div>
        <div class="pl-4 pr-4 pt-2">
            Register or Login to our Hino Customer Portal today to view all the on-demand account management options
            available to you from the convenience of your keyboard.
        </div>
        <div fxLayout="column" style="margin-top: 15%;">
            <div>
                <!-- <h4 style="color:rgb(128,128,128,0.8);margin-top:30px;text-align: center;">
                <img src="assets/images/home/hino_header_logo_left_corner.png" class="img-fluid logo-big" alt=""
                    style=" height: 3em;">
            </h4> -->
            </div>

        </div>
    </div>
</section>