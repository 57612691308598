<div class="column">
    <div class="modal-header">
        <h4 style="color:rgb(128,128,128,0.9);;margin:auto;">User List</h4>
    </div>
    <div style="border:2px solid rgb(128,128,128,0);">
        <div style="margin:5px;border:1px solid rgb(128,128,128,0.4);border-radius:3px;">
            <app-search (searchOutput$)="searchOutput($event)" [placeholderText]="'Key to start search'"></app-search>
        </div>
    </div>
    <!-- <mat-list>
    <mat-list-item *ngFor="let user of Users">
        <div class="form-check-inline" style="width:100%;">
            <h4 style="color:rgb(128,128,128,0.9)">{{user}}</h4>
            <input type="radio" class="form-check-input" style="margin-left: auto;margin-top:-10px;" value="user"
                (click)="selectedUserForSimulation(user)" name="selUser">
        </div>
    </mat-list-item>
</mat-list> -->

<!--corporate table starts-->
    <div  class="scroll" style="overflow-y:scroll !important;height:200px;" *ngIf="users.length > 0 && !isLoading && isCorporate">
        <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" fxFlex="100">
            <ng-container matColumnDef="name">
                <th mat-header-cell *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element" class="user-details"> 
                    <p class="user-name">{{element.account.fleet_code}} - {{element.account.account_name}}</p>
                    <p class="email-address">{{element.email_address}} </p>
                </td>
            </ng-container>
            <ng-container matColumnDef="radioButton">
                <th mat-header-cell class="radioButtonColumn" *matHeaderCellDef> </th>
                <td mat-cell *matCellDef="let element">
                    <!-- <input type="radio" class="form-check-input" style="margin-left: auto;margin-top:-10px;" id="element"  [value]="element"  /> -->
                    <!-- <mat-radio-button  style="margin-left: auto;margin-top:-10px;" value="element"
                (click)="selectedUserForSimulation(element)"></mat-radio-button> -->
                    <input type="radio" style="margin-left: auto;margin-top:-10px;" value="element" name="selUser">
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedUserForSimulation(row)"></tr>
        </table>
    </div>
<!-- corporate table ends-->
<!-- user table starts -->
<div  class="scroll" style="overflow-y:scroll !important;height:200px;" *ngIf="fleet.length > 0 && !isLoading && !isCorporate">
    <table mat-table [dataSource]="fleetDataSource" class="mat-elevation-z8" fxFlex="100">
        <ng-container matColumnDef="name">
            <th mat-header-cell *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element" class="user-details"> 
                <p class="user-name">{{element.fleet_code}} - {{element.account_name}}</p>
                <p class="email-address">{{element.email_address}}</p>
            </td>
        </ng-container>
        <ng-container matColumnDef="radioButton">
            <th mat-header-cell class="radioButtonColumn" *matHeaderCellDef> </th>
            <td mat-cell *matCellDef="let element">
                <!-- <input type="radio" class="form-check-input" style="margin-left: auto;margin-top:-10px;" id="element"  [value]="element"  /> -->
                <!-- <mat-radio-button  style="margin-left: auto;margin-top:-10px;" value="element"
            (click)="selectedUserForSimulation(element)"></mat-radio-button> -->
                <input type="radio" style="margin-left: auto;margin-top:-10px;" value="element" name="selUser">
            </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectedUserForSimulation(row)"></tr>
    </table>
</div>
<div class="scroll" style="overflow-y:scroll !important;height:200px;display: flex;justify-content: center;align-items: center;" *ngIf="fleet.length === 0 && !aliasPopupLoading && !isCorporate">
    Switch account is not allowed
</div>
<div class="scroll" style="overflow-y:scroll !important;height:200px;display: flex;justify-content: center;align-items: center;" *ngIf="aliasPopupLoading && !isCorporate">
    <img style="height: 20px;" src='assets/images/loaders/loader-tranperant.gif'>

</div>
<!-- user table ends-->
<div *ngIf="isCorporate">
    <div  class="scroll" style="overflow-y:scroll !important;height:200px; text-align: center; color: grey; padding:10%; font-size: 12px;" *ngIf="users.length == 0 || isLoading">
        <span *ngIf="!isLoading">{{message | translate | titlecase}}</span>
        <span *ngIf="isLoading">
            <img style="height: 20px;" src='assets/images/loaders/loader-tranperant.gif'>
        </span>
    </div>
</div>
</div>