<div class="modal-header">
    <span style="margin:auto !important" class="title">{{'CONFIRMATION' |translate |titlecase }}</span>
</div>
<div style="text-align:center;background-color: rgb(128,128,128,0.1);" class="modal-body">
    <p *ngIf= "entity == 'telematics'">Conforming this will stop enrolment of new customer?</p>
    <p *ngIf= "entity != 'telematics'">Are you sure you want to {{text}}?</p>
    <div class="row" style="justify-content: center !important;">
        <button type="button" class="btn btn-outline-dark btn-sm" (click)="cancel()">
            {{ declineButtonText }}</button>
        <button style="margin-left:10px;background-color:#b03034;color:white" type="button" class="btn btn-sm"
            (click)="confirm()">{{acceptButtonText}}</button>
        <!-- <button *ngIf="!(text===activate)" style="margin-left:10px;background-color:#b03034;color:white" type="button"
            class="btn btn-sm" (click)="confirm()">{{'DEACTIVATE' |translate |titlecase}}</button> -->
    </div>
</div>