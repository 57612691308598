import { Component, OnInit, NgZone, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd, NavigationStart, NavigationCancel, NavigationError } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { filter, map, mergeMap } from 'rxjs/operators';
import { pascalCase } from '@core/helpers/app.helper';
import { AppService } from '@core/services/app.service';
import { environment } from '@environments/environment';
import { SubSink } from 'subsink';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '@modules/user-management/services/user.service';
import { AutoLogoutService } from './auto-logout.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
    loading: boolean;
    subSink = new SubSink();

    constructor(
        private route: Router,
        private activatedRoute: ActivatedRoute,
        private pageTitle: Title,
        private appService: AppService,
        private ngZone: NgZone,
        private changeDetectorRef: ChangeDetectorRef,
        private translateService: TranslateService,
        private userService: UserService,
        private autoLogoutService: AutoLogoutService,
    ) {
        // disable console logs in production
        environment.production && (console.log = function () { })();
    }

    ngOnInit() {
        // subscribe to http/route events for toggling application loader
        this.ngZone.runOutsideAngular(() => {
            this.subSink.sink = this.appService.appLoader$.subscribe(value => {
                this.loading = value;
                this.changeDetectorRef.detectChanges();
            });
        });

        // setup route loading
        this.route.events.pipe(
            filter(event => event instanceof NavigationStart)
        ).subscribe(_ => this.appService.appLoader$.next(true));
        this.route.events.pipe(
            filter(event => event instanceof NavigationError || event instanceof NavigationCancel || event instanceof NavigationEnd)
        ).subscribe(_ => this.appService.appLoader$.next(false));

        // register for route event for extracting page title from route data
        this.route.events.pipe(
            filter((event) => event instanceof NavigationEnd),
            map(() => this.activatedRoute),
            map((route) => {
                while (route.firstChild) route = route.firstChild;
                return route;
            }),
            filter((route) => route.outlet === 'primary'),
            mergeMap((route) => route.data)
        )
        .subscribe((event) => {
            if (event?.title) {
                const title = pascalCase(event['title']);
                this.pageTitle.setTitle(`Hino - ${title}`);
                this.appService.pageTitle$.next(title);
            }
        });

        // set default language from local storage
        if (localStorage.getItem('user-preferences')) {
            const fullPreferences = this.userService.getUserPreferences();
            if (fullPreferences?.language) {
                const userLanguage = fullPreferences?.language.code.split('-')[0];
                this.translateService.setDefaultLang(userLanguage?.toLowerCase() || 'en');
                this.translateService.use(userLanguage?.toLowerCase() || 'en');
            }
        }
    }

    ngOnDestroy() {
        this.subSink.sink.unsubscribe();
    }
}
