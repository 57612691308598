import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
// i18n
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '@shared/shared.module';
import { LoginComponent } from '@core/components/login/login.component';
import { BaseComponent } from '@core/components/base/base.component';
import { DashboardModule } from '@modules/dashboard/dashboard.module';
import { SignupComponent } from '@core/components/signup/signup.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AutoLogoutService } from './auto-logout.service';

// ngx-toastr
import { ToastrModule } from 'ngx-toastr';
import { GlobalToastConfig } from '@config/toast.config';
import { AppHttpInterceptorService } from '@core/interceptors/app-http-interceptor.service';
import { NotFoundComponent } from './core/components/not-found/not-found.component';
import { ChangePasswordLoginComponent } from './core/components/change-password-login/change-password-login.component';
import { ResetPasswordLoginComponent } from './core/components/reset-password-login/reset-password-login.component';
import { AccessDeniedComponent } from './core/components/access-denied/access-denied.component';
import { LoaderModalComponent } from './core/components/loader-modal/loader-modal.component';
import { AuthMismatchComponent } from './core/components/auth-mismatch/auth-mismatch.component';
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FirstComponentComponent } from './first-component/first-component.component';
import { CorporateLoginComponent } from './core/components/corporate-login/corporate-login.component';
import { HelpComponent } from './core/components/help/help.component';
import { RegisterVinFormComponent } from './core/components/secondary-ownership/register-vin-form/register-vin-form.component';
import { CustomerLoginComponent } from './core/components/customer-login/customer-login.component';
import { TestRouteComponent } from './core/components/test-route/test-route.component';
import { TermsAndConditionsComponent } from './core/components/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './core/components/privacy-policy/privacy-policy.component';
import { TicfComponent } from './core/components/ticf/ticf.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { CorpLoginComponent } from './core/components/corp-login/corp-login.component';
// import { LogoutWarningComponent } from './modules/logout-warning/logout-warning.component';
import { MatDialogModule } from '@angular/material/dialog';
// import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
// import { MobileComponent } from './mobile/mobile.component';
// import { MobileCaseManagementComponent } from './mobile/modules/mobile-case-management/mobile-case-management.component';
// import { MobileDashboardComponent } from './mobile/modules/mobile-dashboard/mobile-dashboard.component';
// import { MobileMyfleetComponent } from './mobile/modules/mobile-myfleet/mobile-myfleet.component';

// AOT compilation requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
    return new TranslateHttpLoader(httpClient, '../assets/i18n/', '.json');
}

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        BaseComponent,
        SignupComponent,
        NotFoundComponent,
        ChangePasswordLoginComponent,
        ResetPasswordLoginComponent,
        AccessDeniedComponent,
        LoaderModalComponent,
        AuthMismatchComponent,
        FirstComponentComponent,
        CorporateLoginComponent,
        HelpComponent,
        RegisterVinFormComponent,
        CustomerLoginComponent,
        TestRouteComponent,
        TermsAndConditionsComponent,
        PrivacyPolicyComponent,
        TicfComponent,
        CorpLoginComponent,
        // LogoutWarningComponent,
        // MobileComponent,
        // MobileCaseManagementComponent,
        // MobileDashboardComponent,
        // MobileMyfleetComponent,
    ],
    imports: [
        BrowserModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        HttpClientModule,
        MatDialogModule,
        AppRoutingModule,
        SharedModule,
        DashboardModule,
        MatTooltipModule,
        ToastrModule.forRoot(GlobalToastConfig),
        DeviceDetectorModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            },
        })
    ],
    providers: [AutoLogoutService,
        
        { provide: Window, useValue: window },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptorService,
            multi: true,
        },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
