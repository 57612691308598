import { Injectable } from '@angular/core';
import { IndividualToastConfig } from '@config/toast.config';
import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {
    config = IndividualToastConfig;

    constructor(
        private toasterService: ToastrService,
    ) { }

    success(message: string, title: string = '') {
        this.toasterService.success(message, title, this.config);
    }

    error(message: string, title: string = '') {
        this.toasterService.error(message, title, this.config);
    }

    info(message: string, title: string = '') {
        this.toasterService.info(message, title, this.config);
    }

    warn(message: string, title: string = '') {
        this.toasterService.warning(message, title, this.config);
    }
}
