const baseUrl = 'assets/images/vehicleModels/';

export const vehicleModels = {
    '5PV_238_front': `${baseUrl}5PV_238_front.png`,
    '5PV_Extended_Cab_XL7_XL8': `${baseUrl}5PV_Extended_Cab_XL7_XL8.png`,
    '5PV_L_Extended_L6_L7': `${baseUrl}5PV_L_Extended_L6_L7.png`,
    '5PV_L_Crew_L6_L7': `${baseUrl}5PV_L_Crew_L6_L7.png`,
    '5PV_Standard_L6_L7': `${baseUrl}5PV_Standard_L6_L7.png`,
    '5PV_XL_Crew_Cab_XL7_XL8': `${baseUrl}5PV_XL_Crew_Cab_XL7_XL8.png`,
    '5PV_XL_Standard_XL7_XL8': `${baseUrl}5PV_XL_Standard_XL7_XL8.png`,
    '7H4_XL_Tractor_XL7_XL8': `${baseUrl}7H4_XL_Tractor_XL7_XL8.png`,
    'JHH_Standard': `${baseUrl}JHH_Standard.png`,
    'JHH_Crew': `${baseUrl}JHH_Crew.png`,
};

