import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MyfleetVehicleViewComponent } from '@modules/myfleet/components/myfleet-vehicle-view/myfleet-vehicle-view.component';
import { DashboardViewComponent } from './components/dashboard-view/dashboard-view.component';


const routes: Routes = [
  { path: '', component: DashboardViewComponent },
  { path: 'vehicle/:vehicleId/details', component: MyfleetVehicleViewComponent }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class DashboardRoutingModule { }
