import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { BaseHttpService } from '@core/services/base-http.service';
import {
    getRoles,
    getLastLogin,
} from '@modules/user-management/models/usermanagement.model';
import * as _ from 'underscore';
import { CryptoService } from '@core/services/crypto.service';
import { AuthService } from '@core/services/auth.service';
import { TranslateService } from '@ngx-translate/core';
import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    broadcastUser: BehaviorSubject<any> = new BehaviorSubject(null);
    loggedInUserAction: BehaviorSubject<any> = new BehaviorSubject(null);
    updateAction: BehaviorSubject<any> = new BehaviorSubject(null);
    modalAction: BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private http: BaseHttpService,
        private cryptoService: CryptoService,
        private authService: AuthService,
        private translateService: TranslateService,
        private deviceService: DeviceDetectorService
    ) { }

    getUsers = (query) => {
        return new Promise((resolve, reject) => {
            this.http.get(`users${query}`, { observe: 'response' }).subscribe(
                (response) => {
                    if (response.status == 200) {
                        let data = {
                            page: response.body.data.page,
                            users: response.body.data.users.map(this.dataForUserComponent)
                        }
                        resolve(data);
                    } else resolve([]);
                },
                (notSuccess) => {
                    if (
                        notSuccess.error.statusCode === 404 &&
                        notSuccess.error.message === 'Users not found'
                    ) {
                        resolve({
                            page: {},
                            users: []
                        });
                    }
                }
            );
        });
    };

    getAADData = (mailId) => {
        return new Promise((resolve, reject) => {
            this.http.get(`aad/search/${mailId}`).subscribe((res) => {
                if (res.statusCode == 200) {
                    resolve(res.data);
                }
            });
        });
    };

    getCountries = () => {
        return new Promise((resolve, reject) => {
            this.http.get(`common/address/countries`).subscribe((res) => {
                if (res.status === 'success') {
                    resolve(res.data);
                }
            });
        });
    };

    getStates = (countryId) => {
        return new Promise((resolve, reject) => {
            this.http
                .get(`common/address/states?country_id=${countryId}`)
                .subscribe((res) => {
                    if (res.status === 'success') {
                        resolve(res.data);
                    }
                });
        });
    };

    dataForUserComponent(usersInstance) {
        const {
            user_id,
            firstname,
            lastname,
            email_address,
            email_verification_code,
            email_verified_on,
            mobile_no,
            phone_no,
            history,
            role_map,
            isActive,
        } = usersInstance;
        const processeedRoles = getRoles(role_map);
        const processeedLastLogin = getLastLogin(history);
        usersInstance.loginTime = processeedLastLogin;
        usersInstance.allLogins = _.sortBy(
            history,
            'login_time'
        ).reverse();
        usersInstance.id = user_id;
        usersInstance.roles = processeedRoles;
        usersInstance.allRoles = role_map;
        return usersInstance;
    }

    getTaxonomies(taxonomy_type) {
        return new Promise((resolve, reject) => {
            this.http.get(`taxonomy?taxonomy_type=${taxonomy_type}`).subscribe(taxonamyResponse => {
                if (taxonamyResponse.statusCode == 200) {
                    resolve(taxonamyResponse.data)
                }
            });
        });
    }

    getUserPreferences() {
        return localStorage.getItem('user-preferences')
            && this.cryptoService.decryptData(localStorage.getItem('user-preferences'));
    }

    getUserTimezone(): any | false {
        const fullPreferences = this.getUserPreferences();
        return fullPreferences?.timezone ? fullPreferences.timezone : false;
    }

    getVehicleTimezone(): any | false {
        const fullPreferences = this.getUserPreferences();
        return fullPreferences?.vehicleTimezone ? fullPreferences.vehicleTimezone : false;
    }

    /** default timezones for user & vehicle */
    extractTimeZone(selection: 'user' | 'vehicle'): string {
        if (selection === 'user') {
            const underScored = this.getUserTimezone().code?.trim().split('_');
            return underScored?.length && underScored[0] || 'US/Central';
        } else {
            const underScored = this.getVehicleTimezone().code?.trim().split('_');
            return underScored?.length && underScored[0] || 'US/Central';
        }
    }

    /** return preferred format or user preferred format */
    getUserDateFormat(): string {
        const fullPreferences = this.getUserPreferences();
        return fullPreferences?.dateFormat
            ? fullPreferences?.dateFormat?.code?.split('tt')[0].trim()
            : 'MM-dd-yyyy';
    }

    getUOMPreferences(): any | false {
        const fullPreferences = this.getUserPreferences();
        return fullPreferences?.uom && Object.keys(fullPreferences.uom).length
            ? fullPreferences.uom : false;
    }

    savePreference() {
        let preferenceObject =  {
            "uom": {
                "distance": {
                    "id": 14001
                },
                "volume": {
                    "id": 14003
                },
                "weight": {
                    "id": 14007
                },
                "temperature": {
                    "id": 14011
                },
                "pressure": {
                    "id": 14015
                }
            },
            "timezone": {
                "id": 72041
            },
            "language": {
                "id": 40001
            },
            "dateFormat": {
                "id": 74007
            },
            "vehicleTimezone": {
                "id": 72041
            },
            "defaultDepot": {
                "id": 30
            }    
        
    }
        this.http
            .put(`users/${this.authService.getUserInfo().userId}/preference`, preferenceObject)
            .subscribe((updateResponse) => {
                if (updateResponse.status === 'success') {
                    this.http.get(`users/${this.authService.getUserInfo().userId}/preference`).subscribe(response => {
                        // if clause to be removed, to disable french language selection in mobile
                        if (!this.deviceService.isMobile()) {
                            localStorage.setItem('user-preferences', this.cryptoService.encryptData(response.data));
                            const userLanguage = response.data?.language.code.split('-')[0];
                            this.translateService.setDefaultLang(userLanguage?.toLowerCase() || 'en');
                            this.translateService.use(userLanguage?.toLowerCase() || 'en');
                        }
                    });
                }
            });
    }


    getInitialColor(userName) {
        let colorSet = [
            { startRange: 0, endRange: 10, colorIndex: -2 },
            { startRange: 11, endRange: 20, colorIndex: 244 },
            { startRange: 21, endRange: 30, colorIndex: 349 },
            { startRange: 31, endRange: 40, colorIndex: -41 },
            { startRange: 41, endRange: 50, colorIndex: -177 },
            { startRange: 51, endRange: 60, colorIndex: 139 },
            { startRange: 61, endRange: 70, colorIndex: 0 },
            { startRange: 71, endRange: 80, colorIndex: -320 },
            { startRange: 81, endRange: 90, colorIndex: 154 },
            { startRange: 91, endRange: 100, colorIndex: -80 },
        ]
        var hash = 0;
        for (var i = 0; i < userName.length; i++) {
            hash = userName.charCodeAt(i) + ((hash << 5) - hash);
        }
        var index = hash % 360;
        return `hsl(${index}, 55% , 45%)`;
    }

    getUser(userId) {
        return new Promise((resolve, reject) => {
            this.http.get(`users/${userId}`, { observe: 'response' }).subscribe(
                (response) => {
                    if (response.status == 200) {
                        resolve(response.body.data);
                    } else resolve([]);
                },
                (notSuccess) => {
                }
            );
        });
    }
}
